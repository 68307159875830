import { memo } from "react"
import { useInfiniteQuery } from "react-query"

const withPostsData = (WrappedComponent, getPosts) => {
    const WrappedComponentMemo = memo(WrappedComponent)
    return props => {
        const { id: userId, queryKey } = props
        const {
            error, data, refetch, isFetching,
            fetchNextPage, hasNextPage,
            isLoading, isFetchingNextPage
        } = useInfiniteQuery({
            queryKey: [queryKey],
            queryFn: ({ pageParam = 1 }) => getPosts({ page: pageParam, userId }),
            getNextPageParam: (lastPage, page) =>
                lastPage?.metadata?.page < lastPage?.metadata?.pages
                    ? lastPage?.metadata?.page + 1
                    : undefined
            ,
            keepPreviousData: true,
            retry: 0
        })

        const { pages, pageParams } = data || {}

        return (
            <WrappedComponentMemo
                {...props}
                errorFetchingPost={error}
                loadingPosts={isLoading}
                pages={pages}
                pageParams={pageParams}
                refetch={refetch}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetching={isFetching}
                isFetchingNextPage={isFetchingNextPage}
            />
        )
    }
}

export default withPostsData
