import { Link } from "react-router-dom"
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from "react-redux"
import { useContext } from "react"
import { MessageContext } from "./MessageProvider"

const MessagesBox = () => {
    // const {handleSetActiveFriend, activeFriend} = props
    const { handleSetActiveFriend } = useContext(MessageContext)

    return (
        <div className="col-lg-3 col-md-4 pd-left-none no-pd">
            <div className="msgs-list">
                <div className="msg-title">
                    <h3>Messages</h3>
                    <ul>
                        <li><Link onClick={() => handleSetActiveFriend()} to="#" title=""><i className="fa fa-edit"></i></Link></li>
                    </ul>
                </div>
                <Scrollbars autoHide style={{ height: 448 }}>
                    <div className="messages-list">
                        <ul>
                            <li className="active">
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img1.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>John Doe</h3>
                                        <p>Lorem ipsum dolor <img src="images/smley.png" alt="" /></p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                    <span className="msg-notifc">1</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img2.png" alt="" />
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>David Vane</h3>
                                        <p>Vestibulum ac diam..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img3.png" alt="" />
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>Nancy Dilan</h3>
                                        <p>Quam vehicula.</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img4.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>Norman Kenney</h3>
                                        <p>Nulla quis lorem ut..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img5.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>James Dilan</h3>
                                        <p>Vivamus magna just..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img5.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>Richard Mugahi</h3>
                                        <p>Vivamus magna just..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img5.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>Maurice Kirya</h3>
                                        <p>Vivamus magna just..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img5.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>John Okello</h3>
                                        <p>Vivamus magna just..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                            <li>
                                <div className="usr-msg-details">
                                    <div className="usr-ms-img">
                                        <img src="images/resources/m-img5.png" alt="" />
                                        <span className="msg-status"></span>
                                    </div>
                                    <div className="usr-mg-info">
                                        <h3>James Dilan</h3>
                                        <p>Vivamus magna just..</p>
                                    </div>
                                    <span className="posted_time">1:55 PM</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

const mapStateToProps = ({ authedUser, users }, { activeFriend, handleSetActiveFriend }) => {
    const user = users[authedUser]
    const friends = user?.friends
    const activeFriendDetails = users[activeFriend]

    return {
        user, handleSetActiveFriend,
        friends,
        activeFriend,
        activeFriendDetails
    }
}

export default connect(mapStateToProps)(MessagesBox)
