import backendAPI from './backendAPI'
const api = process.env.REACT_APP_API || 'xxx'
const sokoniApi = 'https://sokoniplace.com/wp-json/sokoni'
const tcnApi = 'https://thecooperator.news/wp-json/wp/v2'
const axios = require('axios')

require('dotenv').config()

const headers = token => {
    return {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`

    }
}

const headersMultipart = token => {
    return {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}

const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1
    }
    return new File([u8arr], filename, { type: mime })
}

const handleError = error => {
    let errorMessage
    if (error.toJSON().message) {
        errorMessage = error.toJSON().message
    } else {
        errorMessage = error
    }
    throw errorMessage
}

export function getInitialData() {
    const requests = [
        backendAPI.get(`${api}/users`).then(res => res.data)
    ]

    return Promise.allSettled(requests.map(request => request.catch(handleError)))
        .then(([users]) => ({ users }))
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })

}

export function getPosts(params) {
    return backendAPI.get('/posts', { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getProfilePosts({ userId, ...params }) {
    return backendAPI.get(`/posts/${userId}`, { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const getUserInfo = ({ id }) => {
    return backendAPI.get(`/users/${id}/info`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const getUserStatus = ({ id }) => {
    return backendAPI.get(`/users/${id}/status`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getFora(params) {
    return backendAPI.get('/forum/questions', { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getProducts(params) {
    const { page, price_range, category_id } = params || {}
    return axios.get(`${sokoniApi}/get-products`,
        {
            params: {
                count: 28,
                page: page || 1,
                min_price: price_range?.[0],
                max_price: price_range?.[1],
                category_ids: category_id
            }
        })
        .then(res => ({ products: res.data, headers: res.headers }))
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getFriends({ id, ...params }) {
    return backendAPI.get(`/friends/${id}`, { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getUsersMayKnow(params) {
    return backendAPI.get('/friends/may-know', { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getFriendRequestsReceived(params) {
    return backendAPI.get('/friends/received', { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getFriendRequestsSent(params) {
    return backendAPI.get('/friends/sent', { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getCategories() {
    return axios.get(`${sokoniApi}/get-categories`)
        .then(res => res.data)
        .catch(function (error) {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const getForumCategories = () => {
    return backendAPI.get(`/forum/categories`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const getForumCategoryStats = () => {
    return backendAPI.get(`/forum/categories/all`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getForumAnswers(params) {
    return backendAPI.get('/forum/question/answers', { params })
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function getNewsPosts(params) {
    return axios.get(`${tcnApi}/posts`, { params })
        .then(res => ({ data: res.data, headers: res.headers }))
        .catch(error => {
            console.log('Error fetching theCooperator News posts', error)
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const fetchMoreFora = info => {
    const { token, page, limit } = info

    return axios.get(`${api}/forum`, {
        params: {
            page, limit
        },
        "headers": headers(token)
    })
        .then(res => res.data)
        .catch(err => {
            console.log('Error fetching forum')
        })
}

export const saveLikeTogglePost = params => {
    return backendAPI.post(`${api}/posts/like`, params)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const saveVoteQuestionToggle = params => {
    return backendAPI.post('/forum/vote', params)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const saveLikeAnswerToggle = params => {
    return backendAPI.post('/forum/question/answer/like', params)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.response) errorMessage = error.response.data
            else if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            console.log('like toggle answer error', errorMessage)
            throw errorMessage
        })
}

export const saveLikeForumCommentToggle = params => {
    return backendAPI.post('/forum/question/answer/comment/like', params)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.response) errorMessage = error.response.data
            else if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            console.log('like toggle answer error', errorMessage)
            throw errorMessage
        })
}

export const saveLikeTogglePostComment = params => {
    return backendAPI.post(`${api}/posts/comments/like`, params)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.response) errorMessage = error.response.data
            else if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            console.log('like toggle post comment error', errorMessage)
            throw errorMessage
        })
}

export const addFriend = id => {
    return backendAPI.post(`/friends/${id}`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.response) errorMessage = error.response.data
            else if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const acceptFriend = id => {
    return backendAPI.post(`/friends/${id}/accept`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.response) errorMessage = error.response.data
            else if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const unFriend = id => {
    return backendAPI.delete(`/friends/${id}`)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.response) errorMessage = error.response.data
            else if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export const updateUser = info => {
    const { token, ...data } = info

    const { avatarURL, first_name, last_name, email, country, cur_avatar } = data
    const file = new FormData()

    if (avatarURL !== '' && avatarURL !== '/empty.png' && avatarURL.indexOf('http') !== 0) {
        const fileX = dataURLtoFile(avatarURL)
        file.append('avatarURL', fileX, fileX.name)
    }
    file.append('first_name', first_name)
    file.append('last_name', last_name)
    file.append('email', email)
    file.append('country', country)
    file.append('cur_avatar', cur_avatar)

    return axios.post(`${api}/users/update-user`, file, { "headers": headersMultipart(token) })
        .then(res => res.data)
        .catch(err => Promise.reject(err.response.status))
}

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export function saveQuestion(data) {
    return backendAPI.post(`/forum/questions`, data)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function savePost(info) {
    const { text, photo } = info
    const formData = new FormData()
    if (photo) {
        let blob = dataURLtoBlob(photo)
        formData.append("photo", blob);
    }
    formData.append('text', text)

    return backendAPI.post(`${api}/posts`, formData)
        .then(res => res.data)
        .catch(error => {
            throw new Error(error.toJSON().message || error);
        })
}

export function saveReplyPost(params) {
    return backendAPI.post(`${api}/posts/reply`, params)
        .then(res => res.data)
        .catch(error => {
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function saveQuestionAnswer(params) {
    return backendAPI.post('/forum/question/answers', params)
        .then(res => res.data)
        .catch(error => {
            console.log('Error adding answer', error)
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function saveAnswerComment(params) {
    return backendAPI.post('forum/question/answer/comment', params)
        .then(res => res.data)
        .catch(error => {
            console.log('Error adding comment', error)
            let errorMessage
            if (error.toJSON().message) errorMessage = error.toJSON().message
            else errorMessage = error
            throw errorMessage
        })
}

export function loginUser(data) {
    return axios.post(`${api}/users/login`, data)
        .then(res => res.data)
        .catch(err => err.response.data)
}

export function registerUser(data) {
    return axios.post(`${api}/users/register`, data)
        .then(res => res.data)
        .catch(err => console.log(`Registration error ${err}`))
}
