import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Linkify from 'linkifyjs/react'
import ShowMore from '@tedconf/react-show-more'
import ShowMoreText from 'react-show-more-text'

import { formatAnswer, formatDate } from '../../utils/helpers'
import AnswerComments from '../AnswerComments'
import Image from '../../layouts/Image'
import { loadState } from '../../utils/storage'
import useAnswerMutations from '../../async-manager/mutations/answers'
import ProgressDots from '../../layouts/ProgressDots'

const QuestionAnswer = ({ answer: ans, question_id }) => {
    const authedUser = loadState('id')
    const userImage = loadState('image')
    const answer = ans ? formatAnswer(ans, authedUser) : null

    const {
        onHandleAddComment, addCommentSuccess, addCommentError,
        addCommentLoading, onHandleLikeComment, onHandleLikeAnswer
    } = useAnswerMutations({ question_id })

    const {
        name, timestamp, commentIds,
        text, hasLiked, likes,
        id, image, comments
    } = answer

    const {
        register, reset, handleSubmit,
        formState: { errors, isSubmitted, isValid }
    } = useForm({ mode: "onChange" })

    useEffect(() => {
        if (addCommentSuccess) {
            reset()
        }
    }, [addCommentSuccess, reset])

    const options = {
        target: {
            url: '_blank'
        }
    }

    return (
        <div className="mt-2 comment-sec">
            <div className="d-flex">
                <div className="cm_img">
                    <Image image={image} className='small-img' />
                </div>
                <div className="card card-header  w-100 comment-list">
                    <div className="comment">
                        <h3>{name}</h3>
                        <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="show-new-line"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            width={0}
                            truncatedEndingComponent={"... "}
                        >
                            <Linkify tagName="p" options={options}>{text}</Linkify>
                        </ShowMoreText>
                    </div>
                </div>
            </div>
            <span className="float-right comment-span m-1"><img src="images/clock.png" alt="" />{formatDate(timestamp)}</span>
            <div className='float-left ml-5 mt-2'>
                <Link to="#"
                    onClick={() => onHandleLikeAnswer({ id, hasLiked, authedUser })}
                    className={`comment-span ${hasLiked ? 'active' : ''}`}>
                    <i className="fas fa-heart"></i> Like
                </Link>
                <span className="ml-2">{likes !== 0 && likes}</span>
            </div>
            <div className='float-left ml-3 mt-2'>
                <Link className="answer-span mb-1" to="#" data-toggle="collapse" data-target={`#box-${id}`}>
                    <i className="fas fa-comment-alt"></i> Comments
                </Link>
                <span className="ml-2 mt-2">{commentIds.length}</span>
            </div>
            <div id={`box-${id}`} className="collapse pl-5 comment-section">
                <div className="d-flex post-comment">
                    <div className="cm_img">
                        <Image image={userImage} className='small-img' />
                    </div>
                    <div className="w-100 comment_box">
                        <form className="d-flex" onSubmit={handleSubmit(onHandleAddComment)}>
                            <input
                                type="hidden"
                                value={answer?.id}
                                {...register('replyingTo')}
                            />
                            <input
                                className="w-100"
                                type="text"
                                {...register('text', {
                                    required: 'Reply cannot be empty'
                                })}
                                disabled={isSubmitted && !addCommentError}
                                placeholder="Add a comment ..."
                            />
                            {errors.comment && <span className='text-danger'><i className="la la-info-circle la-lg"></i></span>}
                            <button
                                disabled={(isSubmitted && !addCommentError) || !isValid}
                                type="submit">Comments
                            </button>
                        </form>
                        {addCommentLoading && <ProgressDots height='80%' width='80' />}
                        {addCommentError && <p className="text-danger">Error submitting comment: {addCommentError}</p>}
                    </div>
                </div>
                <ShowMore
                    items={commentIds}
                    by={5}
                // onEnd={() => {
                //     console.log("end of the list");
                // }}
                >
                    {({ current, onMore }) => (
                        <>
                            {current.map(comment => (
                                <AnswerComments
                                    key={comment}
                                    comment={comments[comment]}
                                    answerId={answer.id}
                                    handleLikeComment={onHandleLikeComment} />
                            ))}
                            <div className="float-left w-100 mt-2 d-flex">
                                <Link
                                    to="#"
                                    className={!onMore ? 'd-none' : ''}
                                    disabled={!onMore}
                                    onClick={() => {
                                        if (!!onMore) onMore()
                                    }}
                                >
                                    View More Comments
                                </Link>
                                {commentIds.length > 5 &&
                                    <span className="ml-auto text-info">
                                        {current.length} of {commentIds.length} comments
                                    </span>
                                }
                            </div>
                        </>
                    )}
                </ShowMore>
            </div>
        </div>
    )
}

export default QuestionAnswer
