import React from 'react'
import { Link } from 'react-router-dom'
import Linkify from 'linkifyjs/react'
import ShowMoreText from 'react-show-more-text'

import { formatComment, formatDate } from '../utils/helpers'
import Image from '../layouts/Image'
import { loadState } from '../utils/storage'
import withLikeComment from '../async-manager/withLikeComment'

const Comments = ({ comment: commentDetails, post_id, onHandleLikeComment }) => {
    const authedUser = loadState('id')
    const comment = formatComment(commentDetails, authedUser)
    const {
        name, timestamp, text, hasLiked,
        likes, id: comment_id, image
    } = comment

    const options = {
        target: {
            url: '_blank'
        }
    }

    return (
        <div className="mt-2 comment-sec">
            <div className="d-flex">
                <div className="cm_img">
                    <Image image={image} />
                </div>
                <div className="card card-header  w-100 comment-list">
                    <div className="comment">
                        <h3>{name}</h3>
                        <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="show-new-line"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            width={0}
                            truncatedEndingComponent={"... "}
                        >
                            <Linkify tagName="p" options={options}>{text}</Linkify>
                        </ShowMoreText>
                    </div>
                </div>
            </div>
            <span className="float-right comment-span m-1"><img src="images/clock.png" alt="" />{formatDate(timestamp)}</span>
            <div className='float-left ml-5'>
                <Link
                    to="#"
                    onClick={() => onHandleLikeComment({ post_id, comment_id, hasLiked, authedUser })}
                    className={`comment-span ${hasLiked ? 'active' : ''}`}>
                    <i className="fas fa-heart m-1"></i> Like
                </Link>
                <span className="ml-2">{likes !== 0 && likes}</span>
            </div>
        </div>
    )
}

const EnhancedComments = withLikeComment(Comments)
export default EnhancedComments
