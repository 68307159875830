import React, { Fragment, useContext } from "react"
import Post from "./Post.js"
import InfiniteScroll from "react-infinite-scroll-component"
import Error from "../layouts/Error.js"
import { PostsContext } from "./PostsProvider.js"
import Loading from "../layouts/Loading.js"

const PostsFeed = props => {
    const {
        error, loading, pages, isFetchingNextPage,
        fetchNextPage, hasNextPage,
    } = props

    const { queryKey } = useContext(PostsContext)
    const posts = pages?.reduce((prev, curr) => ({ ...prev, ...curr.data }), {})

    return (
        <div className="posts-section">
            {loading && <Loading />}
            {posts && <InfiniteScroll
                dataLength={Object.keys(posts).length}
                next={() => fetchNextPage()}
                hasMore={hasNextPage}
                loader={
                    isFetchingNextPage && (
                        <div className="process-comm">
                            <div className="spinner">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                    )
                }
                style={{ overflowY: 'hidden' }}
                endMessage={
                    <div className="last-bar">
                        <p>No more posts to show</p>
                    </div>
                }>
                {pages.map((group, i) => {
                    const { data, metadata } = group
                    const page = +metadata?.page - 1
                    return (
                        <Fragment key={i}>
                            {data && Object.keys(data).map(post =>
                                <Post key={post} queryKey={queryKey} post={data[post]} page={page} />
                            )}
                        </Fragment>
                    )
                })}
            </InfiniteScroll>}
            {error && <Error error={error} onReload={() => fetchNextPage()} />}
        </div>
    )
}

export default PostsFeed
