import ProfileContainer from "./ProfileContainer"
import ProfilePage from "./ProfilePage"
import RightProfileSideBar from "./RightProfileSideBar"

const Profile = props => {
    const { id } = props.match.params

    return (
        <ProfileContainer id={id}>
            <ProfilePage id={id} queryKey="profile-posts" />
            <RightProfileSideBar />
        </ProfileContainer>
    )
}

export default Profile
