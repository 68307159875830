import { Link } from "react-router-dom"
import { formatDate, formatForum } from '../../utils/helpers'
import LinesEllipsis from 'react-lines-ellipsis'
import Image from "../../layouts/Image"
import { loadState } from "../../utils/storage"
// import { useToggleVote } from "../../async-manager/queries"

const Forum = ({ question, handleVote, children }) => {
    const authedUser = loadState('id')
    const formattedQuestion = formatForum(question, authedUser)

    const {
        timestamp, tags,
        votes, title, id, image, description,
        answers, hasVoted, category
    } = formattedQuestion

    return (
        <div className="card mt-2">
            <div className="usr-question">
                <div className="usr_img">
                    <Image image={image} className='small-img' />
                </div>
                <div className="usr_quest">
                    <h3 style={{
                        marginBottom: '0.5rem'
                    }}><Link
                        className="question-title"
                        to={`/question/${id}`}>{title}</Link></h3>
                    {!children && (
                        <ul className="forum-desc">
                            <LinesEllipsis
                                text={description}
                                maxLine='3'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                            />
                        </ul>
                    )}
                    <ul className="react-links">
                        <li className={hasVoted === true ? 'active' : ''}>
                            <Link to="#" onClick={() => handleVote({ id, hasVoted, authedUser })} className={hasVoted === true ? 'active' : ''}><i className="fas fa-chevron-up"></i>Vote</Link>
                            <span>{votes.length}</span>
                        </li>
                        <li><Link to={`/question/${id}`} title=""><i className="far fa-comment-alt"></i>Answers</Link><span>{answers}</span></li>
                    </ul>
                    <ul className="blockquote mt-2">
                        <footer className="blockquote-footer mt-1 mb-2">{category}</footer>
                    </ul>
                    <ul className="quest-tags mb-2">
                        {tags && tags.map(tag => (
                            <li key={tag}><Link to="#" title="">{tag}</Link></li>
                        ))}
                    </ul>
                    <ul className="quest-tags">
                        <span className="quest-posted-time"><i className="fa fa-clock-o"></i>{formatDate(timestamp)}</span>
                    </ul>
                    <p className="mt-2"></p>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Forum
