import { memo } from "react"
import { useAddPostComment, useToggleLikePost } from "./queries"

const withLikeCommentPost = (WrappedComponent) => {
    const WrappedComponentMemo = memo(WrappedComponent)
    return props => {
        const { page, queryKey } = props
        const toggleLikePost = useToggleLikePost(queryKey, page)

        const {
            mutate: addComment, isLoading: addCommentLoading,
            error: addCommentError, isSuccess: addCommentSuccess
        } = useAddPostComment(queryKey)

        return (
            <WrappedComponentMemo
                {...props}
                onHandleLikePost={data => toggleLikePost.mutate(data)}
                onHandleAddComment={commentData => addComment(commentData)}
                addCommentLoading={addCommentLoading}
                addCommentError={addCommentError}
                addCommentSuccess={addCommentSuccess}
            />
        )
    }
}

export default withLikeCommentPost
