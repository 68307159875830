import { createContext, useMemo } from "react"

export const PostsContext = createContext()

export const PostsProvider = props => {
    const { queryKey } = props
    const initialContext = useMemo(() => ({
        queryKey
    }), [queryKey])

    return (
        <PostsContext.Provider value={initialContext}>
            {props.children}
        </PostsContext.Provider>
    )
}
