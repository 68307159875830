import { createContext, useEffect, useMemo, useState } from "react"
import $ from 'jquery'
import { Link } from "react-router-dom"
import QuestionModal from "./QuestionModal"
import { useForum } from "../../async-manager/queries"

export const ForumContext = createContext()

export const ForumProvider = props => {
    const [category_id, setCategoryId] = useState(null)
    const [page, setPage] = useState(1)

    const {
        isLoading: loading, error, data, refetch,
        isPreviousData, isFetching: loadingPage
    } = useForum({ page, category_id })

    const { questions, metadata } = data || {}
    const pageCount = (metadata && metadata[0]?.pages) || 0

    useEffect(() => {
        refetch();
    }, [category_id, refetch])

    const initialContext = useMemo(() => ({
        pageCount, questions, loading, loadingPage, error,
        onReload: () => refetch(), category_id,
        handleCategory: category_id => setCategoryId(category_id),
        handlePageClick: ({ selected }) => !isPreviousData && setPage(+selected + 1)
    }), [
        refetch, setCategoryId, setPage, category_id, isPreviousData,
        pageCount, questions, loading, loadingPage, error
    ])

    const showQuestion = () => {
        $('#questionModal').modal()
    }

    return (
        <ForumContext.Provider value={initialContext}>
            <section className="forum-sec">
                <div className="container forum-header">
                    <div className="forum-links d-flex justify-content-between">
                        <div className="active"><Link to="/forum" title="">All Questions</Link></div>
                        <div><Link to="#" onClick={showQuestion} className="ask-question">Ask a question</Link></div>
                    </div>
                    <div className="forum-links-btn">
                        <Link to="#" title=""><i className="fa fa-bars"></i></Link>
                    </div>
                </div>
            </section>
            {props.children}
            <QuestionModal />
        </ForumContext.Provider>
    )
}
