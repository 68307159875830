import React from "react"
import { Link } from 'react-router-dom'

import 'slick-carousel/slick/slick'
import PostsFeed from "./PostsFeed.js"
import withPostsData from "../async-manager/withPostsData.js"
import { getProfilePosts } from "../utils/api.js"
import { PostsProvider } from "./PostsProvider.js"
import PostAdd from "./PostAdd.js"

const ProfilePage = props => {
    const {
        errorFetchingPost, loadingPosts, pages, isFetchingNextPage,
        fetchNextPage, hasNextPage, queryKey, id
    } = props

    return (
        <PostsProvider queryKey={queryKey}>
            <div className="col-lg-6 col-md-8 no-pd profile-main">
                <div className="main-ws-sec">
                    <div className="product-feed-tab" id="my-bids">
                        <ul className="nav nav-tabs bid-tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link className="nav-link active" to={`/profile/${id}`}>Posts</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/about`}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/friends`}>Friends</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/photos`}>Photos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/videos`}>Videos</Link>
                            </li>
                        </ul>
                    </div>
                    <PostAdd />
                    <PostsFeed
                        error={errorFetchingPost}
                        loading={loadingPosts}
                        pages={pages}
                        isFetchingNextPage={isFetchingNextPage}
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                    />
                </div>
            </div>
        </PostsProvider>
    )
}

const EnhancedProfilePage = withPostsData(ProfilePage, getProfilePosts)
export default EnhancedProfilePage
