import React, { useContext, useRef, useState } from "react"
import { Link } from "react-router-dom"
import Image from "../layouts/Image"
import { loadState } from "../utils/storage"
import PostModal from "./PostModal"

import $ from 'jquery'
import { readFileAsDataURL } from "../utils/helpers"
import { PostsContext } from "./PostsProvider"

const PostAdd = () => {
    const image = loadState('image')

    const fileSize = useRef(null);
    const [error, setError] = useState(null)

    const [photoUrl, setPhotoUrl] = useState('')

    const addPhoto = e => {
        onUploadPhoto(e)
        $('#postModal').modal()
    }

    const onUploadPhoto = e => {
        const files = e.target.files
        setError(null)
        onUpdatePhoto(null)

        if (files && files.length > 0) {
            fileSize.current = files[0].size
            fileSize.current > 10 * 1024 * 1024
                ? setError('File Size should be below 10 MBs')
                : readFileAsDataURL(files[0]).then(originalURL => onUpdatePhoto(originalURL))
        }
    }

    const onUpdatePhoto = url => setPhotoUrl(url)

    const showPost = () => {
        $('#postModal').modal()
    }

    const { queryKey } = useContext(PostsContext)

    return (
        <>
            <div className="post-topbar">
                <div className="d-flex">
                    <div className="mr-3 user-picy">
                        <Image image={image} className='small-img' />
                    </div>
                    <div className="w-100 post-st">
                        <ul className="w-100" id="first">
                            <li className="w-100">
                                <Link onClick={showPost} className="w-100 border border-dark post-jb active" to="#" title="Add Post">
                                    Say something...
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="post-st mt-3 pl-5 w-100">
                    <ul>
                        <li>
                            <label className="label">
                                <input type="file" className="sr-only" onChange={addPhoto} name="image" accept="image/*" />
                                <i className="fa fa-photo"></i>
                            </label>
                        </li>
                        <li style={{ marginTop: '8px' }}><Link to="#" onClick={showPost} title=""><i className="fa fa-smile-o"></i></Link></li>
                        <li className="float-right">
                            <button className="active" type="submit" onClick={showPost} value="post">Post</button>
                        </li>
                    </ul>
                </div>
            </div>
            <PostModal
                photoUrl={photoUrl}
                error={error}
                uploadPhoto={onUploadPhoto}
                updatePhoto={onUpdatePhoto}
                queryKey={queryKey}
            />
        </>
    )
}

export default PostAdd
