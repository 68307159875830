import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import ShowMoreText from 'react-show-more-text'
import ShowMore from '@tedconf/react-show-more'
import QuestionAnswer from "./QuestionAnswer"
import QuestionRightSide from "./QuestionRightSide"
import Forum from "./Forum"
import Image from "../../layouts/Image"
import { useForumAnswers } from "../../async-manager/queries"
import Loading from "../../layouts/Loading"
import { loadState } from "../../utils/storage"
import useAnswerMutations from "../../async-manager/mutations/answers"
import ProgressDots from "../../layouts/ProgressDots"

const QuestionPage = ({ question_id }) => {
    const {
        onHandleVoteQuestion, onHandleAddAnswer,
        addAnswerSuccess, addAnswerLoading, addAnswerError
    } = useAnswerMutations({ question_id })

    const {
        data, /*error,*/ isLoading: loading
    } = useForumAnswers({ question_id })

    const { question, answers } = data || {}

    const {
        register, handleSubmit, reset,
        formState: { errors, isSubmitted, isValid }
    } = useForm({ mode: "onChange" })

    const userImage = loadState('image')

    const answerIds = answers && Object.keys(answers)

    useEffect(() => {
        if (addAnswerSuccess) {
            reset()
        }
    }, [addAnswerSuccess, reset])

    if (loading) return <Loading />

    return (
        <section className="forum-page">
            <div className="container">
                <div className="forum-questions-sec">
                    <div className="row">
                        <div className="col-lg-8">
                            <Forum question={question} handleVote={onHandleVoteQuestion}>
                                <span className='show-new-line'>
                                    <ShowMoreText
                                        lines={3}
                                        more="Show more"
                                        less="Show less"
                                        className="content-css question-desc"
                                        anchorClass="show-more"
                                        expanded={false}
                                        width={0}
                                        truncatedEndingComponent={"... "}
                                    >
                                        {question.description}
                                    </ShowMoreText>
                                </span>
                                <div className="comment-section mb-3">
                                    <div id={`box-${answers.id}`} className="comment-section">
                                        <div className="d-flex w-100 mt-2">
                                            <div className="cm_img">
                                                <Image image={userImage} className='small-img' />
                                            </div>
                                            <div className="w-100 comment_box">
                                                <form className="d-flex" onSubmit={handleSubmit(onHandleAddAnswer)}>
                                                    <input
                                                        type="hidden"
                                                        value={question?.id}
                                                        {...register('replyingTo')}
                                                    />
                                                    <textarea
                                                        className="p-2 form-control w-100"
                                                        rows="4"
                                                        {...register('text', {
                                                            required: 'Reply cannot be empty'
                                                        })}
                                                        disabled={isSubmitted && !addAnswerError}
                                                        placeholder="Add an answer ..."
                                                    />
                                                    {errors.text && <span className='text-danger'><i className="la la-info-circle la-lg"></i></span>}
                                                    <button
                                                        style={{ 'whiteSpace': 'nowrap' }}
                                                        className='p2 align-self-center'
                                                        disabled={(isSubmitted && !addAnswerError) || !isValid}
                                                        type="submit">
                                                        Post Answer
                                                    </button>
                                                </form>
                                                {addAnswerLoading && <p><ProgressDots height='80%' width='80' /></p>}
                                                {addAnswerError && <p className="text-danger">Error submitting answer: {addAnswerError}</p>}
                                            </div>
                                        </div>
                                        <ShowMore
                                            items={Object.keys(answers)}
                                            by={5}
                                        >
                                            {({ current, onMore }) => (
                                                <>
                                                    {current.map(answer => (
                                                        <QuestionAnswer
                                                            key={answer}
                                                            answer={answers[answer]}
                                                            question_id={question_id} />
                                                    ))}
                                                    <div className="float-left w-100 mt-2 d-flex">
                                                        <Link
                                                            to='#'
                                                            className={!onMore ? 'd-none' : ''}
                                                            disabled={!onMore}
                                                            onClick={() => {
                                                                if (!!onMore) onMore()
                                                            }}
                                                        >
                                                            View More Comments
                                                        </Link>
                                                        {answerIds && answerIds.length > 5 &&
                                                            <span className="ml-auto text-info">
                                                                {current.length} of {answerIds.length} comments
                                                            </span>
                                                        }
                                                    </div>
                                                </>
                                            )
                                            }
                                        </ShowMore>
                                    </div>
                                </div>
                            </Forum>
                        </div>
                        <QuestionRightSide />
                    </div>
                </div>
            </div>
        </section>
    )
}


export default QuestionPage
