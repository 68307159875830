const {
    useNews
} = require("../queries")

const useNewsData = page => {
    const {
        isLoading: loadingNews, error: newsError, data, refetch,
        isPreviousData, isFetching: loadingNewsPage
    } = useNews(page)

    const { data: news, headers } = data || {}

    return {
        loadingNews, newsError, news, refetch,
        isPreviousData, loadingNewsPage, headers
    }
}

export default useNewsData
