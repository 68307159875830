import React, { useContext } from "react"
import { useCategories } from "../../async-manager/queries"
import Scrollbars from 'react-custom-scrollbars'
import Loading from "../../layouts/Loading"
import Error from "../../layouts/Error"
import { SokoniContext } from "./SokoniProvider"

const CategoriesList = () => {
    const { category_id, handleCategory } = useContext(SokoniContext)
    const { isLoading: loading, error, data: categories, refetch } = useCategories()
    const onReload = () => refetch()
    const onhandleCategory = id => handleCategory(id)

    return (
        <div className="suggestions-list">
            <Scrollbars autoHide style={{ height: 400 }}>
                <div className="categories-list">
                    <ul>
                        {categories && categories.length > 0
                            && categories.map((category, index) => (
                                <li key={index} className={+category_id === category.id ? 'active' : ''}>
                                    <div onClick={() => onhandleCategory(category.id)} className='lead row anchor-no-decoration'>
                                        <div className='image'>
                                            <img className='category-img img-fluid ml-2 mr-2 mb-0' src={category.icon_url} alt="" />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <h4 className='h6'>{category.name}</h4>
                                            <span>{category.description}</span>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                        {error && <Error error={error} onReload={onReload} />
                        }
                        {loading && <Loading />}
                    </ul>
                </div>
            </Scrollbars>
        </div>
    )
}

export default CategoriesList
