import React, { useEffect } from "react"
import ForumContainer from "./ForumContainer"
import QuestionPage from "./QuestionPage"

const Question = props => {
    const { id } = props.match.params

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ForumContainer>
            <QuestionPage question_id={id} />
        </ForumContainer>
    )
}

export default Question
