import React from "react"
import LeftSideBar from "./LeftSideBar"
import RightSideBar from "./RightSideBar"
import PostsPage from "./PostsPage"

const Home = () => {
    return (
        <main>
            <div className="main-section home-main">
                <div className="container">
                    <div className="main-section-data">
                        <div className="row">
                            <LeftSideBar />
                            <PostsPage queryKey="posts" />
                            <RightSideBar />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Home
