import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import './css/line-awesome-font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './css/font-awesome.min.css'

import './slick/slick.css'
import './slick/slick-theme.css'

import './css/style.css'
import './css/responsive.css'

import App from './components/App'
import { Provider } from 'react-redux'

import { createStore } from 'redux'
import reducer from './reducers'
import middleware from './middleware'

const store = createStore(reducer, middleware)
const queryClient = new QueryClient()

ReactDOM.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App />
            </Provider>
        </QueryClientProvider>
    </StrictMode>,
    document.getElementById('root')
)
