import React, { useEffect, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { handleLogin } from '../actions/users'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login'

import LoginPage from './LoginPage'

const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
const facebook_client_id = process.env.REACT_APP_FACEBOOK_CLIENT_ID || ''

// const twitter_consumer_key = process.env.REACT_APP_TWITTER_CONSUMER_KEY || ''
// const twitter_consumer_secret = process.env.REACT_APP_TWITTER_CONSUMER_SECRET || ''

const Login = props => {
    const { dispatch, token } = props
    const [error, setError] = useState(null)
    const history = useHistory()

    useEffect(() => {
        if (token) {
            history.push('/')
        }
    }, [token, history])

    const {
        register,
        handleSubmit, formState: { errors },
    } = useForm()

    const onLogin = data => {
        dispatch(handleLogin(data))
            .then(res => res?.error && setError(res.error))
            .catch(err => setError(err))
    }

    const onSuccess = res => {
        console.log('All: ', JSON.stringify(res))
        console.log('[Login Google Success] currentUser: ', res.profileObj)
    }

    const onFailure = res => console.log('{Login Google failed] res: ', res)
    const responseFacebookSuccess = res => console.log('{Login facebook succeed} res: ', res)
    const responseFacebookFail = res => console.log('{Login facebook failed]} res: ', res)

    return (
        <Fragment>
            <LoginPage>
                <div className="current" id="tab-1">
                    <div className="px-5 dff-tab current" id="tab-1">
                        <h3 className="text-center sign_up">Sign in</h3>
                        {error && (<p className='mb-2 text-danger text-center'>{error}</p>)}
                        <form onSubmit={handleSubmit(onLogin)}>
                            {errors.email && <p className='text-danger'>{errors.email.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-envelope"></i></div>
                                </div>
                                <input
                                    type="email"
                                    {...register('email', {
                                        required: 'Email is required'
                                    })}
                                    className='border form-control shadow-none'
                                    placeholder="Email Address"
                                />
                            </div>
                            {errors.password && <p className='text-danger'>{errors.password.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-lock"></i></div>
                                </div>
                                <input
                                    {...register('password', {
                                        required: 'Password is required'
                                    })}
                                    type="password"
                                    className='border form-control shadow-none'
                                    placeholder="Password" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12 no-pdd">
                                    <div className="checky-sec">
                                        <div className="fgt-sec">
                                            <input type="checkbox" name="cc" id="c1" />
                                            <label htmlFor="c1">
                                                <span></span>
                                            </label>
                                            <small>Remember me</small>
                                        </div>
                                        <Link to="/t" title="">Forgot Password?</Link>
                                    </div>
                                </div>
                                <div className="mt-2 text-center col-lg-12 no-pdd d-flex justify-content-between">
                                    <span className='d-flex align-items-center register'><Link to="/register">Create Account?</Link></span>
                                    <div className='d-flex align-items-center '>
                                        <button className='sign_up_submit' type="submit" value="submit">Sign in</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="login-resources">
                            <h4 className="text-center">Login Via Social Account</h4>
                            <ul className="d-flex flex-row">
                                <li>
                                    <GoogleLogin
                                        clientId={google_client_id}
                                        render={(renderProps) => (
                                            <Link to="" title="" className="gg" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                <i className="fa fa-google"></i>
                                                Google
                                            </Link>
                                        )}
                                        isSignedIn={false}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        cookiePolicy={'single_host_origin'}
                                    >
                                        <i className="fa fa-google"></i>
                                        <span>Google</span>
                                    </GoogleLogin>
                                </li>
                                <li>
                                    <FacebookLogin
                                        appId={facebook_client_id}
                                        callback={responseFacebookSuccess}
                                        onFailure={responseFacebookFail}
                                        fields="name,email,picture"
                                        render={renderProps => (
                                            <Link to="#" title="" className="fb" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                <i className="fa fa-facebook"></i>
                                                Facebook
                                            </Link>
                                        )}
                                    />
                                </li>
                                {/* <li>
                                    <TwitterLogin
                                        authCallback={responseTwitter}
                                        consumerKey={twitter_consumer_key}
                                        consumerSecret={twitter_consumer_secret}
                                        buttonTheme={'light_short'}
                                    />
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </LoginPage>
        </Fragment>
    )
}

function mapStateToProps({ authedUser, token }) {
    return {
        authedUser,
        token,
        loading: authedUser === null
    }
}

export default connect(mapStateToProps)(Login)
