import ProfileContainer from "./ProfileContainer"
import VideosPage from "./VideosPage"

const Videos = props => {
    const { id } = props.match.params

    return (
        <ProfileContainer id={id}>
            <VideosPage id={id} />
        </ProfileContainer>
    )
}

export default Videos
