import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {

    return (
        <footer>
            <div className="footy-sec mn no-margin">
                <div className="container">
                    <ul>
                        {/* <li><Link to="help-center.html" title="">Help Center</Link></li> */}
                        {/* <li><Link to="about.html" title="">About</Link></li> */}
                        <li><Link to="/privacy-policy" title="">Privacy Policy</Link></li>
                        {/* <li><Link to="#" title="">Community Guidelines</Link></li>
                        <li><Link to="#" title="">Cookies Policy</Link></li>
                        <li><Link to="#" title="">Language</Link></li> */}
                        <li><Link to="/terms-of-use" title="">Terms Of Use</Link></li>
                    </ul>
                    <p><img src="images/copy-icon2.png" alt="" />Copyright 2019</p>
                    <img className="fl-rgt" src="images/logo2.png" alt="" />
                </div>
            </div>
        </footer>
    )
}

export default Footer
