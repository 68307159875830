import React from 'react'
import parse from 'html-react-parser'
import { decode } from 'html-entities'
import { formatDate } from '../../utils/helpers'

const NewsCard = ({ data }) => {
    const {
        title: { rendered: title },
        excerpt: { rendered: excerpt },
        jetpack_featured_media_url: img, date, link
    } = data

    return (
        <div className="job-info card pr-2">
            <div className="d-flex flex-row news-segment-details">
                <div className="w-50 mr-3">
                    <img src={img ?? '/images/no-image.png'} className="img-fluid float-none news-img" alt="" />
                </div>
                <div className='d-flex flex-column w-50 mt-2'>
                    <div className='mb-2 font-weight-bold'>{decode(title)}</div>
                    <div className=''>{parse(excerpt)}</div>
                    <p className='mt-2 row'>
                        <a href={link} target='_blank' rel="noreferrer" className="text-danger font-weight-bold">Read More...</a>
                        {/* Add time with font awesome clock icon */}
                        <span className="ml-auto text-muted"><i className='fa fa-clock-o'></i>  {formatDate(date)}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NewsCard
