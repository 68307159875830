import React from "react"
import LeftProfileSideBar from "./LeftProfileSideBar"

const ProfileContainer = props => {
    const { id, children } = props

    return (
        <>
            <section className="cover-sec profile-sec">
                <img src="/images/resources/cover-img.jpg" alt="" />
                <div className="add-pic-box">
                    <div className="container">
                        <div className="add-pic-box">
                            <div className="container">
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-sm-12">
                                        <input type="file" id="file" />
                                        <label htmlFor="file">Change Image</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                <div className="main-section">
                    <div className="container">
                        <div className="main-section-data">
                            <div className="row">
                                <LeftProfileSideBar id={id} />
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ProfileContainer
