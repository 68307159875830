const {
    useToggleVoteQuestion, useAddForumAnswer,
    useToggleLikeAnswer, useAddForumComment, useToggleLikeComment
} = require("../queries")

const useAnswerMutations = question_id => {
    const toggleVoteQuestion = useToggleVoteQuestion(question_id)
    const toggleLikeAnswer = useToggleLikeAnswer(question_id)
    const toggleLikeComment = useToggleLikeComment(question_id)

    const {
        mutate: addAnswer, isLoading: addAnswerLoading,
        error: addAnswerError, isSuccess: addAnswerSuccess
    } = useAddForumAnswer(question_id)

    const {
        mutate: addComment, isLoading: addCommentLoading,
        error: addCommentError, isSuccess: addCommentSuccess
    } = useAddForumComment(question_id)

    return {
        onHandleVoteQuestion: data => toggleVoteQuestion.mutate(data),
        onHandleLikeAnswer: data => toggleLikeAnswer.mutate(data),
        onHandleAddAnswer: answerData => addAnswer(answerData),
        onHandleAddComment: commentData => addComment(commentData),
        onHandleLikeComment: data => toggleLikeComment.mutate(data),
        addAnswerLoading, addAnswerError, addAnswerSuccess,
        addCommentLoading, addCommentError, addCommentSuccess
    }
}

export default useAnswerMutations
