import { Fragment } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"
import Error from "../../layouts/Error"
import ProgressDots from "../../layouts/ProgressDots"
import { getFriends } from "../../utils/api"
import { loadState } from "../../utils/storage"
import ProfileContainer from "../ProfileContainer"
import UserCard from "../UserCard"
import FriendsPage from "./FriendsPage"

const Friends = props => {
    const { id } = props.match.params
    const authedUserId = loadState('id')
    const isSelf = authedUserId === id

    const {
        error, data, /*refetch, isFetching,*/
        fetchNextPage, hasNextPage,
        isLoading: loading, isFetchingNextPage
    } = useInfiniteQuery({
        queryKey: ['friends', id],
        queryFn: ({ pageParam = 1 }) => getFriends({ page: pageParam, id }),
        getNextPageParam: (lastPage, page) =>
            lastPage?.metadata?.page < lastPage?.metadata?.pages
                ? lastPage?.metadata?.page + 1
                : undefined
        ,
        keepPreviousData: true,
        retry: 0
    })

    const { pages } = data || {}

    if (!pages) return null
    const users = pages.map(page => page.data).flat()

    return (
        <ProfileContainer id={id}>
            <FriendsPage id={id}>
                <div className="companies-list">
                    {loading && <ProgressDots />}
                    <InfiniteScroll
                        dataLength={users.length}
                        next={() => fetchNextPage()}
                        hasMore={hasNextPage}
                        loader={isFetchingNextPage && <ProgressDots />}
                        style={{ overflowY: 'hidden' }}
                    >
                        <div className="d-flex flex-wrap">
                            {pages.map((group, i) => {
                                const { data } = group
                                return (
                                    <Fragment key={i}>
                                        {data && data.map(user =>
                                            <UserCard
                                                key={user.id}
                                                id={user.id}
                                                user={user}
                                                status='friends'
                                                isSelf={isSelf} />
                                        )}
                                    </Fragment>
                                )
                            })}
                        </div>
                    </InfiniteScroll>
                    {error && <Error error={error} onReload={() => fetchNextPage()} />}
                </div>
            </FriendsPage>
        </ProfileContainer>
    )
}

export default Friends
