import ForumPage from "./ForumPage"

import ForumContainer from "./ForumContainer"

const Forum = () => {
    return (
        <ForumContainer>
            <ForumPage />
        </ForumContainer>
)}

export default Forum
