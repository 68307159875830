import { createContext, useMemo, useState } from "react"

export const MessageContext = createContext()

export const MessageProvider = props => {
    const [activeFriend, setActiveFriend] = useState()

    const initialContext = useMemo(() => ({
        activeFriend,
        handleSetActiveFriend: friend => setActiveFriend(friend)
    }), [activeFriend])

    return (
        <main className="w-100">
            <section className="messages-page">
                <div className="container">
                    <div className="messages-sec">
                        <div className="row">
                            <MessageContext.Provider value={initialContext}>
                                {props.children}
                            </MessageContext.Provider>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
