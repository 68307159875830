import React from 'react'
import { Link } from 'react-router-dom'

const LoginPage = props => {
    const { children } = props

    return (
        <div className="sign-in">
            <div className="wrapper">
                <div className="sign-in-page">
                    <div className="signin-popup">
                        <div className="signin-pop">
                            <div className="row">
                                <div className="col-lg-6 d-flex align-items-center">
                                    <div className="cmp-info">
                                        <div className="cm-logo">
                                            <img src="images/cm-logo.png" alt="" />
                                            <p>CoopConnect, is a global freelancing platform and social networking where
                                                businesses and independent professionals connect and collaborate remotely</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="login-sec">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footy-sec">
                        <div className="container">
                            <div className=" row justify-content-center">
                                <ul>
                                    {/* <li><a href="help-center.html" title="">Help Center</a></li>
                                    <li><a href="about.html" title="">About</a></li> */}
                                    <li><Link to="/privacy-policy" title="">Privacy Policy</Link></li> |
                                    {/* <li><a href="2" title="">Community Guidelines</a></li>
                                    <li><a href="3" title="">Cookies Policy</a></li>
                                    <li><a href="4" title="">Career</a></li>
                                    <li><a href="forum.html" title="">Forum</a></li>
                                    <li><a href="5" title="">Language</a></li> */}
                                    <li><Link to="/terms-of-use" title="">Terms Of Use</Link></li>
                                </ul>
                            </div>
                            <div className="mt-2 row justify-content-center">
                                <p> &copy; Copyright 2019. The Uhuru Institue for Social Development</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
