import { useEffect, useRef, useState } from 'react'
import LoginPage from './LoginPage'

import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { handleRegister } from '../actions/users'
import Select from '../layouts/Select'
import { countries } from '../utils/countries'
import { loadState } from '../utils/storage'

const Register = props => {
    const history = useHistory()
    const dispatch = useDispatch()

    const [error, setError] = useState(null)
    const [token,] = useState(loadState('token'))

    useEffect(() => {
        if (token) {
            history.push('/')
        }
    }, [token, history])

    const {
        register, handleSubmit,
        watch, control,
        formState: { errors },
    } = useForm()

    const password = useRef({})
    password.current = watch("password", "")

    const onRegister = data => {
        const { confirm_password, ...input } = data

        dispatch(handleRegister(input))
            .then(res => {
                const { status } = res
                if (!status) {
                    res?.errorMessage && setError(res.errorMessage)
                } else {
                    history.replace('/login')
                }
            })
    }

    return (
        <LoginPage>
            <div className="current" id="tab-2">
                <div className="px-5 dff-tab current" id="tab-1">
                    <h3 className="text-center sign_up">Sign Up</h3>
                    {error && (<p className='mb-2 text-danger text-center'>{error}</p>)}
                    <form onSubmit={handleSubmit(onRegister)}>
                        <div className="">
                            {errors.first_name && <p className='jerror text-danger'>{errors.first_name.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-user"></i></div>
                                </div>
                                <input
                                    type="text"
                                    {...register('first_name', {
                                        required: 'Enter your first name below'
                                    })}
                                    placeholder="First Name"
                                    className='border form-control shadow-none'
                                />
                            </div>
                            {errors.last_name && <p className='jerror text-danger'>{errors.last_name.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-user"></i></div>
                                </div>
                                <input
                                    type="text"
                                    {...register('last_name', {
                                        required: 'Enter your last name below'
                                    })}
                                    placeholder="Last Name"
                                    className='border form-control shadow-none'
                                />
                            </div>
                            {errors.email && <p className='jerror text-danger'>{errors.email.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-envelope"></i></div>
                                </div>
                                <input
                                    type="email"
                                    {...register('email', {
                                        required: 'Enter your email address below'
                                    })}
                                    className='border form-control shadow-none'
                                    placeholder="Email Address"
                                />
                            </div>
                            {errors.country && <p className='text-danger'>{errors.country.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-globe"></i></div>
                                </div>
                                <Select
                                    name="country"
                                    placeholder="Choose Country"
                                    control={control}
                                    options={countries} />
                            </div>
                            {errors.password && <p className='jerror text-danger'>{errors.password.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-lock"></i></div>
                                </div>
                                <input
                                    {...register('password', {
                                        required: 'You must specify a password',
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters"
                                        }
                                    })}
                                    type="text"
                                    className='border form-control shadow-none'
                                    placeholder="Password" />
                            </div>
                            {errors.confirm_password && <p className='jerror text-danger'>{errors.confirm_password.message}</p>}
                            <div className="input-group mb-3 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="la la-lock"></i></div>
                                </div>
                                <input
                                    {...register('confirm_password', {
                                        required: 'Confirm your password below',
                                        validate: value => value === watch('password') || "Passwords don't match."
                                    })}
                                    type="text"
                                    className='border form-control shadow-none'
                                    placeholder="Repeat password" />
                            </div>
                            <div className="col-lg-12 no-pdd">
                                <div className="checky-sec st2">
                                    <div className="fgt-sec">
                                        <input type="checkbox" name="cc" id="c2" />
                                        <label htmlFor="c2">
                                            <span></span>
                                        </label>
                                        <small>Yes, I understand and agree to the CoopConnect Terms
                                            &amp; Conditions.</small>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 text-center col-lg-12 no-pdd d-flex justify-content-between">
                                <span className='d-flex align-items-center register'><Link to="/login">Sign in instead</Link></span>
                                <div className='d-flex align-items-center '>
                                    <button className='sign_up_submit' type="submit" value="submit">Sign Up</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </LoginPage >
    )
}

export default Register
