import React from 'react'
import { ForumProvider } from './ForumProvider'

const ForumContainer = props => {
    const { children } = props

    return (
        <ForumProvider>
            {children}
        </ForumProvider>
    )
}

export default ForumContainer
