import {
    getInitialData
} from '../utils/api'
import { receiveUsers } from './users'
import { setToken } from './token'
import { setAuthedUser } from './authedUser'

export function handleInitialData() {
    return dispatch => {
        return getInitialData()
            .then(({ users }) => {
                if (users.status === 'fulfilled') dispatch(receiveUsers(users.value))
            })
            .catch(error => {
                console.log('Error', error)
            })
    }
}
export function handleGetOut() {
    return dispatch => {
        localStorage.clear()
        dispatch(setToken(null))
    }
}

export function handleLogOut() {
    return dispatch => {
        localStorage.clear()
        dispatch(setToken(null))
        dispatch(setAuthedUser(null))
        dispatch(receiveUsers(null))
    }
}
