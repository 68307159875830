import React, { useEffect, useState } from "react"
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import $ from 'jquery'
import { handleLogOut } from "../actions/shared"
import Image from "../layouts/Image"

const NavBar = props => {
    const { dispatch } = props
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const history = useHistory()
    const author_id = props?.user?.id

    useEffect(() => {
        const image = props?.user?.image
        const name = props?.user?.name

        setName(name)
        setImage(image)
    }, [props])

    const enterShowUser = () => {
        $(".user-account-settingss").slideToggle("fast")
        $("#message").not($(this).next("#message")).slideUp()
        $("#notification").not($(this).next("#notification")).slideUp()
    }

    const leaveShowUser = () => {
        $(".user-account-settingss").slideToggle("fast")
        $("#message").not($(this).next("#message")).slideUp()
        $("#notification").not($(this).next("#notification")).slideUp()
    }

    const onLogout = () => {
        dispatch(handleLogOut())
        history.replace('/login')
    }

    return (
        <header className="sticky-top mb-4">
            <div className='d-flex'>
                <div className="header-data">
                    <div className="d-flex justify-content-start logo">
                        <Link to="/" title=""><img src="/images/logo.png" alt="" /></Link>
                    </div>
                    {props.authedUser && (
                        <>
                            <div className="d-flex justify-content-start search-bar">
                                <form>
                                    <input type="text" name="search" placeholder="Search..." />
                                    <button type="submit"><i className="la la-search"></i></button>
                                </form>
                            </div>
                            <nav className='d-flex justify-content-between pl-5'>
                                <ul className='d-flex justify-content-center'>
                                    <li>
                                        <Link to="/" title="">
                                            <span><i className="fa fa-home"></i></span>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/profile/${author_id}`} title="">
                                            <span><i className="fa fa-user"></i></span>
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/messages" title="" className="not-box-openm">
                                            <span><i className="fa fa-envelope"></i></span>
                                            Messages
                                        </Link>
                                        <div className="notification-box msg" id="message">
                                            <div className="nt-title">
                                                <h4>Setting</h4>
                                                <Link to="/#" title="">Clear all</Link>
                                            </div>
                                            <div className="nott-list">
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img1.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/messages.html" title="">Jassica William</Link> </h3>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</p>
                                                        <span>2 min ago</span>
                                                    </div>
                                                </div>
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img2.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/messages.html" title="">Jassica William</Link></h3>
                                                        <p>Lorem ipsum dolor sit amet.</p>
                                                        <span>2 min ago</span>
                                                    </div>
                                                </div>
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img3.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/messages.html" title="">Jassica William</Link></h3>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempo incididunt ut labore et dolore magna aliqua.</p>
                                                        <span>2 min ago</span>
                                                    </div>
                                                </div>
                                                <div className="view-all-nots">
                                                    <Link to="/messages.html" title="">View All Messsages</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/forum" title="">
                                            <span><i className="fas fa-gopuram"></i></span>
                                            Forum
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/news" title="">
                                            <span><i className="fas fa-newspaper"></i></span>
                                            News
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/marketplace" title="">
                                            <span><i className="fas fa-shopping-basket"></i></span>
                                            Marketplace
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/#" title="" className="not-box-open">
                                            <span><i className="fas fa-bell"></i></span>
                                            Notifications
                                        </Link>
                                        <div className="notification-box noti" id="notification">
                                            <div className="nt-title">
                                                <h4>Setting</h4>
                                                <Link to="/#" title="">Clear all</Link>
                                            </div>
                                            <div className="nott-list">
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img1.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/#" title="">Jassica William</Link> Comment on your project.
                                                        </h3>
                                                        <span>2 min ago</span>
                                                    </div>
                                                    {/* <!--notification-info --> */}
                                                </div>
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img2.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/#" title="">Jassica William</Link> Comment on your project.
                                                        </h3>
                                                        <span>2 min ago</span>
                                                    </div>
                                                    {/* <!--notification-info --> */}
                                                </div>
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img3.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/#" title="">Jassica William</Link> Comment on your project.
                                                        </h3>
                                                        <span>2 min ago</span>
                                                    </div>
                                                    {/* <!--notification-info --> */}
                                                </div>
                                                <div className="notfication-details">
                                                    <div className="noty-user-img">
                                                        <img src="images/resources/ny-img2.png" alt="" />
                                                    </div>
                                                    <div className="notification-info">
                                                        <h3><Link to="/#" title="">Jassica William</Link> Comment on your project.
                                                        </h3>
                                                        <span>2 min ago</span>
                                                    </div>
                                                    {/* <!--notification-info --> */}
                                                </div>
                                                <div className="view-all-nots">
                                                    <Link to="/#" title="">View All Notification</Link>
                                                </div>
                                            </div>
                                            {/* <!--nott-list end--> */}
                                        </div>
                                        {/* <!--notification-box end--> */}
                                    </li>
                                </ul>
                                <div className="d-flex justify-content-end user-account" onMouseEnter={enterShowUser} onMouseLeave={leaveShowUser}>
                                    <div className="user-info mr-4">
                                        <Image image={image} />
                                        <Link to="#" title="">{name}</Link>
                                        <i className="la la-sort-down"></i>
                                    </div>
                                    <div className="user-account-settingss" id="users">
                                        <ul className="us-links">
                                            <li><Link to="/settings" title="">Account Settings</Link></li>
                                            <li><Link to="/#" title="">Privacy</Link></li>
                                            <li><Link to="/#" title="">Faqs</Link></li>
                                            <li><Link to="/#" title="">Terms & Conditions</Link></li>
                                        </ul>
                                        <h3 className="tc"><Link to="#" onClick={onLogout} >Logout</Link></h3>
                                    </div>
                                </div>
                            </nav>
                        </>
                    )
                    }
                    <div className="menu-btn">
                        <Link to="/#" title=""><i className="fa fa-bars"></i></Link>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = ({ authedUser, users }) => {
    return {
        authedUser,
        user: users[authedUser]
    }
}

export default connect(mapStateToProps)(NavBar)
