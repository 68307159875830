import React, { useContext, useState } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import CategoriesList from './CategoriesList'
import { SokoniContext } from './SokoniProvider'

const LeftSideBarSokoni = () => {
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(4000000)

    const { onHandleChange, onHandleReset } = useContext(SokoniContext)

    const handleChange = value => {
        setMin(value[0])
        setMax(value[1])
    }

    const handleMinChange = e => {
        handleChange([e.target.value, max])
    }

    const handleMaxChange = e => {
        handleChange([min, e.target.value])
    }

    const onHandleRange = () => onHandleChange([min, max])
    const handleReset = () => {
        onHandleReset()
        setMin(0)
        setMax(4000000)
    }

    return (
        <div className="d-none d-lg-block col-lg-3 col-md-4 pd-left-none no-pd">
            <div className="main-left-sidebar no-margin">
                <div className="user-data full-width">
                    <div className="user-profile">
                        <div className="sokoni-dt">
                            <div className="sokoni-pic">
                                <img src='/images/sokoni.png' alt="" />
                            </div>
                        </div>
                    </div>
                    <ul className="sokoni-fw-status">
                        <li style={{ padding: '20px 20px 20px 10px' }}>
                            <Slider
                                range
                                pushable
                                min={0}
                                max={4000000}
                                defaultValue={[min, max]}
                                value={[min, max]}
                                tipFormatter={value => `${value} UGX`}
                                tipProps={{ placement: 'top', visible: true }}
                                marks={{
                                    0: {
                                        style: {
                                            color: 'black',
                                            marginLeft: '5px',
                                            marginTop: '3px',
                                            fontSize: '0.8rem'
                                        },
                                        label: <b>0 UGX</b>,
                                    },
                                    4000000: {
                                        style: {
                                            color: 'black',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '-20px',
                                            marginTop: '3px',
                                            fontSize: '0.8rem'
                                        },
                                        label: <b>4,000,000 UGX</b>,
                                    }
                                }}
                                onChange={handleChange}
                                trackStyle={[
                                    { backgroundColor: '#8e0b56', borderColor: '#8e0b56', height: '5px', opacity: 'inherit' },
                                    { backgroundColor: '#8e0b56', borderColor: '#8e0b56', height: '5px', opacity: 'inherit' }
                                ]}
                                handleStyle={[
                                    { backgroundColor: '#8e0b56', borderColor: '#8e0b56', opacity: 'inherit' },
                                    { backgroundColor: '#8e0b56', borderColor: '#8e0b56', opacity: 'inherit' }
                                ]}
                                railStyle={{ backgroundColor: 'gray' }}
                                dotStyle={{ display: 'none' }}
                            />
                        </li>
                        <li>
                            <div className="input-group-sm d-flex mb-2">
                                <input type={'number'} onChange={handleMinChange} value={min} className="border form-control shadow-none rounded" name="min" placeholder="Min" />
                                <span className='text-muted divider'> - </span>
                                <input type={'number'} onChange={handleMaxChange} value={max} className="border form-control shadow-none rounded" name="max" placeholder="Max" />
                            </div>
                            <div className="d-flex justify-content-around col-lg-12 no-pdd">
                                <button onClick={handleReset} className='btn btn-secondary btn-sm'>RESET</button>
                                <button onClick={onHandleRange} className='btn btn-primary btn-sm'>APPLY</button>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="suggestions full-width">
                    <div className="sd-title">
                        <h3>Product Categories</h3>
                        <i className="la la-ellipsis-v"></i>
                    </div>
                    <CategoriesList />
                </div>

                {/* <div className="tags-sec full-width">
                    <ul>
                        <li><Link to="#" title="">Help Center</Link></li>
                        <li><Link to="#" title="">About</Link></li>
                        <li><Link to="#" title="">Privacy Policy</Link></li>
                        <li><Link to="#" title="">Community Guidelines</Link></li>
                        <li><Link to="#" title="">Cookies Policy</Link></li>
                        <li><Link to="#" title="">Career</Link></li>
                        <li><Link to="#" title="">Language</Link></li>
                        <li><Link to="#" title="">Copyright Policy</Link></li>
                    </ul>
                    <div className="cp-sec">
                        <img src="images/logo2.png" alt="" />
                        <p><img src="images/cp.png" alt="" />Copyright 2019</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default LeftSideBarSokoni
