import React from "react"
import LeftSideBarSokoni from "./LeftSideBarSokoni"
import SokoniMainPage from "./SokoniMainPage"
import { SokoniProvider } from "./SokoniProvider"

const Sokoni = () => (
    <SokoniProvider>
        <LeftSideBarSokoni />
        <SokoniMainPage />
    </SokoniProvider>
)

export default Sokoni
