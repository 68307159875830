/**
 * @module utils/backendAPI
 * @description HTTP Client module for making HTTP requests to external services
 */
import axios from 'axios'
import { loadState } from './storage'

let authInterceptorID

const API_URL = process.env.REACT_APP_API || 'xxx'

const backendAPI = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: API_URL
})

authInterceptorID = backendAPI.interceptors.request.use(
  config => {
    const token = loadState('token')
    config.headers = {
      'Authorization': `Bearer ${token}`
    }
    return config;
  },
  response => response,
  error => Promise.reject(error)
)

const authInterceptor = config => {
  const token = loadState('token')
  config.headers = {
    'Authorization': `Bearer ${token}`
  }
  return config
}

export function setAuthHeader() {
  authInterceptorID = backendAPI.interceptors.request.use(authInterceptor)
}

export function removeAuthHeader() {
  backendAPI.interceptors.request.eject(authInterceptorID)
  authInterceptorID = null
}

export default backendAPI
