import React, { useEffect } from 'react'
// import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ShowMoreText from 'react-show-more-text'
import { formatDate, formatPost } from '../utils/helpers'

import 'bootstrap/js/dist/collapse'
import { useForm } from 'react-hook-form'
import Linkify from 'linkifyjs/react'
import ShowMore from '@tedconf/react-show-more'

import Comments from './Comments'
import { useState } from 'react'
import Name from '../layouts/Name'
import Image from '../layouts/Image'
import { loadState } from '../utils/storage'
import withLikeCommentPost from '../async-manager/withLikeCommentPost'

const Post = props => {
    const {
        post: postDetails, page, addCommentSuccess, queryKey,
        onHandleLikePost, onHandleAddComment, addCommentError
    } = props
    const [active, setActive] = useState(null)
    const authedUser = loadState('id')
    const userImage = loadState('image')

    const post = postDetails && formatPost(postDetails, authedUser)

    const {
        register, reset, handleSubmit,
        formState: { errors, isSubmitted, isValid }
    } = useForm({ mode: "onChange" })

    const {
        name, timestamp,
        text, hasLiked, likes, comments,
        commentsLength, id, image, photo, author_id
    } = post || {}

    const showOpen = () => {
        const status = active === null ? 'active' : null
        setActive(status)
    }

    useEffect(() => {
        if (addCommentSuccess) {
            reset()
        }
    }, [addCommentSuccess, reset])

    return (
        <div className="post-bar">
            <div className="post_topbar">
                <div className="usy-dt">
                    <Image image={image} className='small-img' />
                    <div className="usy-name">
                        <Name author_id={author_id} name={name} />
                        <span><img src="images/clock.png" alt="" />{formatDate(timestamp)}</span>
                    </div>
                </div>
                <div className="ed-opts">
                    <Link to="#" title="" className="ed-opts-open" onClick={showOpen}>
                        <i className="la la-ellipsis-h"></i>
                    </Link>
                    <ul className={`ed-options ${active}`}>
                        <li><Link to="#" title="">Edit Post</Link></li>
                        <li><Link to="#" title="">Delete Post</Link></li>
                    </ul>
                </div>
            </div>
            <div className="mt-2 job_descp">
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="show-new-line"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                >
                    <Linkify tagName="p" className='show-new-line'>
                        {text}
                    </Linkify>
                </ShowMoreText>
                {photo &&
                    <div className="row text-center">
                        <img src={photo} className="rounded img-fluid vw-100" alt="" />
                    </div>
                }
            </div>
            <div className="job-status-bar">
                <ul className="like-com">
                    <li>
                        <Link
                            to="#"
                            onClick={() => onHandleLikePost({ id, hasLiked, authedUser })}
                            className={hasLiked === true ? 'active' : ''}>
                            <i className="fas fa-heart"></i> Like
                        </Link>
                        <span>{likes !== 0 && likes}</span>
                    </li>
                    <li>
                        <Link to="#" data-toggle="collapse" data-target={`#box-${id}`}><i className="fas fa-comment-alt"></i>
                            Comments
                        </Link>
                        <span>{commentsLength}</span>
                    </li>
                </ul>
            </div>
            <div id={`box-${id}`} className="collapse comment-section">
                <div className="d-flex post-comment">
                    <div className="cm_img">
                        <Image image={userImage} />
                    </div>
                    <div className="w-100 comment_box">
                        <form className="d-flex" onSubmit={handleSubmit(onHandleAddComment)}>
                            <input
                                type="hidden"
                                value={id}
                                {...register('replyingTo')}
                            />
                            <input
                                className="w-100"
                                type="text"
                                {...register('text', {
                                    required: 'Reply cannot be empty'
                                })}
                                disabled={isSubmitted && !addCommentError}
                                placeholder="Add a comment ..."
                            />
                            {errors.text && <span className='text-danger'><i className="la la-info-circle la-lg"></i></span>}
                            <button
                                style={{ 'whiteSpace': 'nowrap' }}
                                disabled={(isSubmitted && !addCommentError) || !isValid}
                                type="submit">
                                Comment
                            </button>
                        </form>
                    </div>
                </div>
                {comments && (
                    <ShowMore
                        items={Object.keys(comments).sort((a, b) => comments[b].createdAt - comments[a].createdAt)}
                        by={5}
                    // onEnd={() => {
                    //     console.log("end of the list");
                    // }}
                    >
                        {({ current, onMore }) => (
                            <>
                                {current.map(comment => (
                                    <Comments
                                        key={comment}
                                        comment={comments[comment]}
                                        post_id={id}
                                        queryKey={queryKey}
                                        page={page} />
                                ))}
                                <div className="float-left w-100 mt-2 d-flex">
                                    <Link
                                        to="#"
                                        className={!onMore ? 'd-none' : ''}
                                        disabled={!onMore}
                                        onClick={() => {
                                            if (!!onMore) onMore()
                                        }}
                                    >
                                        View More Comments
                                    </Link>
                                    {Object.keys(comments).length > 5 &&
                                        <span className="ml-auto text-info">
                                            {current.length} of {Object.keys(comments).length} comments
                                        </span>
                                    }
                                </div>
                            </>
                        )}
                    </ShowMore>
                )}
            </div>
        </div>
    )
}

const EnhancedPost = withLikeCommentPost(Post)
export default EnhancedPost
