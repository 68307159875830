import React, { useContext } from "react"
import { connect } from "react-redux"
import Image from "../../layouts/Image"
import { MessageContext } from "../MessageProvider"

const MessageFriend = props => {
    const { hoverFriend, onMouseOver } = props || ''
    const { name, id, image } = props?.friend || ''
    const { activeFriend, handleSetActiveFriend } = useContext(MessageContext)

    return (
        <li
            onMouseDown={() => handleSetActiveFriend(id)}
            onMouseOver={() => onMouseOver && onMouseOver(id)}
            onMouseOut={() => onMouseOver && onMouseOver()}
            className={(activeFriend === id || hoverFriend === id) ? "active" : ""}>
            <div className="d-flex align-items-center usr-msg-details">
                <div className="usr-friends-img">
                    <Image image={image} />
                </div>
                <div className="usr-friends-info">
                    <h3>{name}</h3>
                    <p>No mutual friends</p>
                </div>
                <span className="d-flex msg-friends-status"></span>
            </div>
        </li>
    )
}

const mapStateToProps = ({ users, authedUser }, { id, activeFriend, hoverFriend, onMouseOver }) => {
    const friend = users[id]

    return {
        friend, activeFriend, hoverFriend, onMouseOver
    }
}

export default connect(mapStateToProps)(MessageFriend)
