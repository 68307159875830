import React from "react";
import { Link } from "react-router-dom";

const ForumListGroup = () =>
    <div className="mb-3 mt-2">
        <ul className="list-group">
            <li className="list-group-item active" aria-current="true">
                <Link to="/forum">
                    All Questions
                </Link>
            </li>
            <li className="list-group-item">
                <Link to="/">
                    Tags
                </Link>
            </li>
            <li className="list-group-item">
                <Link to="/">Unanswered</Link>
            </li>
            <li className="list-group-item">
                <Link to="/">Trending</Link>
            </li>
            <li className="list-group-item">
                <Link to="/">Popular This Week</Link>
            </li>
            <li className="list-group-item">
                <Link to="/">Popular This Month</Link>
            </li>
        </ul>
    </div>

export default ForumListGroup;
