import React from "react"
import { Link } from "react-router-dom"

const Name = ({ author_id, name }) =>
    <Link
        className='text-decoration-none text-dark'
        to={`/profile/${author_id}`}
        title={name}
        style={{ maxWidth: '12rem' }}
    >
        <h3 className="text-truncate">{name}</h3>
    </Link>

export default Name
