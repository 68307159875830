import React from "react"

import 'slick-carousel/slick/slick'
import PostsFeed from "./PostsFeed.js"
import withPostsData from "../async-manager/withPostsData.js"
import { getPosts } from "../utils/api.js"
import { PostsProvider } from "./PostsProvider.js"
import PostAdd from "./PostAdd.js"

const PostsPage = props => {
    const {
        errorFetchingPost, loadingPosts, pages, isFetchingNextPage,
        fetchNextPage, hasNextPage, queryKey
    } = props

    return (
        <PostsProvider queryKey={queryKey}>
            <div className="col-lg-6 col-md-8 no-pd">
                <div className="main-ws-sec">
                    <PostAdd />
                    <PostsFeed
                        error={errorFetchingPost}
                        loading={loadingPosts}
                        pages={pages}
                        isFetchingNextPage={isFetchingNextPage}
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                    />
                </div>
            </div>

        </PostsProvider>
    )
}

const EnhancedPostsPage = withPostsData(PostsPage, getPosts)
export default EnhancedPostsPage
