import React from 'react'
import NavBar from './NavBar'

const PrivacyPolicy = () => (
    <>
        <NavBar />
        <section className="legal-document">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h2>PRIVACY POLICY</h2>
                        <p className='text-muted font-weight-normal mb-1'>Last updated: 19th September, 2023</p>
                        <p>
                            This privacy notice for Coop Connect ('<span className='font-weight-bold'>we</span>', '<span className='font-weight-bold'>us</span>', or '<span className='font-weight-bold'>our</span>'),
                            describes how and why we might collect, store, use, and/or share ('process') your information when you use our platform ('<span className='font-weight-bold'>Platform</span>'), such as when you:
                        </p>
                        <ul className="bulleted">
                            <li>Visit the link at <a href="https://coopconnect.coop/privacy-policy">https://coopconnect.coop/privacy-policy</a>, that links to this privacy notice</li>
                            <li>Interact with us through various other channels, encompassing sales, marketing, and events.</li>
                        </ul>
                        <p className='mt-2'>
                            <span className='font-weight-bold'>Questions or concerns?</span> Reviewing this privacy policy will provide you with insights into your privacy rights and options.
                            If you find yourself in disagreement with our policies and practices, we kindly request that you refrain from using our Platform.
                            If you still have any questions or concerns, please contact us at <a href="info@coopconnect.coop">info@coopconnect.coop</a>.
                        </p>
                        <ol className="numbered">
                            <li><span className='font-weight-bold'>Summary of Key Points</span></li>
                            <div>
                                <em>
                                    This summary offers essential highlights from our privacy notice. For more in-depth information on any of these subjects, you can click the respective links provided after each key point or
                                    navigate to the specific section using our table of contents below.
                                </em>
                                <div>
                                    <ol className="numbered-indent">
                                        <li>
                                            <span className='font-weight-bold'>What personal information do we process?</span>
                                            <div>
                                                When you visit, use, or navigate our Platform, we may process personal information depending on how you interact with us and the Platform, the choices you make, and the products and features you use.
                                                Explore further details regarding the <a href='#personal information you disclose to us'>personal information you disclose to us</a>.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Do we process any sensitive personal information?</span>
                                            <div>
                                                We want to clarify that we do not receive any information from third parties. Your data privacy is of utmost importance to us, and we do not engage in data sharing or acquisition from external sources.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Do we receive any information from third parties?</span>
                                            <div>
                                                We want to clarify that we do not receive any information from third parties. Your data privacy is of utmost importance to us, and we do not engage in data sharing or acquisition from external sources.
                                            </div>
                                        </li>
                                        <li>
                                            <span id='how-we-process-your-information' className='font-weight-bold'>How do we process your information? </span>
                                            <div>
                                                We process your information to provide, improve, and administer our Platform, communicate with you, for security and fraud prevention, and to comply with the law. Additionally, we might process your
                                                information for alternative purposes, contingent upon obtaining your consent. We process your information exclusively when we have a valid legal basis for doing so. Explore further details regarding <a href='#how-we-process-your-information'>how we process your information</a>.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Under what circumstances and with whom do we share personal information?</span>
                                            <div>
                                                We engage in sharing information under specific circumstances and with designated third parties. Explore further details regarding <a href='#when-and-with-whom-we-share-your-personal-information'>when and with whom we share your personal information</a>.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>How do we keep your information safe?</span>
                                            <div>
                                                While we have implemented organisational and technical processes and procedures to safeguard your personal information, it's important to note that no electronic transmission over the internet or information storage technology can be guaranteed
                                                to be completely secure. Therefore, we cannot provide a promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will be unable to circumvent our security measures and improperly collect, access, steal, or alter your
                                                information. Explore further details regarding <a href='#how-we-keep-your-information-safe'>how we keep your information safe</a>.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>What are your rights?</span>
                                            <div>
                                                Your location's geographical context plays a significant role in determining the relevant privacy laws that may grant you specific rights concerning your personal information. Explore further details regarding <a href='#your-privacy-rights'>your privacy rights</a>.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>How do you exercise your rights?</span>
                                            <div>
                                                The easiest way to exercise your rights is by visiting https://coopconnect.coop/privacy-policy, or by contacting us. We will assess and respond to any request in alignment with the relevant data protection regulations in effect.
                                                <p className='mt-2'>
                                                    Interested in gaining a deeper understanding of how we handle the information we gather? Review the privacy notice in full.
                                                </p>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <li><span className='font-weight-bold'>What information do we collect?</span></li>
                            <div>
                                <em>
                                    In Short: We gather personal information that you voluntarily provide to us.
                                </em>
                                <div>
                                    <ol className="numbered-indent">
                                        <li>
                                            <span id='personal information you disclose to us' className='font-weight-bold'>Personal information you disclose to us</span>
                                            <div>
                                                We collect personal information that you voluntarily provide to us when you register on the platform,
                                                when you participate in activities on the platform, or otherwise when you contact us.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Personal Information Provided by You</span>
                                            <div>
                                                The personal information that we collect depends on the context of your interactions with us and the Platform, the choices you make, and the products and features you use.
                                                The personal information we gather may encompass the following:
                                                <ol className="bulleted">
                                                    <li>names</li>
                                                    <li>phone numbers</li>
                                                    <li>email addresses</li>
                                                    <li>usernames</li>
                                                    <li>passwords</li>
                                                </ol>
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Sensitive Information</span>
                                            <div>
                                                We do not process sensitive information.
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Social Media Login Data</span>
                                            <div>
                                                We may offer you the opportunity to register using your existing social media account details, such as Facebook, Twitter, or other
                                                social media platforms. If you opt for this registration method, we will gather the information outlined in the section titled <a href='#how-do-we-handle-your-social-logins'>HOW DO WE HANDLE YOUR SOCIAL LOGINS?'</a> below.
                                                <p className='mt-2'>
                                                    All personal information you furnish to us must be truthful, comprehensive, and precise. Additionally, it is your responsibility to inform us promptly of any modifications to such personal information.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <span className='font-weight-bold'>Information automatically collected</span>
                                            <div>
                                                <em>
                                                    In Short: Certain information, such as your Internet Protocol (IP) address and/or browser and device characteristics, is automatically collected when you access our Platform.
                                                </em>
                                                <p className='mt-2'>
                                                    During your interactions with the Platform, we automatically gather specific information. This data does not disclose your individual identity, such as your name or contact details, but it may
                                                    encompass details related to your device and usage. This could involve your IP address, browser and device attributes, operating system, language preferences, referring URLs, device name, country,
                                                    location, insights into how and when you utilise our Platform, and additional technical information. The primary purpose of collecting this information is to ensure the security and functionality of our Platform, as well as for our internal analytical and reporting needs.
                                                </p>
                                                <div className='mt-2'>
                                                    The information we collect includes:
                                                    <ol className="bulleted">
                                                        <li>
                                                            <span className='font-weight-bold'>Log and Usage Data</span>. Log and usage data encompass service-related, diagnostic, usage, and performance information that our servers automatically collect when you access or utilise our Platform. We record this data in log files. The log data we compile can vary based on
                                                            your interactions with us. It may encompass your IP address, device particulars, browser specifications, and settings, as well as details regarding your activities within the Platform. This includes date and time stamps associated with your usage, the pages and files you
                                                            view, your search queries, and other actions you perform, such as which features you engage with. Additionally, it may involve device event data, such as system activities, error reports (referred to as 'crash dumps' at times), and hardware configurations.
                                                        </li>
                                                        <li>
                                                            <span className='font-weight-bold'>Device Data</span>. We gather device data, which comprises information concerning the computer, phone, tablet, or any other device utilised to access the Platform. Depending on the specific device, this data may encompass details such as your IP address (or proxy server), device and application identification numbers,
                                                            location, browser type, hardware model, Internet service provider, and/or mobile carrier, operating system, as well as system configuration information.
                                                        </li>
                                                        <li>
                                                            <span className='font-weight-bold'>Location Data</span>. We gather location data, which may pertain to your device's location, ranging from precise to imprecise information. The extent of data collected is contingent upon the type and settings of the device used to access the Platform. For instance, we might employ GPS and other technologies to obtain geolocation data that provides insights into
                                                            your current location, often determined by your IP address. You have the option to decline the collection of this information either by denying access to the data or by disabling your device's location setting. Nonetheless, opting out may result in limitations on your ability to utilise certain features of the Platform.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <li><span className='font-weight-bold'>How do we process this information?</span></li>
                            <div>
                                <em>
                                    In Short: We process your information for various purposes, including providing, enhancing, and managing our Platform, communicating with you, ensuring security and fraud prevention, and complying
                                    with legal requirements. Additionally, we may process your information for other specific purposes, subject to your consent.
                                </em>
                                <div>
                                    <p>
                                        We handle your personal information for a range of purposes, which are contingent on your interactions with our Platform. These purposes may encompass:
                                    </p>
                                    <ol className='bulleted'>
                                        <li>To facilitate account creation and authentication, along with the overall management of user accounts. This involves processing your information to enable the creation and login to your account and ensuring its smooth operation.</li>
                                        <li>To respond to user inquiries/offer support to users. We may also process your information to address your inquiries and resolve any potential issues related to the requested service.</li>
                                        <li>To send administrative information to you. We may process your information to provide you with information regarding our services, updates to our terms and policies, and similar communications.</li>
                                        <li>To enable user-to-user communications. We may process your information if you opt to utilise any of our features that enable communication with another user.</li>
                                        <li>To save or protect an individual's vital interest. We may process your information when it becomes necessary to preserve or safeguard an individual's vital interests, particularly to prevent harm.</li>

                                    </ol>
                                </div>
                            </div>
                            <li><span className='font-weight-bold'>What legal basis guides our information processing?</span></li>
                            <div>
                                <em>
                                    In Short: We engage in the processing of your personal information exclusively when we deem it
                                    necessary and possess a valid legal basis to do so under applicable laws. These legal bases may
                                    encompass your consent, compliance with legal requirements, the provision of services, the fulfilment
                                    of contractual obligations, protection of your rights, or the pursuit of our legitimate business interests.
                                </em>
                                <ol className="numbered-indent">
                                    <li>
                                        <span className='font-weight-bold'>For individuals situated in the EU or U.K, this section pertains to you.</span>
                                        <div>
                                            Under the provisions of the General Data Protection Regulation (GDPR) and the UK GDPR, we are obligated to
                                            elucidate the legitimate legal bases upon which we rely to process your personal information. Consequently,
                                            we may rely on the following legal bases for the processing of your personal information:
                                            <ol className='bulleted'>
                                                <li><span className='font-weight-bold'>Consent</span>. We may engage in the processing of your information if you have granted us explicit permission, signifying your consent, to employ your personal information for a precise purpose. Importantly, it is within your prerogative to rescind your consent at any juncture. Explore further details regarding <a href='#withdrawing-your-consent'>withdrawing your consent</a>.</li>
                                                <li><span className='font-weight-bold'>Performance of a Contract</span>. We may undertake the processing of your personal information when we deem it essential to meet our contractual commitments to you, encompassing the provision of our Platform or, at your behest, in anticipation of entering into a contractual agreement with you.</li>
                                                <li><span className='font-weight-bold'>Legal Obligations</span>. We may engage in the processing of your information when we ascertain it to be imperative for adhering to our legal obligations, which may include collaborating with law enforcement entities or regulatory agencies, safeguarding our legal rights through litigation, or disclosing your information as evidentiary material in cases in which we are actively involved.</li>
                                                <li><span className='font-weight-bold'>Vital Interests</span>. We may embark on the processing of your information when we discern it as indispensable to safeguard your vital interests or those of a third party, particularly in situations that entail potential threats to the safety and well-being of any individual.</li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        <span className='font-weight-bold'>For individuals situated in Canada, this section pertains to you.</span>
                                        <div>
                                            We may engage in the processing of your information when you have granted us explicit permission (i.e., express consent) for the utilisation of your personal information for a particular purpose or in scenarios where your consent can be reasonably inferred (i.e., implied consent). Importantly, you retain the prerogative to withdraw your consent at your discretion.
                                            <p className='mt-2'>
                                                In rare and exceptional circumstances, we may be legally authorised under applicable law to engage in the processing of your information without necessitating your explicit consent. This may include situations such as:
                                            </p>
                                            <ol className='bulleted'>
                                                <li>When the collection of information is evidently in the best interests of an individual, and obtaining consent within a reasonable timeframe is unfeasible.</li>
                                                <li>For investigations and fraud detection and prevention.</li>
                                                <li>For business transactions, provided certain conditions are met.</li>
                                                <li>When the information is contained within a witness statement, its collection is imperative for the evaluation, processing, or resolution of an insurance claim.</li>
                                                <li>In situations that involve the identification of injured, ill, or deceased individuals and the necessity to communicate with their next of kin.</li>
                                                <li>When there are reasonable grounds to believe that an individual has been, is currently, or may become a victim of financial abuse.</li>
                                                <li>In cases where it is reasonable to anticipate that obtaining consent for collection and use would undermine the availability or accuracy of the information, such collection is rational in the context of investigating a breach of an agreement or a violation of Canadian federal or provincial laws.</li>
                                                <li>When disclosure is mandated to adhere to a subpoena, warrant, court order, or court rules concerning the production of records.</li>
                                                <li>If the information was generated by an individual during the course of their employment, business, or professional activities, the collection aligns with the original purposes for which the information was produced.</li>
                                                <li>When the collection is exclusively intended for journalistic, artistic, or literary purposes.</li>
                                                <li>If the information is publicly available and is specified by the regulations.</li>
                                            </ol>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <li><span id='when-and-with-whom-we-share-your-personal-information' className='font-weight-bold'>When and with whom is your personal information shared?</span></li>
                            <div>
                                <em>
                                    In Short: We may share information as described in this section and/or with the following third parties.
                                </em>
                                We may need to share your personal information in the following situations:
                                <ul className='bulleted'>
                                    <li><span className='font-weight-bold'>Business Transfers</span>. We may share or transfer your information
                                        as part of negotiations, mergers, asset sales, financing, or acquisitions involving our business and another company.</li>
                                    <li><span className='font-weight-bold'>Other Users</span>. When you share personal information, such as posting comments,
                                        contributions, or other content on the Platform, or engage in interactions within public areas of the Platform, please be
                                        aware that such information may be visible to all users and could be publicly accessible beyond the Platform indefinitely.
                                        If you choose to interact with fellow Platform users or register through a social media network like Facebook, your
                                        social media network contacts may have access to your name, profile photo, and activity descriptions. Likewise, other
                                        users may view your activity descriptions, communicate with you within our Platform, and access your profile.</li>
                                </ul>
                            </div>
                            <li><span className='font-weight-bold'>What is our stance on third-party websites?</span></li>
                            <div>
                                <em>
                                    In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who
                                    advertise on our Platform, but are not affiliated with it.
                                </em>
                                <p className='mt-2'>
                                    The Platform may include links to third-party websites, online services, or mobile applications and may display advertisements from
                                    unrelated third parties that may lead to other websites, services, or applications. Consequently, we do not provide any assurances
                                    regarding these third parties, and we cannot be held responsible for any losses or damages resulting from the use of such third-party
                                    websites, services, or applications. The inclusion of a link to a third-party website, service, or application should not be interpreted
                                    as an endorsement by us. We cannot ensure the safety and privacy of any data you provide to third parties. Data collected by third parties
                                    falls outside the scope of this privacy notice. We bear no responsibility for the content, privacy practices, and security policies of third
                                    parties, including websites, services, or applications that may be linked to or from the Platform. We advise you to review the policies of
                                    these third parties and contact them directly for any inquiries you may have.
                                </p>
                            </div>
                            <li><span id='how-do-we-handle-your-social-logins' className='font-weight-bold'>How do we handle your social logins?</span></li>
                            <div>
                                <em>
                                    In Short: If you opt to register or log in to our Platform via a social media account, we may gain access to specific information pertaining to you.
                                </em>
                                <p className='mt-2'>
                                    Our Platform provides you with the option to register and log in using your third-party social media account credentials, such as your Facebook, Google,
                                    or X (formerly Twitter) logins. If you choose to utilise this feature, we will receive specific profile details about you from your chosen social media
                                    provider. The profile information we receive can vary depending on the particular social media provider but typically includes your name, email address,
                                    friends list, profile picture, and any other information you have chosen to make publicly available on the respective social media platform.
                                </p>
                                <p className='mt-2'>
                                    The information we receive will be employed solely for the purposes outlined in this privacy notice or those explicitly communicated to you on the respective
                                    Platform. It's important to note that we lack control over and cannot be held responsible for any additional uses of your personal information by your chosen
                                    third-party social media provider. We strongly advise you to peruse their privacy notice to gain insights into their practices concerning the collection,
                                    utilisation, and sharing of your personal information, as well as to learn how to configure your privacy preferences on their websites and applications.
                                </p>
                            </div>
                            <li><span className='font-weight-bold'>How long do we keep your information?</span></li>
                            <div>
                                <em>
                                    In Short: We retain your information for the duration necessary to fulfil the purposes elucidated in this privacy notice, unless legal obligations dictate otherwise.
                                </em>
                                <p className='mt-2'>
                                    We will exclusively retain your personal information only for the duration it is necessary for the purposes specified in this privacy notice, unless extended retention
                                    is required or authorised by law, including but not limited to tax, accounting, or other legal obligations. Importantly, no aspect of this notice obligates us to retain
                                    your personal information beyond a period of twelve (12) months following the termination of the user's account.
                                </p>
                                <p className='mt-2'>
                                    When there is no ongoing legitimate business necessity to process your personal information, we will take one of the following actions: either delete or anonymize the
                                    information, or, if this is unfeasible (e.g., due to the storage of your personal information in backup archives), we will securely retain your personal information,
                                    isolating it from any additional processing until such time that deletion becomes feasible.
                                </p>
                            </div>
                            <li><span id='how-we-keep-your-information-safe' className='font-weight-bold'>How do we keep your information safe?</span></li>
                            <div>
                                <em>
                                    In Short: Our objective is to safeguard your personal information by implementing a robust framework of organisational and technical security measures. Your security is our priority.
                                </em>
                                <p className='mt-2'>
                                    Safeguarding your personal information is not just a priority; it's our unwavering commitment. We have gone to great lengths to ensure that your data remains confidential and secure
                                    through a combination of advanced technical measures and secure cloud hosting.
                                </p>
                                <div className='mt-2'>
                                    Our Commitment to Security encompasses:
                                    <ol className='bulleted'>
                                        <li><span className='font-weight-bold'>Fortified Cloud Hosting:</span></li>
                                        <span>
                                            CoopConnect's platform is hosted on a secure cloud infrastructure that employs industry-leading security protocols and practices. This fortified environment is designed to resist
                                            and repel even the most sophisticated cyber threats.
                                        </span>
                                        <li><span className='font-weight-bold'>Data Encryption Over HTTPS:</span></li>
                                        <span>
                                            All data transmitted to and from our platform is protected by state-of-the-art encryption technology. We utilise HTTPS, the gold standard for secure data transfer over the internet,
                                            ensuring that your information remains confidential and tamper-proof during transmission.
                                        </span>
                                        <li><span className='font-weight-bold'>Cloud Database Security:</span></li>
                                        <span>
                                            Your data is stored in a secure cloud database environment, further enhancing its protection. Our cloud infrastructure employs stringent security measures to safeguard your data at
                                            rest, making it highly resistant to unauthorised access.
                                        </span>
                                        <li><span className='font-weight-bold'>Continuous Vigilance:</span></li>
                                        <span>
                                            Our security team conducts regular audits and continuous monitoring of our systems. This proactive approach allows us to identify and address potential vulnerabilities or threats swiftly,
                                            ensuring that our security measures remain robust and up-to-date.
                                        </span>
                                        <li><span className='font-weight-bold'>Compliance:</span></li>
                                        <span>
                                            CoopConnect is fully compliant with industry and data protection regulations, including Uganda’s Data Protection & Protection Act, GDPR and HIPAA, where applicable. We are dedicated to
                                            upholding the utmost standards of security and privacy.
                                        </span>
                                    </ol>
                                </div>
                                <ol className='numbered-indent'>
                                    <li>
                                        <span className='font-weight-bold'>No Guarantee is Absolute</span>
                                        <div>
                                            While our security measures are designed to provide the highest level of protection, it's essential to acknowledge that no security system can be entirely foolproof. However,
                                            by hosting our platform securely on the cloud and implementing advanced encryption, we have significantly minimised potential risks.
                                        </div>
                                    </li>
                                    <li>
                                        <span className='font-weight-bold'>Your Responsibility</span>
                                        <div>
                                            We encourage you to take an active role in your online security. Use strong, unique passwords for your CoopConnect account and keep them confidential. Keep your devices and software
                                            up-to-date to benefit from the latest security enhancements. Exercise caution when sharing personal information online and access CoopConnect only from a secure environment.
                                        </div>
                                    </li>
                                    <li>
                                        <span className='font-weight-bold'>Reporting Security Concerns</span>
                                        <div>
                                            Should you ever suspect any unauthorised activity or have security concerns regarding your data on CoopConnect, please notify us immediately at <a href='mailto:info@coopconnect.org'>
                                                info@coopconnect.org</a>. Our dedicated I.T team is ready to investigate and address any potential security incidents swiftly and decisively.
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <li><span className='font-weight-bold'>Do we collect information from minors?</span></li>
                            <div>
                                <em>
                                    In Short: We do not knowingly gather information from or promote our services to individuals under 18 years of age.
                                </em>
                                <p className='mt-2'>
                                    We do not intentionally request information from or promote our services to individuals under the age of 18. When using the Platform, you confirm that you are at least 18 years old or that
                                    you are the legal parent or guardian of a minor using the Platform, and you provide consent for the minor's use of the Platform. In the event that we become aware of the collection of personal
                                    information from users under the age of 18, we will deactivate the account and take appropriate steps to promptly remove such data from our records. If you have knowledge of any data we may have
                                    collected from individuals under the age of 18, please contact us at <a href='mailto:info@coopconnect.org'>info@coopconnect.coop</a>
                                </p>
                            </div>
                            <li><span id='your-privacy-rights' className='font-weight-bold'> What are your privacy rights?</span></li>
                            <div>
                                <em>
                                    In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal
                                    information. You may review, change, or terminate your account at any time.
                                </em>
                                <div className='mt-2'>
                                    In specific regions, such as the EEA, UK, Switzerland, and Canada, you possess certain rights conferred by relevant data protection laws. These may include the right;
                                    <ol className='numbered'>
                                        <li>To initiate a request for access and secure a copy of your personal information.</li>
                                        <li>To request rectification or erasure.</li>
                                        <li>To request the restriction of processing for your personal information</li>
                                        <li>If applicable, to data portability; and</li>
                                        <li>Not to be subject to automated decision-making. </li>
                                    </ol>
                                </div>
                                <p className='mt-2'>
                                    Under specific circumstances, you may also hold the right to object to the processing of your personal information. You can submit such a request by contacting us through the contact details outlined
                                    in the section titled <a href='contact-us-about-this-notice'>How can you contact us about this notice?</a> below.
                                </p>
                                <p className='mt-2'>
                                    We will assess and respond to all requests in compliance with the pertinent data protection laws.
                                </p>
                                <p className='mt-2'>
                                    If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or
                                    UK data protection authority.
                                </p>
                                <p className='mt-2'>
                                    If you are situated in Switzerland, you have the option to contact the Federal Data Protection and Information Commissioner for assistance.
                                </p>
                                <ol className='numbered-indent'>
                                    <li><span id='withdrawing-your-consent' className='font-weight-bold'>Withdrawing your consent</span></li>
                                    <div>
                                        If we are dependent on your consent to process your personal information, whether it is express or implied consent in accordance with the applicable law, you maintain the right to retract
                                        your consent at any moment. To do so, you can contact us using the contact information provided in the section labelled <a href='#contact-us-about-this-notice'>How can you contact us about this notice?</a> below or by updating your preferences.
                                        Nevertheless, it's important to acknowledge that withdrawing your consent will not impact the legality of the processing that occurred prior to the withdrawal. Additionally, where permitted
                                        by applicable law, it will not affect the processing of your personal information conducted under lawful processing based on anything other than consent.
                                    </div>
                                    <li><span className='font-weight-bold'>Opting out of marketing and promotional communications</span></li>
                                    <div>
                                        You have the choice to opt out of our marketing and promotional communications at any time. This can be done by clicking on the unsubscribe link within the emails we send or by reaching out to us using the
                                        contact information provided in the section titled <a href='#contact-us-about-this-notice'>How can you contact us about this notice?</a> below. Upon unsubscribing, you will be promptly removed from our marketing lists. It's important to note, however,
                                        that we may continue to communicate with you for essential service-related matters, such as administering your account, responding to service requests, or other non-marketing purposes.
                                    </div>
                                    <li><span className='font-weight-bold'>Account Information</span></li>
                                    <div>
                                        If, at any point, you wish to review or modify the information within your account or deactivate your account, you can:
                                        <ul className='bulleted'>
                                            <li>Log in to your account settings and update your user account.</li>
                                            <li>Contact us using the contact information provided.</li>
                                        </ul>
                                        <p className='mt-2'>
                                            Upon receiving your request to terminate your account, we will proceed to deactivate or delete your account and the associated information from our active databases. It's important to note that we may retain
                                            certain information in our records for purposes such as fraud prevention, troubleshooting issues, aiding in investigations, enforcing our legal terms, and adhering to relevant legal obligations.
                                        </p>
                                        <p className='mt-2'>
                                            If you have questions or comments about your privacy rights, you may email us at <a href='mailto:uhuru@uhuruinstitute.org'>uhuru@uhuruinstitute.org</a>
                                        </p>
                                    </div>
                                </ol>
                            </div>
                            <li><span className='font-weight-bold'>Controls for DO-NOT-TRACK features</span></li>
                            <div>
                                Many web browsers, as well as some mobile operating systems and mobile applications, incorporate a "Do-Not-Track" (DNT) feature or setting. Activating this feature signals your privacy preference, indicating your
                                desire not to have your data about your online browsing activities monitored and collected. It's crucial to emphasise that, at present, there exists no globally recognised technology standard for the identification
                                and execution of DNT signals.
                                At present, we do not acknowledge or act upon DNT browser signals or any other automated mechanism that conveys your choice to abstain from online tracking. In the event that a standard for online tracking is established
                                that we must adhere to in the future, we will apprise you of this practice in an updated version of this privacy notice.
                            </div>
                            <li><span className='font-weight-bold'> Do California residents have specific privacy rights?</span></li>
                            <div>
                                <em>
                                    In Short: If you reside in California, you have specific rights pertaining to the access of your personal information.
                                </em>
                                <p className='mt-2'>
                                    California Civil Code Section 1798.83, commonly referred to as the 'Shine The Light' law, grants California resident users the right to request and receive, free of charge and once per year, details about the categories
                                    of personal information (if any) we have disclosed to third parties for direct marketing purposes, as well as the names and addresses of all third parties with whom we have shared personal information in the preceding
                                    calendar year. If you are a California resident and wish to make such a request, please send a written request to the contact information provided below.
                                </p>
                                <p className='mt-2'>
                                    If you are a California resident under the age of 18 and have an active registered account on our Platform, you hold the privilege to request the removal of any unwanted data that you have publicly posted on the Platform.
                                    To initiate this data removal request, please reach out to us using the contact details provided below. In your communication, include the email address linked to your account and confirm that you reside in California.
                                    We will take measures to ensure that the data is no longer publicly visible on the Platform. However, it's important to note that the data may not be entirely or comprehensively eradicated from all our systems, such as backups, among others.
                                </p>
                            </div>
                            <li><span className='font-weight-bold'> Do Uganda residents have specific privacy rights?</span></li>
                            <div>
                                <em>
                                    In Short: If you are a resident of Uganda, you have certain rights related to the protection and access to your personal information, in accordance with Ugandan data protection laws.
                                </em>
                                <p className='mt-2'>
                                    Uganda has established data protection laws that safeguard your privacy and personal information. You have the right to request access to the personal information we have collected about you. To do so, please contact us using the contact information
                                    here <a href='#contact-us-about-this-notice'>How can you contact us about this notice?</a>. We may require you to verify your identity before providing access to your personal information. If you believe that the personal information we have on record about you is inaccurate, incomplete,
                                    or out-of-date, you possess the right to request corrections. Please reach out to us using the contact details provided below to initiate the correction process. Under certain circumstances and in compliance with Ugandan data protection laws,
                                    you may request the deletion of your personal information. It is essential to understand that while we will make reasonable efforts to fulfil your requests for access, correction, or deletion of your personal information, some data may be retained
                                    in accordance with Ugandan laws and regulations, including those necessary for legal or legitimate business purposes.
                                </p>
                            </div>
                            <li><span className='font-weight-bold'>Do we make updates to this notice?</span></li>
                            <div>
                                <em>
                                    In Short: Yes, we will revise this notice as needed to remain in accordance with applicable laws.
                                </em>
                                <p className='mt-2'>
                                    We may periodically update this privacy notice. The updated version will be identified by a new 'Revised' date and will become effective as soon as it is made available. In instances of significant alterations to this privacy notice, we may notify
                                    you through prominent notices of such changes or via direct notifications. We strongly encourage you to regularly review this privacy notice to stay informed about how we safeguard your information.
                                </p>
                            </div>
                            <li><span id='contact-us-about-this-notice' className='font-weight-bold'>How can you contact us about this notice?</span></li>
                            <div>
                                If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), Bekar Ismail, by email at <a href='mailto:bekar.ismail@uhuruinstitute.org'>bekar.ismail@uhuruinstitute.org</a>, by phone at <a href='tel:+256781946116'>+256781946116</a>, or by Post at:<br />
                                <blockquote className='mt-2 text-dark blockquote'>
                                    The Uhuru Institute for Social Development<br />
                                    Plot 1, Katalima Crescent,<br />
                                    Kampala, Central,<br />
                                    Uganda.
                                </blockquote>
                            </div>
                            <li><span className='font-weight-bold'>How can you assess, modify, or remove the data we have gathered from you?</span></li>
                            <div>
                                If you wish to review, update, or delete the data we have collected from you, and depending on the applicable laws in your country, you may have the right to request access to your personal information, modify it, or have it removed. To initiate a request for reviewing, updating, or deleting your
                                personal information, please visit: <a href='https://coopconnect.coop/profile'>https://coopconnect.coop/profile</a>.
                            </div>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    </>
)

export default PrivacyPolicy
