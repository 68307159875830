import React from "react"
import useFriendsMutations from "../async-manager/mutations/friends"
import Image from "../layouts/Image"
import Name from "../layouts/Name"

const User = ({ user, status, id, isSelf }) => {
    const { first_name, last_name, image } = user
    const name = `${first_name} ${last_name}`

    const {
        onHandleAcceptFriend, acceptFriendLoading,
        onHandleUnFriend, unFriendLoading,
        onHandleAddFriend, addFriendLoading
    } = useFriendsMutations()

    return (
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 px-2">
            <div className="company_profile_info card">
                <div className="card">
                    <Image className='card-img-top border-bottom user-card-image' image={image} />
                    <div className="card-body pb-2 pt-2">
                        <h5 className="mb-1"><Name author_id={id} name={name} /></h5>
                        <p className="card-text">No mutual friends</p>
                        <div className="d-flex flex-column">
                            {isSelf && status === 'may-know' &&
                                <button
                                    type="button"
                                    onClick={() => onHandleAddFriend(id)}
                                    disabled={addFriendLoading}
                                    className="btn btn-primary btn-sm">
                                    <i className="la la-plus"></i> Add Friend
                                </button>
                            }
                            {isSelf && status === 'received' && (<>
                                <button
                                    type="button"
                                    onClick={() => onHandleAcceptFriend(id)}
                                    disabled={acceptFriendLoading}
                                    className="btn btn-secondary btn-sm mt-1">
                                    <i className="las la-handshake"></i> Confirm
                                </button>
                                <button
                                    type="button"
                                    onClick={() => onHandleUnFriend(id)}
                                    disabled={unFriendLoading}
                                    className="btn btn-info btn-sm mt-1">
                                    <i className="lar la-trash-alt"></i> Delete
                                </button>
                            </>)}
                            {isSelf && status === 'sent' &&
                                <button
                                    type="button"
                                    onClick={() => onHandleUnFriend(id)}
                                    disabled={unFriendLoading}
                                    className="btn btn-danger btn-sm mt-1">
                                    <i className="las la-times"></i> Cancel Request
                                </button>
                            }
                            {isSelf && status === 'friends' && (<>
                                <button
                                    type="button"
                                    onClick={() => onHandleUnFriend(id)}
                                    disabled={unFriendLoading}
                                    className="btn btn-danger btn-sm mt-1"
                                >
                                    <i className="las la-times"></i> Unfriend
                                </button>
                                <button type="button" className="btn btn-info btn-sm mt-1">
                                    <i className="lar la-envelope"></i> Message
                                </button>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default User
