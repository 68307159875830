import ForumRightSide from "./ForumRightSide"
import ForumMainPage from "./ForumMainPage"

const ForumPage = props => (
    <section className="forum-page">
        <div className="container">
            <div className="forum-questions-sec">
                <div className="row">
                    <div className="col-lg-8">
                        <ForumMainPage />
                    </div>
                    <ForumRightSide />
                </div>
            </div>
        </div>
    </section>
)

export default ForumPage
