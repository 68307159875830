import React, { Fragment } from "react"
import { connect } from "react-redux"
import { Link } from 'react-router-dom'

import 'slick-carousel/slick/slick'

const VideosPage = props => {
    const { id } = props || {}

    return (
        <Fragment>
            <div className="col-lg-9 col-md-8 no-pd profile-main">
                <div className="main-ws-sec">
                    <div className="product-feed-tab" id="my-bids">
                        <ul className="nav nav-tabs bid-tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}`}>Posts</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/about`}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/friends`}>Friends</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/photos`}>Photos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to={`/profile/${id}/videos`}>Videos</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="post-topbar">
                        <div className="d-flex justify-content-center">
                            <p className="h3">Videos Page</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ({ posts, authedUser, users }, { id }) => {
    const user = users[authedUser]
    const x = posts?.data
    const metadata = posts?.metadata

    return {
        posts,
        user, id,
        metadata,
        postIds: x && Object.keys(x)
            .filter(l => x[l].author === id)
            .sort((a, b) => x[b].timestamp - x[a].timestamp)
    }
}

export default connect(mapStateToProps)(VideosPage)
