import React from 'react'
import { decode } from 'html-entities'

const RecentNews = ({ data }) => {
    const {
        title: { rendered: title },
        jetpack_featured_media_url: img, link
    } = data

    return (
        <div className="job-info">
            <div className="d-flex flex-row segment-details">
                <div className="w-50 mr-2">
                    <img src={img ?? '/no-image.png'} className="rounded mx-auto d-block img-fluid float-none news-img" alt="" />
                </div>
                <div className='d-flex flex-column w-50 justify-content-center'>
                    <h3>{decode(title)}</h3>
                    <p className=''>
                        <a href={link} target='_blank' rel="noreferrer" className="text-danger">Read More...</a>
                    </p>
                </div>
            </div>
            {/* <div className="d-flex">
                <i className="fa fa-caret-right text-danger mr-1" aria-hidden="true"></i>
                <div className="segment-details flex-column">
                    <h3>{title}</h3>
                    <div className="text-center">
                        <img src={img ?? '/no-image.png'} className="rounded mx-auto d-block img-fluid float-none" alt="" />
                    </div>
                    <p>{excerpt}</p>
                    <p>
                        <i className="fa fa-clock-o mr-1" aria-hidden="true"></i>
                        {date}
                        <a href={link} target='_blank' rel="noreferrer" className="float-right text-danger">Read More...</a>
                    </p>
                </div>
            </div> */}
        </div>
    )
}

export default RecentNews
