import moment from 'moment'

export const formatDate = timestamp => {
    // const d = new Date(timestamp)

    // const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // const time = d.toLocaleTimeString('en-US', { timeZone })
    // return `${time.substr(0, 5)} ${time.slice(-2)} | ${d.toLocaleDateString('en-US', options)}`
    // return moment(timestamp).calendar()
    const hours = moment().diff(moment(timestamp), 'hours')

    if (hours >= 2) {
        return moment(timestamp).calendar(null, {
            sameDay: '[Today] [at] LT',
            lastDay: '[Yesterday]  [at] LT',
            lastWeek: '[Last] dddd [at] LT',
            sameElse: 'Do MMMM, YYYY'
        })
    }
    return moment(timestamp).fromNow()
}

export const formatAnswer = (answer, authedUser) => {
    const { id, likes, text, timestamp, author, comments } = answer
    const { first_name, last_name, avatar, image } = author || {}
    const commentIds = Object.keys(comments)

    return {
        name: `${first_name} ${last_name}`,
        id: id, commentIds,
        timestamp,
        text, comments,
        avatar: avatar,
        image: image,
        likes: likes.length,
        hasLiked: likes.includes(authedUser),
    }
}

export const formatComment = (comment, authedUser) => {
    const { _id, likes, text, createdAt, author } = comment
    const { first_name, last_name, avatar, image } = author || {}

    return {
        name: `${first_name} ${last_name}`,
        id: _id, text,
        timestamp: createdAt,
        avatar: avatar,
        image: image,
        likes: likes.length,
        hasLiked: likes.includes(authedUser),
    }
}

export const formatForum = (forum, authedUser) => {
    const {
        id, votes, answers, text, timestamp, photo,
        tags, title, description, author, category: categoryDetails
    } = forum

    const { name: category } = categoryDetails

    return {
        description,
        tags, image: author?.image,
        id, title,
        timestamp,
        text, photo,
        votes: votes,
        answers: answers,
        category,
        hasVoted: votes.includes(authedUser),
    }
}

export const formatPost = (post, authedUser) => {
    const {
        id, likes, text, timestamp,
        photo, author, comments
    } = post
    const { first_name, last_name, image, id: author_id } = author || {}

    return {
        name: `${first_name} ${last_name}`,
        id, comments, author_id,
        timestamp, text, photo,
        image: image,
        likes: likes?.length,
        commentsLength: Object.keys(comments)?.length,
        hasLiked: likes?.includes(authedUser),
    }
}

export const readFileAsDataURL = file =>
    new Promise(resolve => {
        const reader = new FileReader()

        reader.onload = event => {
            resolve(event.target.result)
        }

        reader.readAsDataURL(file)
    })
