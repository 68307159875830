import { useForm } from 'react-hook-form'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import $ from 'jquery'
import { useState } from 'react'
import MultiSelect from '../../layouts/MultiSelect'
import Select from '../../layouts/Select'
import { useAddForumQuestion, useForumCategories } from '../../async-manager/queries'

const QuestionModal = props => {
    const [askError, setAskError] = useState(null)

    const {
        register, reset,
        handleSubmit, control,
        formState: { errors, isSubmitted, isValid }
    } = useForm({ mode: "onChange" })

    const {
        data: categories_data,
    } = useForumCategories()

    const categories = categories_data && categories_data.map(category => ({
        value: category.id,
        label: category.name
    }))

    const { mutate } = useAddForumQuestion()

    const onAsk = data => {
        mutate(data, {
            onSuccess: () => {
                reset()
                $('#questionModal').modal('hide')
            },
            onError: error => setAskError(error),
        })
        reset()
    }

    return (
        <div>
            <div id="questionModal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="gridModalLabel">Ask a Question?</h5>
                            <button type="button" className="close" style={{ outline: "none" }} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit(onAsk)}>
                            {askError && (<p className='mb-2 text-danger text-center'>{askError}</p>)}
                            <div className="modal-body">
                                <div className="question-fields">
                                    <div className="">
                                        {errors.title && <p className='text-danger'>{errors.title.message}</p>}
                                        <div className="form-group">
                                            <input
                                                className='border form-control shadow-none rounded'
                                                name="title"
                                                {...register('title', {
                                                    required: 'Title is required'
                                                })}
                                                placeholder="Question Title"
                                            />
                                        </div>
                                        <div className="form-group">
                                            {errors.category && <p className='text-danger'>{errors.category.message}</p>}
                                            <Select
                                                name="category_id"
                                                control={control}
                                                options={categories} />
                                        </div>
                                        {errors.tags && <p className='text-danger'>{errors.tags.message}</p>}
                                        <div className="form-group">
                                            <MultiSelect
                                                name={"tags"}
                                                control={control}
                                            />
                                        </div>
                                        {errors.description && <p className='text-danger'>{errors.description.message}</p>}
                                        <div className="sn-field">
                                            <textarea
                                                name="description"
                                                {...register('description', {
                                                    required: 'Add a description'
                                                })}
                                                placeholder="Add a description to the question" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ border: 0 }}>
                                <button
                                    disabled={(isSubmitted && !askError) || !isValid}
                                    className="modal-button"
                                    type="submit"
                                >
                                    Ask
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default QuestionModal
