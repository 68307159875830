import { memo } from "react"
import { useToggleLikePostComment } from "./queries"

const withLikeComment = (WrappedComponent) => {
    const WrappedComponentMemo = memo(WrappedComponent)
    return props => {
        const { page, queryKey } = props
        const toggleLikeComment = useToggleLikePostComment(queryKey, page)

        return (
            <WrappedComponentMemo
                {...props}
                onHandleLikeComment={data => toggleLikeComment.mutate(data)}
            />
        )
    }
}

export default withLikeComment
