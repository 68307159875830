import { createContext, useMemo, useState } from "react"
import { useProducts } from "../../async-manager/queries"

export const SokoniContext = createContext()

export const SokoniProvider = props => {
    const [price_range, setPriceRange] = useState()
    const [category_id, setCategoryId] = useState()
    const [page, setPage] = useState(1)

    const {
        isLoading: loading, error, data, refetch,
        isPreviousData, isFetching: loadingPage
    } = useProducts({ page, price_range, category_id })

    const { products, headers } = data || {}

    const pageCount = headers ? +headers['x-wp-totalpages'] : 0

    const initialContext = useMemo(() => ({
        pageCount, products, loading, loadingPage, error,
        onReload: () => refetch(),
        onHandleChange: price_range => setPriceRange(price_range),
        onHandleReset: () => setPriceRange(),
        handleCategory: category_id => setCategoryId(category_id),
        handlePageClick: ({ selected }) => !isPreviousData && setPage(+selected + 1),
    }), [
        refetch, setPriceRange, setCategoryId, setPage,
        isPreviousData, pageCount, products, loading, loadingPage, error
    ])

    return (
        <div className="companies-info">
            <div className="container">
                <div className=" row companies-list">
                    <SokoniContext.Provider value={initialContext}>
                        {props.children}
                    </SokoniContext.Provider>
                </div>
            </div>
        </div>
    )
}
