import React from "react"
import ChatBox from "./ChatBox"
import FriendBox from "./friends/FriendBox"
import MessagesBox from "./MessageBox"
import { MessageProvider } from "./MessageProvider"

const Messages = () => {
    return (
        <main>
            <section className="messages-page">
                <div className="container">
                    <div className="messages-sec">
                        <div className="row">
                            <MessageProvider>
                                <MessagesBox />
                                <ChatBox />
                                <FriendBox />
                            </MessageProvider>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Messages
