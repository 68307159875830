import { handleGetOut } from "../actions/shared"
const jwt = require("jsonwebtoken")

function checkToken(token) {
    if (!token) return 'missing'
    let decodedToken = jwt.decode(token)
    let currentDate = new Date()

    return decodedToken.exp * 1000 < currentDate.getTime() ? 'expired' : 'valid'
}

const checker = store => next => action => {
    const returnValue = next(action)
    const status = checkToken(store.getState().token)
    if (status === 'missing') {
    } else if (status === 'expired') {
        store.dispatch(handleGetOut())
    }
    return returnValue
}

export default checker
