import ProfileContainer from "./ProfileContainer"
import AboutPage from "./AboutPage"

const About = props => {
    const { id } = props.match.params

    return (
        <ProfileContainer id={id}>
            <AboutPage id={id} />
            {/* <RightProfileSideBar /> */}
        </ProfileContainer>
    )
}

export default About
