import React from "react"
import { ThreeDots } from 'react-loader-spinner'

const ProgressDots = ({ height, width }) =>
    <div className="d-flex justify-content-center mt-1 w-100">
        <ThreeDots
            height={height || 80}
            width={width || 80}
            radius="9"
            color="#8e0b56"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
    </div>

export default ProgressDots
