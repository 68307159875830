import React from "react"
import Selecter from "react-select"
import { Controller } from "react-hook-form"

const Select = ({ name, options, control, width }) => {
    const customStyles = {
        control: (provided, { selectProps: { width } }) => ({
            ...provided,
            border: '1px solid #ced4da',
            borderRightRadius: '0.25rem',
            boxShadow: 'none',
            width: width || null,
            '&:hover': {
                borderColor: '#ced4da',
                boxShadow: 'none',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? '#007bff' : 'white',
            '&:hover': {
                backgroundColor: '#007bff',
                color: 'white',
            },
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }

    const holder = name.replace("_id", "")

    return (
        <div className="react-select form-control p-0">
            <Controller
                name={name}
                control={control}
                defaultValue={null}
                rules={{ required: `Choose atleast a ${name}` }}
                render={({ field: { value, onChange, onBlur } }) => {
                    return (
                        <Selecter
                            className="d-flex column"
                            options={options}
                            width='100%'
                            placeholder={`Choose a ${holder}`}
                            onBlur={onBlur}
                            isClearable
                            styles={customStyles}
                            value={value && options.find(c => c.value === value)}
                            onChange={val => onChange(val?.value)}
                        />
                    )
                }}
            />
        </div>
    )
}

export default Select
