import React from "react"
import Creatable from 'react-select/creatable'
import { Controller } from "react-hook-form"

const MultiSelect = ({ label, name, options = [], control }) => {

    return (
        <div>
            <Controller
                name={name}
                control={control}
                defaultValue={null}
                rules={{ required: `Add atleast 1 ${name}` }}
                render={({ field: { value, onChange, onBlur } }) => {
                    return (
                        <Creatable
                            placeholder={`Add atleast 1 ${name} i.e. press enter to add tag after typing`}
                            isMulti={true}
                            onChange={options => onChange(options?.map(option => option.value))}
                            onBlur={onBlur}
                            value={value && options.find(c => c.value === value)}
                        />
                    )
                }}
            />
        </div>
    )
}

export default MultiSelect
