import React from "react"
import LeftSideBarNews from "./LeftSideBarNews"
import NewsMainPage from "./NewsMainPage"

const News = props => {

    return (
        <div className="companies-info">
            <div className="container">
                <div className="row companies-list mt-2">
                    <LeftSideBarNews />
                    <NewsMainPage />
                </div>
            </div>
        </div >
    )
}

export default News
