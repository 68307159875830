import React from 'react'
import { Link } from 'react-router-dom'
import Linkify from 'linkifyjs/react'
import ShowMoreText from 'react-show-more-text'

import { formatComment, formatDate } from '../utils/helpers'
import Image from '../layouts/Image'
import { loadState } from '../utils/storage'

const Comments = ({ comment: comm, handleLikeComment, answerId }) => {

    const authedUser = loadState('id')
    const comment = comm ? formatComment(comm, authedUser) : null

    const {
        name, timestamp, image,
        text, hasLiked, likes, id
    } = comment

    /* const handleLikeAnswerComment = () => {
        dispatch(handleToggleLikeAnswerComment({
            id: id,
            question_id,
            hasLiked: hasLiked,
            authedUser
        }))
    } */

    const options = {
        target: {
            url: '_blank'
        }
    }

    return (
        <div className="mt-2 comment-sec">
            <div className="d-flex">
                <div className="cm_img">
                    <Image image={image} />
                </div>
                <div className="card card-header  w-100 comment-list">
                    <div className="comment">
                        <h3>{name}</h3>
                        <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            width={0}
                            truncatedEndingComponent={"... "}
                        ><Linkify tagName="p" options={options}>{text}</Linkify>
                        </ShowMoreText>
                    </div>
                </div>
            </div>
            <span className="float-right comment-span m-1"><img src="images/clock.png" alt="" />{formatDate(timestamp)}</span>
            <div className='float-left ml-5 mt-2'>
                <Link to="#"
                    onClick={() => handleLikeComment({ id, hasLiked, authedUser, answerId })}
                    className={`comment-span ${hasLiked ? 'active' : ''}`}>
                    <i className="fas fa-heart"></i> Like
                </Link>
                <span className="ml-2">{likes !== 0 && likes}</span>
            </div>
        </div>
    )
}

export default Comments
