import ProfileContainer from "./ProfileContainer"
import PhotosPage from "./PhotosPage"

const Photos = props => {
    const { id } = props.match.params

    return (
        <ProfileContainer id={id}>
            <PhotosPage id={id} />
        </ProfileContainer>
    )
}

export default Photos
