import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom"
import { connect } from "react-redux"
// import io from "socket.io-client"
import Settings from "./Settings"

import Login from "./Login"
import Register from "./Register"
import Home from "./Home"
import { setToken } from "../actions/token"
import { setAuthedUser } from "../actions/authedUser"
import { handleInitialData } from "../actions/shared"
import NavBar from "./NavBar"
import SettingsPage from "./SettingsPage"
import Profile from "./Profile"
import Messages from "./Messages"
import Sokoni from "./sokoni/Sokoni"
import Fora from "./forum/Fora"
import Question from "./forum/Question"
import ScrollToTop from "./ScrollToTop"
import About from "./About"
import Friends from "./friends/Friends"
import Photos from "./Photos"
import Videos from "./Videos"
import MayKnow from "./friends/MayKnow"
import Requests from "./friends/Requests"
import Sent from "./friends/Sent"
import News from "./news/News"
import { loadState } from "../utils/storage"
import PrivacyPolicy from "./Privacy"
import TermsOfUse from "./TermsOfUse"

const App = props => {
    const { dispatch, tokenized, lid } = props

    if (tokenized !== null) {
        dispatch(setToken(tokenized))
        dispatch(setAuthedUser(lid))
    }

    useEffect(() => {
        if (tokenized !== null) {
            dispatch(handleInitialData())
                .catch(e => {
                })
        }
    }, [tokenized, dispatch])

    const DefaultRoutes = () => {
        const history = useHistory()

        useEffect(() => {
            if (tokenized === null) {
                history.push('/login')
            }
        }, [history])
        return (
            <div className="wrapper">
                <NavBar />
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/settings' exact component={Settings} />
                    <Route path='/messages' exact component={Messages} />
                    <Route path='/forum' exact component={Fora} />
                    <Route path='/settingsNew' exact component={SettingsPage} />
                    <Route path='/profile/:id' exact component={Profile} />
                    <Route path='/profile/:id/about' exact component={About} />
                    <Route path='/profile/:id/friends' exact component={Friends} />
                    <Route path='/profile/:id/friends/may-know' exact component={MayKnow} />
                    <Route path='/profile/:id/friends/requests' exact component={Requests} />
                    <Route path='/profile/:id/friends/sent' exact component={Sent} />
                    <Route path='/profile/:id/photos' exact component={Photos} />
                    <Route path='/profile/:id/videos' exact component={Videos} />
                    <Route path='/question/:id' exact component={Question} />
                    <Route path='/news' exact component={News} />
                    <ScrollToTop>
                        <Switch>
                            <Route path='/marketplace' exact component={Sokoni} />
                        </Switch>
                    </ScrollToTop>
                </Switch>
            </div>
        )
    }

    return (
        <Router>
            <Switch>
                <Route path='/login' exact component={Login} />
                <Route path='/privacy-policy' exact component={PrivacyPolicy} />
                <Route path='/terms-of-use' exact component={TermsOfUse} />
                <Route path='/register' exact component={Register} />
                <Route component={DefaultRoutes} />
            </Switch>
        </Router>
    )
}

const mapStateToProps = ({ authedUser, users, token }) => {
    const authed = users[authedUser]
    const lid = loadState('id') || null
    const tokenized = loadState('token') || null

    return {
        tokenized: token || tokenized || null,
        authedUser: authed || lid || null,
        user: users[authedUser],
        lid
    }
}

export default connect(mapStateToProps)(App)
