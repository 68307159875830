import { memo } from "react"
import { useAddPost } from "./queries"

const withAddPost = (WrappedComponent) => {
    const WrappedComponentMemo = memo(WrappedComponent)
    return props => {
        const { queryKey } = props
        const {
            mutate: addPost, isLoading: addPostLoading,
            error: addPostError, isSuccess: addPostSuccess
        } = useAddPost(queryKey)

        return (
            <WrappedComponentMemo
                {...props}
                onHandleAddPost={postData => addPost(postData)}
                addPostSuccess={addPostSuccess}
                addPostError={addPostError}
                addPostLoading={addPostLoading}
            />
        )
    }
}

export default withAddPost
