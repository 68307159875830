import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import $ from 'jquery'
import { useEffect, useState } from 'react'
import withAddPost from '../async-manager/withAddPost'

const PostModal = props => {
    const {
        photoUrl, error, updatePhoto, uploadPhoto,
        onHandleAddPost, addPostSuccess, addPostError
    } = props

    const [postError, setPostError] = useState(null)

    $('#postModal').on('hidden.bs.modal', function () {
        $(this).find('form').trigger('reset')
        updatePhoto('')
        reset()
    })

    const {
        register, setValue,
        handleSubmit, reset,
        formState: { errors, isSubmitted, isValid },
    } = useForm({ mode: "onChange" })

    useEffect(() => {
        setValue('photo', photoUrl)
    }, [photoUrl, setValue])

    const removePhoto = () => {
        setPostError('')
        updatePhoto('')
    }

    useEffect(() => {
        if (addPostSuccess) {
            $('#postModal').modal('hide')
            reset()
        }
        if (addPostError) setPostError(addPostError)
    }, [addPostSuccess, reset, addPostError])

    return (
        <div>
            <div id="postModal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="gridModalLabel">Create a Post</h5>
                            <button type="button" className="close" style={{ outline: "none" }} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit(onHandleAddPost)} >
                            {postError && (<p className='mb-2 text-danger text-center'>{postError}</p>)}
                            <div className="modal-body">
                                <div className="post-project-fields">
                                    {errors.text && <p className='text-danger'>{errors.text.message}</p>}
                                    <div className="mf-field d-block">
                                        <textarea
                                            disabled={isSubmitted && !addPostError}
                                            name="text"
                                            {...register('text', {
                                                required: true
                                            })}
                                            placeholder="What do you want to talk about?" />
                                    </div>
                                    {error && <p className='mb-2 text-danger text-center'>{error}</p>}
                                    {photoUrl &&
                                        (<>
                                            <div className="overflow-auto image-view">
                                                <i onClick={removePhoto} className="far fa-lg fa-times-circle remove"></i>
                                                <img className="w-50 rounded img-thumbnail img-fluid" id="image" src={photoUrl} alt="" />
                                            </div>
                                            <input
                                                className="d-none"
                                                type="text"
                                                {...register('photo',)}
                                            />
                                        </>)}
                                </div>
                            </div>
                            <div className="modal-footer" style={{ border: 0 }}>
                                <ul className='posting-tools'>
                                    <li>
                                        <label className="label">
                                            <input
                                                type="file"
                                                disabled={isSubmitted}
                                                className="sr-only"
                                                onChange={uploadPhoto} name="image" accept="image/*" />
                                            <i className="fa fa-photo"></i>
                                        </label>
                                    </li>
                                    <li><Link to="#" title=""><i className="fa fa-smile-o"></i></Link></li>
                                </ul>
                                <button
                                    disabled={(isSubmitted && !addPostError) || !isValid}
                                    className="modal-button"
                                    type="submit">
                                    Post
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

const EnhancedPostModal = withAddPost(PostModal)
export default EnhancedPostModal
