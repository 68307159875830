import React from "react"
import { Link } from 'react-router-dom'
import useNewsData from "../async-manager/mutations/news"
import Loading from "../layouts/Loading"
import RecentNewsCard from "./news/RecentNewsCard"
import Error from "../layouts/Error"

const RightSideBar = () => {

    const {
        loadingNews, newsError, refetch, news
    } = useNewsData({ page: 1 })

    return (
        <div className="col-lg-3 pd-right-none no-pd">
            <div className="right-sidebar">
                <div className="widget widget-about">
                    <img src="images/wd-logo.png" alt="" />
                    <h3>Advertise With CoopConnect</h3>
                    <span>Pay only for the engagement</span>
                    <div className="sign_link">
                        <h3><Link to="sign-in.html" title="">Contact Us</Link></h3>
                        <Link to="#" title="">Learn More</Link>
                    </div>
                </div>
                {/* <!--widget-about end--> */}
                <div className="widget">
                    <div className="right-side-heading">
                        <h3>Recent News</h3>
                    </div>

                    <div className="jobs-list">
                        {news &&
                            news.slice(0, 5).map(data => <RecentNewsCard key={data.id} data={data} />)
                        }
                        {loadingNews && <Loading />}
                        {newsError && <Error error={newsError} onReload={() => refetch()} />}
                        <div className="text-center py-2">
                            <Link className="view-more" to={`/news`} title="">View More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightSideBar
