import React from 'react'
import 'rc-slider/assets/index.css'
import Scrollbars from 'react-custom-scrollbars'

const LeftSideBarNews = () => {

    return (
        <div className="d-none d-lg-block col-lg-3 col-md-4 pd-left-none no-pd">
            <div className="main-left-sidebar no-margin">
                <div className="user-data full-width">
                    <div className="user-profile">
                        <div className="sokoni-pic my-4">
                            <img src='/images/theCooperatorNews-logo.png' alt="" />
                        </div>
                    </div>
                </div>

                <div className="suggestions full-width">
                    <div className="sd-title">
                        <h3>News Categories</h3>
                        <i className="la la-ellipsis-v"></i>
                    </div>
                    <div className="suggestions-list">
                        <Scrollbars autoHide style={{ height: 400 }}>
                            <div className="categories-list">
                                <ul>
                                    Coming soon ...
                                    {/* {categories.map(category => (
                                        <li key={category.id} className={+category_id === category.id ? 'active' : ''}>
                                            <Link to={`/marketplace/category/${category.id}`} className='lead row anchor-no-decoration'>
                                                <div className='image'>
                                                    <img className='category-img img-fluid ml-2 mr-2 mb-0' src={category.icon_url} alt="" />
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <h4 className='h6'>{category.name}</h4>
                                                    <span>{category.description}</span>
                                                </div>
                                            </Link>
                                        </li>
                                    ))} */}
                                </ul>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftSideBarNews
