import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Link, useRouteMatch } from 'react-router-dom'

import 'slick-carousel/slick/slick'

const FriendsPage = ({ id, children }) => {
    const isSelf = useSelector(state => state.authedUser === id)

    // Get last part of URL string
    const { path } = useRouteMatch()
    const routeName = path.split('/').pop()

    return (
        <Fragment>
            <div className="col-lg-9 col-md-8 no-pd profile-main">
                <div className="main-ws-sec">
                    <div className="product-feed-tab" id="my-bids">
                        <ul className="nav nav-tabs bid-tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}`}>Posts</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/about`}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to={`/profile/${id}/friends`}>Friends</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/photos`}>Photos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/profile/${id}/videos`}>Videos</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="post-topbar">
                        <div className="d-flex justify-content-center">
                            <div className="product-feed-tab" id="my-bids">
                                <ul className="nav nav-tabs bid-tab" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <Link className={`nav-link ${routeName === 'friends' && 'active'}`} to={`/profile/${id}/friends`}>All Friends</Link>
                                    </li>
                                    {isSelf && (
                                        <>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${routeName === 'may-know' && 'active'}`} to={`/profile/${id}/friends/may-know`}>People You May Know</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${routeName === 'requests' && 'active'}`} to={`/profile/${id}/friends/requests`}>Requests Received</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${routeName === 'sent' && 'active'}`} to={`/profile/${id}/friends/sent`}>Requests Sent</Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FriendsPage
