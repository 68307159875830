import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Scrollbars } from 'react-custom-scrollbars'
import MessageFriend from "./MessageFriend"
import { useState } from "react"

const FriendBox = props => {
    const { friends } = props

    const [hoverFriend, setHoverFriend] = useState()
    const handleFriendFocus = id => setHoverFriend(id)

    return (
        <div className="col-lg-3 pd-right-none no-pd">
            <div className="msgs-list">
                <div className="msg-title">
                    <h3>Friends</h3>
                    <ul>
                        <li><Link to="#" title=""><i className="fa fa-user"></i></Link></li>
                    </ul>
                </div>
                <Scrollbars autoHide style={{ height: 448 }}>
                    <div className="friends-list">
                        <ul>
                            {friends && friends.map(id => (
                                <MessageFriend key={id} id={id} onMouseOver={handleFriendFocus} hoverFriend={hoverFriend} />
                            ))}
                        </ul>
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

const mapStateToProps = ({ authedUser, users }) => {
    const user = users[authedUser]
    const friends = user?.friends

    return {
        user, friends
    }
}

export default connect(mapStateToProps)(FriendBox)
