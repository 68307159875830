import {
    RECEIVE_USERS, UPDATE_USER, ADD_FRIEND,
    ACCEPT_FRIEND, CANCEL_REQUEST, UNFRIEND
} from "../actions/users"

export default function users(state = {}, action) {
    switch (action.type) {
        case RECEIVE_USERS:
            const { users } = action

            if (users === null) {
                return {
                    ...users
                }
            }
            return {
                ...state,
                ...users
            }
        case UPDATE_USER:
            return {
                ...state,
                [action.user.id]: action.user
            }
        case ADD_FRIEND:

            return {
                ...state,
                [action.friend.id]: {
                    ...state[action.friend.id],
                    requests: [...state[action.friend.id].requests, action.friend.authedUser]
                },
                [action.friend.authedUser]: {
                    ...state[action.friend.authedUser],
                    sent: [...state[action.friend.authedUser].sent, action.friend.id]

                }
            }
        case UNFRIEND:
            return {
                ...state,
                [action.friend.authedUser]: {
                    ...state[action.friend.authedUser],
                    friends: state[action.friend.authedUser].friends.filter(id => id !== action.friend.id),
                }
            }
        case ACCEPT_FRIEND:
            const { toAccept, currentUser } = action.friend

            return {
                ...state,
                [toAccept]: {
                    ...state[toAccept],
                    sent: state[toAccept].sent.filter(id => id !== currentUser),
                    friends: [...state[toAccept].friends, currentUser]
                },
                [currentUser]: {
                    ...state[currentUser],
                    requests: state[currentUser].requests.filter(id => id !== toAccept),
                    friends: [...state[currentUser].friends, toAccept]
                }
            }
        case CANCEL_REQUEST:
            const { cancelled, user } = action.friend

            return {
                ...state,
                [cancelled]: {
                    ...state[cancelled],
                    sent: state[cancelled].sent.filter(id => id !== user)
                },
                [user]: {
                    ...state[user],
                    requests: state[user].requests.filter(id => id !== cancelled)
                }
            }
        default:
            return state
    }
}
