const loadState = key => {
  const stringifiedData = localStorage.getItem(key)
  return JSON.parse(stringifiedData)
}

const updateState = (key, data) => {
  const stringifiedData = JSON.stringify(data)
  const savedData = localStorage.setItem(key, stringifiedData)
  return savedData
}

const removeState = key => {
  localStorage.removeItem(key)
}

const clearStorage = () => {
  localStorage.clear()
}

module.exports = {
  clearStorage,
  updateState,
  loadState,
  removeState,
}
