import React from "react"
import ForumCategory from "./ForumCategory"
import ForumListGroup from "./ForumListGroup"

const ForumRightSide = () => (
    <div className="col-lg-4 fr-side">
        <ForumListGroup />
        <ForumCategory />
    </div>
)

export default ForumRightSide
