import React from "react"
import Name from "../layouts/Name"
import Image from "../layouts/Image"
import useFriendsMutations from "../async-manager/mutations/friends"

const Suggestions = ({ user, status, id }) => {
    const { first_name, last_name, image } = user
    const name = `${first_name} ${last_name}`

    const {
        onHandleAcceptFriend, acceptFriendLoading,
        onHandleUnFriend, unFriendLoading,
        onHandleAddFriend, addFriendLoading
    } = useFriendsMutations()

    return (
        <>
            <div className="suggestion-usd row">
                <Image image={image} className='smaller-img' />
                <div className="sgt-text">
                    <h4 style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}><Name author_id={id} name={name} /></h4>
                    <span>No mutual friends</span>
                    <div className="d-flex suggestion-buttons mt-2">
                        {status === 'friends' &&
                            <button
                                onClick={() => onHandleUnFriend(id)}
                                disabled={unFriendLoading}
                                className="friends-button mr-2"
                            > Unfriend
                            </button>
                        }
                        {status === 'received' && (
                            <>
                                <button
                                    onClick={() => onHandleAcceptFriend(id)}
                                    disabled={acceptFriendLoading}
                                    className="friends-button mr-2"
                                > Confirm
                                </button>
                                <button
                                    onClick={() => onHandleUnFriend(id)}
                                    disabled={unFriendLoading}
                                    className="friends-button-secondary"
                                >Reject</button>
                            </>
                        )}
                        {status === 'sent' && (
                            <button
                                onClick={() => onHandleUnFriend(id)}
                                disabled={unFriendLoading}
                                className="friends-button-secondary"
                            > Cancel Request
                            </button>
                        )}
                        {status === 'may-know' && (
                            <button
                                onClick={() => onHandleAddFriend(id)}
                                disabled={addFriendLoading}
                                className="friends-button"
                            >Add Friend
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Suggestions
