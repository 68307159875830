import React, { useContext } from "react"
import { useForumCategoryStats } from "../../async-manager/queries"
import Loading from "../../layouts/Loading"
import { ForumContext } from "./ForumProvider"

const ForumCategory = () => {
    const {
        data: categories,
    } = useForumCategoryStats()

    const {
        category_id, handleCategory,
        loadingPage, loading
    } = useContext(ForumContext)

    return (
        <div className="widget widget-user">
            <h3 className="title-wd">Categories</h3>
            <ul className="mb-2 category-list">
                {categories && loadingPage ? (
                    <div className="loading-indicator">
                        <Loading />
                    </div>
                ) : null}
                {categories
                    && categories.map(category => (
                        <li key={category.id} onClick={() => handleCategory(category.id)}
                            className={`category-name d-flex w-100 justify-content-between ${category_id === category.id ? 'active' : ''}`}>
                            <h4 className="align-self-center category-value">{category.name}</h4>
                            <span className="lead">
                                <span className="badge badge-secondary">{category.total}</span>
                            </span>
                        </li>
                    ))}
                {loading && <Loading />}
            </ul>
        </div >
    )
}

export default ForumCategory
