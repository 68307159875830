import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Scrollbars } from 'react-custom-scrollbars'
import { useForm } from 'react-hook-form'
import { useContext, useEffect, useRef, useState } from "react"
import MessageFriend from "./friends/MessageFriend"
import { MessageContext } from "./MessageProvider"

const MessageBox = props => {
    const [, setTop] = useState(0)
    const [focusState, setFocusState] = useState(true)
    const [hoverFriend, setHoverFriend] = useState()

    const { activeFriend, handleSetActiveFriend } = useContext(MessageContext)

    const { friends, users } = props
    const activeFriendDetails = users[activeFriend]
    const { name } = activeFriendDetails || ''
    const scrollbarRef = useRef(null)
    const friendToMessage = useRef(null)

    const handleScrollFrame = (values) => {
        const { top } = values
        setTop(top)
    }

    const [query, setQuery] = useState('')

    const updateQuery = query => {
        setQuery(query)
    }

    const {
        register,
        handleSubmit, reset,
        formState: { errors, isSubmitted, isValid },
    } = useForm({ mode: "onChange" })

    const onSendMessage = data => {
        console.log("Message to be sent", data)
        reset()
    }

    const handleFriendFocus = id => setHoverFriend(id)

    useEffect(() => {
        friendToMessage.current.focus()
        scrollbarRef.current.scrollToBottom()
    }, [])

    const handleInputFocus = () => setFocusState(true)

    const handleInputBlur = (event) => setFocusState(false)

    const showingFriends = query === ''
        ? friends
        : friends && friends.filter(friend =>
            users[friend].name.toLowerCase().includes(query.trim().toLowerCase()))

    return (
        <div className="col-lg-6 col-md-8 no-pd">
            <div className="main-conversation-box position-relative">
                <div className="message-bar-head msg-title d-flex align-items-center">
                    {!activeFriendDetails ? (
                        <>
                            <h3 className="mr-2">To:</h3>
                            <div className="mf-field d-block">
                                <input
                                    ref={friendToMessage}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    value={query}
                                    onChange={event => updateQuery(event.target.value)}
                                    className="w-100" id="message" type="text" name="reply"
                                    placeholder="Search for friend ..." />
                            </div>
                        </>
                    ) : (
                        <div className="usr-msg-details d-flex">
                            <div className="usr-ms-img">
                                <img src="images/resources/m-img1.png" alt="" />
                            </div>
                            <div className="usr-mg-info">
                                <h3>{name}</h3>
                                <p>Online</p>
                            </div>
                        </div>
                    )}
                </div>
                {!activeFriendDetails && focusState && (
                    <div className="d-flex message-box-select ml-5 position-absolute" style={{ top: "45px", zIndex: 1 }}>
                        <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="180px" >
                            <ul>
                                {showingFriends && showingFriends.map(id => (
                                    <MessageFriend key={id} onClick={handleSetActiveFriend} id={id} activeFriend={activeFriend} onMouseOver={handleFriendFocus} hoverFriend={hoverFriend} />
                                ))}
                            </ul>
                        </Scrollbars>
                    </div>
                )}
                <Scrollbars
                    ref={scrollbarRef}
                    onScrollFrame={handleScrollFrame}
                    autoHide style={{ height: 333 }}>
                    <div className="messages-line">
                        {activeFriendDetails && (
                            <>
                                <div className="main-message-box">
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img1.png" alt="" />
                                    </div>
                                    <div className="message-dt">
                                        <div className="message-inner-dt img-bx">
                                            <img src="images/resources/mt-img1.png" alt="" />
                                            <img src="images/resources/mt-img2.png" alt="" />
                                            <img src="images/resources/mt-img3.png" alt="" />
                                        </div>
                                        <span>Sat, Aug 23, 1:08 PM</span>
                                    </div>
                                </div>
                                <div className="main-message-box ta-right">
                                    <div className="message-dt">
                                        <div className="message-inner-dt">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor.</p>
                                        </div>
                                        <span>Sat, Aug 23, 1:08 PM</span>
                                    </div>
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img2.png" alt="" />
                                    </div>
                                </div>
                                <div className="main-message-box st3">
                                    <div className="message-dt st3">
                                        <div className="message-inner-dt">
                                            <p>Cras ultricies ligula.<img src="images/smley.png" alt="" /></p>
                                        </div>
                                        <span>5 minutes ago</span>
                                    </div>
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img1.png" alt="" />
                                    </div>
                                </div>
                                <div className="main-message-box ta-right">
                                    <div className="message-dt">
                                        <div className="message-inner-dt">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor.</p>
                                        </div>
                                        <span>Sat, Aug 23, 1:08 PM</span>
                                    </div>
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img2.png" alt="" />
                                    </div>
                                </div>
                                <div className="main-message-box st3">
                                    <div className="message-dt st3">
                                        <div className="message-inner-dt">
                                            <p>Lorem ipsum dolor sit amet</p>
                                        </div>
                                        <span>2 minutes ago</span>
                                    </div>
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img1.png" alt="" />
                                    </div>
                                </div>
                                <div className="main-message-box ta-right">
                                    <div className="message-dt">
                                        <div className="message-inner-dt">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor.</p>
                                        </div>
                                        <span>Sat, Aug 23, 1:08 PM</span>
                                    </div>
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img2.png" alt="" />
                                    </div>
                                </div>
                                <div className="main-message-box st3">
                                    <div className="message-dt st3">
                                        <div className="message-inner-dt">
                                            <p>....</p>
                                        </div>
                                        <span>Typing...</span>
                                    </div>
                                    <div className="messg-usr-img">
                                        <img src="images/resources/m-img1.png" alt="" />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Scrollbars>
                <div className="message-send-area">
                    <form onSubmit={handleSubmit(onSendMessage)}>
                        <div className="mf-field">
                            {errors.message && <p className='text-danger'>{errors.message.message}</p>}
                            <input
                                type="text"
                                id="eighty"
                                disabled={isSubmitted}
                                name="message"
                                {...register('message', {
                                    required: true,
                                    minLength: 2
                                })}
                                placeholder="Type a message here"
                            />
                            <button id="send-message" disabled={isSubmitted || !isValid} type="submit">Send</button>
                        </div>
                        <ul>
                            <li><Link to="#" title=""><i className="fa fa-smile-o"></i></Link></li>
                            <li><Link to="#" title=""><i className="fa fa-camera"></i></Link></li>
                            <li><Link to="#" title=""><i className="fa fa-paperclip"></i></Link></li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ authedUser, users }) => {
    const user = users[authedUser]
    const friends = user?.friends

    return {
        user,
        friends, users
    }
}

export default connect(mapStateToProps)(MessageBox)
