import React from "react"
import useFriendsMutations from "../async-manager/mutations/friends"
import { useUserInfo, useUserStatus } from "../async-manager/queries"
import Image from "../layouts/Image"
import { loadState } from "../utils/storage"

const LeftSideBar = ({ id }) => {
    const authedUserId = loadState('id')
    const isSelf = authedUserId === id
    let status

    const { data: user_info/*, isLoading: loading, error, refetch*/ } = useUserInfo({ id })
    const { data: user_status/*, isLoading: loading, error, refetch*/ } = useUserStatus({ id })
    if (!isSelf) {
        if (user_status) {
            if (user_status.status === 'friend') {
                status = 'friend'
            } else if (user_status.status === 'sent') {
                status = 'sent'
            } else if (user_status.status === 'received') {
                status = 'received'
            } else {
                status = 'may-know'
            }
        }
    }

    const {
        onHandleAcceptFriend, acceptFriendLoading,
        onHandleUnFriend, unFriendLoading,
        onHandleAddFriend, addFriendLoading
    } = useFriendsMutations()

    if (!user_info) return null
    const { name, image, friends, requests } = user_info

    return (
        <>
            <div className="col-lg-3">
                <div className="main-left-sidebar">
                    <div className="user_profile">
                        <div className="user-pro-img">
                            <Image image={image} />
                            <h3 className="mt-1">{name}</h3>
                            <span>@username</span>
                        </div>
                        <div className="user_pro_status">
                            <div className="mb-2">
                                {!isSelf && status === 'may-know' &&
                                    <button
                                        type="button"
                                        onClick={() => onHandleAddFriend(id)}
                                        disabled={addFriendLoading}
                                        className="btn btn-primary "
                                    >
                                        <i className="la la-plus"></i> Add Friend
                                    </button>
                                }
                                {!isSelf && status === 'received' && (
                                    <div className="row d-flex justify-content-around">
                                        <button
                                            type="button"
                                            onClick={() => onHandleAcceptFriend(id)}
                                            disabled={acceptFriendLoading}
                                            className="btn btn-secondary mt-1">Confirm</button>
                                        <button
                                            type="button"
                                            onClick={() => onHandleUnFriend(id)}
                                            disabled={unFriendLoading}
                                            className="btn btn-info mt-1">Delete</button>
                                    </div>
                                )}
                                {!isSelf && status === 'sent' &&
                                    <div className="row d-flex justify-content-around">
                                        <button
                                            type="button"
                                            onClick={() => onHandleUnFriend(id)}
                                            disabled={unFriendLoading}
                                            className="btn btn-danger mt-1">
                                            <i className="las la-times"></i> Cancel Sent Request
                                        </button>
                                    </div>
                                }
                                {!isSelf && status === 'friend' &&
                                    <div className="row d-flex justify-content-around">
                                        <button
                                            type="button"
                                            onClick={() => onHandleUnFriend(id)}
                                            disabled={unFriendLoading}
                                            className="btn btn-danger mt-1">
                                            <i className="las la-times"></i> Unfriend
                                        </button>
                                        <button type="button" className="btn btn-info mt-1">
                                            <i className="lar la-envelope"></i> Message
                                        </button>
                                    </div>
                                }
                            </div>
                            <ul className="flw-status">
                                <li>
                                    <span>Friends</span>
                                    <b>{friends}</b>
                                </li>
                                <li>
                                    <span>Requests</span>
                                    <b>{requests}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeftSideBar
