import React, { useCallback, useContext } from "react"
import ReactPaginate from 'react-paginate'
import Forum from "./Forum"
import Loading from "../../layouts/Loading"
import { ForumContext } from "./ForumProvider"
import ProgressDots from "../../layouts/ProgressDots"
import Error from "../../layouts/Error"
import { useToggleVoteQuestion } from "../../async-manager/queries"

const ForumMainPage = () => {
    const {
        questions, handlePageClick, pageCount,
        loadingPage, error, onReload, loading
    } = useContext(ForumContext)

    const { mutate } = useToggleVoteQuestion()

    const onHandleVote = useCallback(
        ({ id, hasVoted, authedUser }) => mutate({ id, hasVoted, authedUser }), [mutate]
    )

    if (loading) return <Loading />

    return (
        <>
            <div className="forum-questions">
                {questions && Object.keys(questions).length > 0 && Object.keys(questions).map(question => (
                    <Forum key={question} question={questions[question]} handleVote={onHandleVote} />
                ))}
                {questions && Object.keys(questions).length < 1 && (
                    <div className="usr-question text-center">
                        No forum questions ...
                    </div>
                )}
            </div>
            {loadingPage && <ProgressDots />}
            {pageCount > 1 &&
                <nav aria-label="Page navigation example" className="full-pagi">
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link cursor-default'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link pvr'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link pvr'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={20}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        activeLinkClassName={'page-link active'}
                    />
                </nav>
            }
            {error && <Error error={error} onReload={onReload} />}
            {loading && <Loading />}
        </>
    )
}

export default ForumMainPage
