import React, { useState } from "react"
import ReactPaginate from "react-paginate"
import useNewsData from "../../async-manager/mutations/news"
import Error from "../../layouts/Error"
import Loading from "../../layouts/Loading"
import ProgressDots from "../../layouts/ProgressDots"
import NewsCard from "./NewsCard"

const NewsMainPage = () => {
    const [page, setPage] = useState(1)

    const {
        loadingNews, isPreviousData, newsError,
        refetch, loadingNewsPage, news, headers,
    } = useNewsData({ page })

    const pageCount = headers ? +headers['x-wp-totalpages'] : 0

    const handlePageClick = ({ selected }) => {
        setPage(+selected + 1)
        !isPreviousData && refetch({ page: +selected + 1 })
    }

    console.log('News', news)

    return (
        <div className="col-lg-9 col-md-8">
            {news && news?.length < 1 && (
                <div className="my-5 mx-auto">
                    <span className="lead font-weight-bold">No news to display</span>
                </div>
            )}
            <div className={`ml-4 w-100 category-list ${loadingNewsPage ? 'loading' : ''}`}>
                {news && loadingNewsPage ? (
                    <div className="loading-indicator">
                        <Loading />
                    </div>
                ) : null}
                {news && news.map(data => <NewsCard key={data.id} data={data} />)}
            </div>
            {loadingNews && <Loading />}
            {news && loadingNewsPage && <ProgressDots />}
            {pageCount > 1 &&
                <div className={loadingNewsPage ? 'd-none' : ''}>
                    <div className="d-flex justify-content-center w-100">
                        <div className="row">
                            <nav aria-label="Page navigation example" className="full-pagi">
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link cursor-default'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link pvr'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link pvr'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={10}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    activeLinkClassName={'page-link active'}
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            }
            {newsError && <Error error={newsError} onReload={() => refetch()} />}
        </div>

    )
}

export default NewsMainPage
