import React from 'react'
import Footer from './Footer'
import SettingsPage from './SettingsPage'

const Settings = () => {
    return (
        <div className="wrapper">
            <SettingsPage />
            <Footer />
        </div>
    )
}

export default Settings
