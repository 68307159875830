import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    acceptFriend,
    getCategories, getFora, getForumAnswers, getForumCategories, getForumCategoryStats,
    getNewsPosts, getProducts, saveAnswerComment, saveLikeAnswerToggle, getUserStatus,
    saveLikeForumCommentToggle, saveLikeTogglePost, saveLikeTogglePostComment, savePost, saveQuestion,
    saveQuestionAnswer, saveReplyPost, saveVoteQuestionToggle, unFriend, addFriend, getUserInfo
} from "../utils/api"

export const useCategories = () => useQuery(['categories'], getCategories)
export const useForumCategories = () => useQuery(['forum_categories'], getForumCategories)
export const useForumCategoryStats = () => useQuery(['forum_categories_stats'], getForumCategoryStats)

export const useForumAnswers = question_id => useQuery({
    queryKey: ['forum_answers', question_id],
    queryFn: () => getForumAnswers(question_id)
})

export const useProducts = (page, price_range, category_id) => useQuery({
    queryKey: ['products', page, price_range, category_id],
    queryFn: () => getProducts(page),
    keepPreviousData: true
})

export const useForum = (page, category_id) => useQuery({
    queryKey: ['forum', page, category_id],
    queryFn: () => getFora(page, category_id),
    keepPreviousData: true,
    retry: 0
})

export const useNews = page => useQuery({
    queryKey: ['news', page],
    queryFn: () => getNewsPosts(page),
    keepPreviousData: true,
    retry: 0
})

export const useUserInfo = id => useQuery({
    queryKey: ['user-info', id],
    queryFn: () => getUserInfo(id),
    retry: 0
})

export const useUserStatus = id => useQuery({
    queryKey: ['user-status', id],
    queryFn: () => getUserStatus(id),
    retry: 0
})

export const useAddForumAnswer = question_id => {
    const queryClient = useQueryClient()

    return useMutation(saveQuestionAnswer, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['forum_answers', question_id] })
    })
}

export const useAcceptFriend = () => {
    const queryClient = useQueryClient()

    const queryKeys = ['friends', 'users-may-know', 'requests-sent', 'friend-requests', 'user-info', 'user-status']
    return useMutation(acceptFriend, {
        onSuccess: () => queryKeys.forEach(queryKey => queryClient.invalidateQueries(queryKey))
    })
}

export const useUnFriend = () => {
    const queryClient = useQueryClient()

    const queryKeys = ['friends', 'users-may-know', 'requests-sent', 'friend-requests', 'user-info', 'user-status']
    return useMutation(unFriend, {
        onSuccess: () => queryKeys.forEach(queryKey => queryClient.invalidateQueries(queryKey))
    })
}

export const useAddFriend = () => {
    const queryClient = useQueryClient()

    const queryKeys = ['friends', 'users-may-know', 'requests-sent', 'friend-requests', 'user-info', 'user-status']
    return useMutation(addFriend, {
        onSuccess: () => queryKeys.forEach(queryKey => queryClient.invalidateQueries(queryKey))
    })
}

export const useAddForumComment = question_id => {
    const queryClient = useQueryClient()

    return useMutation(saveAnswerComment, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['forum_answers', question_id] })
    })
}

export const useAddForumQuestion = () => {
    const queryClient = useQueryClient()
    return useMutation(saveQuestion, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['forum'] })
    })
}

export const useToggleVoteQuestion = question_id => {
    const queryClient = useQueryClient()
    const onMutate = async newVote => {
        const queryKey = question_id ? ['forum_answers', question_id] : ['forum']
        const previousData = queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, prevData => {
            if (question_id) {
                return {
                    ...prevData,
                    question: {
                        ...prevData?.question,
                        votes: newVote.hasVoted
                            ? prevData?.question?.votes.filter(vote => vote !== newVote.authedUser)
                            : [...(prevData?.question?.votes || []), newVote.authedUser,]
                    },
                }
            } else {
                return {
                    ...prevData,
                    questions: {
                        ...prevData?.questions,
                        [newVote.id]: {
                            ...(prevData?.questions?.[newVote.id] || {}),
                            votes: newVote.hasVoted
                                ? prevData?.questions?.[newVote.id]?.votes.filter(vote => vote !== newVote.authedUser)
                                : [...(prevData?.questions?.[newVote.id]?.votes || []), newVote.authedUser,]
                        },
                    },
                }
            }
        })

        return { previousData }
    }
    const onError = (err, newVote, context) => {
        const queryKey = question_id ? ['forum_answers', question_id] : ['forum']
        queryClient.setQueryData(queryKey, context.previousData)
    }
    const onSettled = () => {
        const queryKey = question_id ? ['forum_answers', question_id] : ['forum']
        queryClient.invalidateQueries({ queryKey })
    }
    const onSuccess = () => {
        const queryKey = question_id ? ['forum_answers', question_id] : ['forum']
        queryClient.invalidateQueries({ queryKey })
    }

    return useMutation(saveVoteQuestionToggle, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
    })
}

export const useToggleLikeAnswer = question_id => {
    const queryClient = useQueryClient()

    return useMutation(saveLikeAnswerToggle, {
        onMutate: async newLike => {
            await queryClient.cancelQueries(['forum_answers', question_id])
            const previousData = queryClient.getQueryData(['forum_answers', question_id])

            queryClient.setQueryData(['forum_answers', question_id], prevData => {
                return {
                    ...prevData,
                    answers: {
                        ...prevData?.answers,
                        [newLike.id]: {
                            ...(prevData?.answers?.[newLike.id] || {}),
                            likes: newLike.hasLiked
                                ? prevData?.answers?.[newLike.id]?.likes?.filter(like => like !== newLike.authedUser)
                                : [...(prevData?.answers?.[newLike.id]?.likes || []), newLike.authedUser]
                        }
                    }
                }
            })

            return { previousData }
        },
        onError: (err, newLike, context) => {
            queryClient.setQueryData(['forum_answers', question_id], context.previousData)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['forum_answers', question_id] })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['forum_answers', question_id] })
    })
}

export const useAddPostComment = queryKey => {
    const queryClient = useQueryClient()

    return useMutation(saveReplyPost, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey] })
    })
}

export const useAddPost = queryKey => {
    const queryClient = useQueryClient()

    return useMutation(savePost, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey] })
    })
}

export const useToggleLikeComment = question_id => {
    const queryClient = useQueryClient()

    return useMutation(saveLikeForumCommentToggle, {
        onMutate: async newLike => {
            const previousData = queryClient.getQueryData(['forum_answers', question_id])

            queryClient.setQueryData(['forum_answers', question_id], prevData => {
                return {
                    ...prevData,
                    answers: {
                        ...prevData?.answers,
                        [newLike.answerId]: {
                            ...prevData?.answers?.[newLike.answerId],
                            comments: {
                                ...prevData?.answers?.[newLike.answerId]?.comments,
                                [newLike.id]: {
                                    ...(prevData?.answers?.[newLike.answerId]?.comments?.[newLike.id] || {}),
                                    likes: newLike.hasLiked
                                        ? prevData?.answers?.[newLike.answerId]?.comments?.[newLike.id]?.likes?.filter(like => like !== newLike.authedUser)
                                        : [...(prevData?.answers?.[newLike.answerId]?.comments?.[newLike.id]?.likes || []), newLike.authedUser]
                                }
                            }
                        }
                    }
                }
            })

            return { previousData }
        },
        onError: (err, newLike, context) => {
            queryClient.setQueryData(['forum_answers', question_id], context.previousData)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['forum_answers', question_id] })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['forum_answers', question_id] })
    })
}

export const useToggleLikePost = (queryKey, page) => {
    const queryClient = useQueryClient()

    return useMutation(saveLikeTogglePost, {
        onMutate: async newLike => {
            await queryClient.cancelQueries([queryKey])
            const previousData = queryClient.getQueryData([queryKey])

            queryClient.setQueryData([queryKey], prevData => {
                return {
                    ...prevData,
                    pages: [
                        ...prevData.pages.slice(0, page),
                        {
                            ...prevData?.pages?.[page],
                            data: {
                                ...prevData?.pages?.[page]?.data,
                                [newLike.id]: {
                                    ...(prevData?.pages?.[page]?.data?.[newLike.id] || []),
                                    likes: newLike.hasLiked
                                        ? prevData?.pages?.[page]?.data?.[newLike.id]?.likes?.filter(like => like !== newLike.authedUser)
                                        : [...(prevData?.pages?.[page]?.data?.[newLike.id]?.likes || []), newLike.authedUser]
                                }
                            }
                        },
                        ...prevData.pages.slice(page + 1),
                    ]
                }
            })

            return { previousData }
        },
        onError: (err, newLike, context) => {
            queryClient.setQueryData([queryKey], context.previousData)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [queryKey] })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey] })
    })
}

export const useToggleLikePostComment = (queryKey, page) => {
    const queryClient = useQueryClient()

    return useMutation(saveLikeTogglePostComment, {
        onMutate: async newLike => {
            await queryClient.cancelQueries([queryKey])
            const previousData = queryClient.getQueryData([queryKey])

            queryClient.setQueryData([queryKey], prevData => {
                return {
                    ...prevData,
                    pages: [
                        ...prevData.pages.slice(0, page),
                        {
                            ...prevData?.pages?.[page],
                            data: {
                                ...prevData?.pages?.[page]?.data,
                                [newLike.post_id]: {
                                    ...(prevData?.pages?.[page]?.data?.[newLike.post_id] || []),
                                    comments: {
                                        ...(prevData?.pages?.[page]?.data?.[newLike.post_id].comments || []),
                                        [newLike.comment_id]: {
                                            ...(prevData?.pages?.[page]?.data?.[newLike.post_id].comments?.[newLike.comment_id] || []),
                                            likes: newLike.hasLiked
                                                ? prevData?.pages?.[page]?.data?.[newLike.post_id].comments?.[newLike.comment_id]?.likes?.filter(like => like !== newLike.authedUser)
                                                : [...(prevData?.pages?.[page]?.data?.[newLike.post_id].comments?.[newLike.comment_id]?.likes || []), newLike.authedUser]
                                        }
                                    }
                                }
                            }
                        },
                        ...prevData.pages.slice(page + 1),
                    ]
                }
            })

            return { previousData }
        },
        onError: (err, newLike, context) => {
            queryClient.setQueryData([queryKey], context.previousData)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [queryKey] })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey] })
    })
}
