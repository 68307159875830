import { loginUser, registerUser, updateUser } from "../utils/api"
import { setAuthHeader } from "../utils/backendAPI"
import { updateState } from "../utils/storage"
import { setAuthedUser } from "./authedUser"
import { handleInitialData } from "./shared"
import { setToken } from "./token"

export const RECEIVE_USERS = 'RECEIVE_USERS'
export const UPDATE_USER = 'UPDATE_USER'
export const ADD_FRIEND = 'ADD_FRIEND'
export const ACCEPT_FRIEND = 'ACCEPT_FRIEND'
export const CANCEL_REQUEST = 'CANCEL_REQUEST'
export const UNFRIEND = 'UNFRIEND'

export function receiveUsers(users) {
    return {
        type: RECEIVE_USERS,
        users
    }
}

export function addFriend(friend) {
    return {
        type: ADD_FRIEND,
        friend
    }
}

export function unFriend(friend) {
    return {
        type: UNFRIEND,
        friend
    }
}

export function acceptFriend(friend) {
    return {
        type: ACCEPT_FRIEND,
        friend
    }
}

export function cancelFriend(friend) {
    return {
        type: CANCEL_REQUEST,
        friend
    }
}

function updateInfo(user) {
    return {
        type: UPDATE_USER,
        user
    }
}

export function handleUpdateUser(info) {
    return (dispatch, getState) => {
        const { token } = getState()

        return updateUser({ token, ...info })
            .then(user => {
                dispatch(updateInfo(user))
            })
            .catch(e => { throw e })
    }
}

export function handleLogin(info) {
    return (dispatch) => {
        return loginUser(info)
            .then(user => {
                const { status } = user
                if (status) {
                    dispatch(setToken(user.token))
                    dispatch(setAuthedUser(user.id))
                    updateState('token', user.token)
                    updateState('name', user.name)
                    updateState('id', user.id)
                    updateState('image', user.image)
                    setAuthHeader()
                    dispatch(handleInitialData())
                    // history.replace('/')
                } else {
                    return { error: user.errorMessage }
                }
            })
            .catch(err => err.errorMessage)
    }
}

export function handleRegister(info) {
    return dispatch => {
        return registerUser(info)
            .then(data => data)
            .catch(e => {
                console.error('There was an error registering', e)
            })
    }
}
