const {
    useAcceptFriend, useUnFriend,
    useAddFriend
} = require("../queries")

const useFriendsMutations = (id) => {
    const {
        mutate: acceptFriend, loading: acceptFriendLoading,
        error: acceptFriendError, isSuccess: acceptFriendSuccess
    } = useAcceptFriend()

    const {
        mutate: unFriend, loading: unFriendLoading,
        error: unFriendError, isSuccess: unFriendSuccess
    } = useUnFriend()

    const {
        mutate: addFriend, loading: addFriendLoading,
        error: addFriendError, isSuccess: addFriendSuccess
    } = useAddFriend()

    return {
        onHandleAcceptFriend: friend_id => acceptFriend(friend_id),
        acceptFriendLoading, acceptFriendError, acceptFriendSuccess,
        onHandleUnFriend: friend_id => unFriend(friend_id),
        unFriendLoading, unFriendError, unFriendSuccess,
        onHandleAddFriend: friend_id => addFriend(friend_id),
        addFriendLoading, addFriendError, addFriendSuccess
    }
}

export default useFriendsMutations
