import React, { useContext } from "react"
import ReactPaginate from "react-paginate"
import Error from "../../layouts/Error"
import Loading from "../../layouts/Loading"
import ProgressDots from "../../layouts/ProgressDots"
import { SokoniContext } from "./SokoniProvider"

const SokoniMainPage = props => {
    const {
        products, onReload, handlePageClick,
        pageCount, loading, loadingPage, error
    } = useContext(SokoniContext)

    return (
        <div className="col-lg-9 col-md-8">
            {products && products?.length < 1 && (
                <div className="my-5 mx-auto">
                    <span className="lead font-weight-bold">No products to display</span>
                </div>
            )}
            {products && products.length > 0 && (
                <div className="row w-100">
                    {products && products.map(product => {
                        const { id, name, sale_price, price, image_url, product_url } = product
                        return (
                            <a target='_blank' rel='noreferrer' href={product_url} className="text-decoration-none  text-reset col-lg-3 col-md-4 col-sm-6 col-12 mb-3 pr-0" key={id}>
                                <div className="card custom-transform">
                                    <img className="card-img-top border-bottom" style={{ height: '10rem' }} src={image_url} alt="Card cap" />
                                    <div className="card-body pb-2 pt-2" style={{ height: '5rem' }}>
                                        <h5 className="mb-1">{name}</h5>
                                        {(sale_price > 0)
                                            ? <>
                                                <p className="h5 bold mb-0">UGX {sale_price.toLocaleString("en-US")}</p>
                                                <p className="h6 text-muted"><del>UGX {price.toLocaleString("en-US")}</del></p>
                                            </>
                                            : <p className="h5 bold mb-0">UGX {price.toLocaleString("en-US")}</p>
                                        }
                                    </div>
                                </div>
                            </a>
                        )
                    })}
                    {loadingPage && <ProgressDots />}
                    {pageCount > 1 &&
                        <div className={loadingPage ? 'd-none' : ''}>
                            <div className="d-flex justify-content-center w-100">
                                <div className="row">
                                    <nav aria-label="Page navigation example" className="full-pagi">
                                        <ReactPaginate
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link cursor-default'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link pvr'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link pvr'}
                                            breakClassName={'break-me'}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={10}
                                            onPageChange={handlePageClick}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            activeLinkClassName={'page-link active'}
                                        />
                                    </nav>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )}
            {error && <Error error={error} onReload={onReload} />}
            {loading && <Loading />}
        </div>

    )
}

export default SokoniMainPage
