import { SET_TOKEN }
    from '../actions/token'

export default function users(state = null, action) {
    switch (action.type) {
        case SET_TOKEN:
            return action.token
        default:
            return state
    }
}