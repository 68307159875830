import React from 'react'
import NavBar from './NavBar'

const TermsOfUse = () => (
    <>
        <NavBar />
        <section className="legal-document">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h2>TERMS OF USE</h2>
                        <p className='text-muted font-weight-normal mb-1'>Last updated: 26th September, 2023</p>
                        <h2 className='h3'>AGREEMENT TO OUR LEGAL TERMS</h2>
                        <div>
                            <p className='mt-2'>We are <a href="https://uhuruinstitute.org">The Uhuru Institute for Social Development</a> ('<span className="font-weight-bold">Company</span>',
                                '<span className="font-weight-bold">we</span>', '<span className="font-weight-bold">us</span>', or '<span className="font-weight-bold">our</span>'), a company registered
                                in Uganda at Plot 1, Katalima Crescent, Kampala.
                            </p>
                            <p className='mt-2'>We operate the platform <a href="https://coopconnect.coop">https://coopconnect.coop</a> (the 'Site'), as well as any other related products and
                                services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services').
                            </p>
                            <p className='mt-2'>Coop Connect is a cooperative social media platform, aimed at fostering collaboration, communication, and resource sharing among members of the
                                cooperative community.
                            </p>
                            <p className='mt-2'>You can contact us by phone at <a href="tel:+256784100014">+256784100014</a>, email at <a href='mailto:info@coopconnect.coop'>info@coopconnect.coop</a>,
                                or by mail to Plot 1, Katalima Crescent, Kampala, Central, Uganda.
                            </p>
                            <p className='mt-2'>These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and The Uhuru Institute
                                for Social Development, concerning your access to and use of the Services. By accessing the Services, you confirm that you have read, comprehended, and agreed to be bound by
                                all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, YOU ARE EXPLICITLY PROHIBITED FROM USING THE SERVICES, AND YOU MUST CEASE USE IMMEDIATELY.
                            </p>
                            <p className='mt-2'>We will provide you with advance notice of any planned changes to the Services you are currently utilising. The modified Legal Terms will become effective
                                upon posting or notifying you by <a href='mailto:info@coopconnect.coop'>info@coopconnect.coop</a>, as stated in the email message.
                            </p>
                            <p className='mt-2'>Using the Services after changes take effect means you agree to the updated terms.
                            </p>
                            <p className='mt-2'>The Services are for users aged 13 and above. Minors (usually under 18) must have parental/guardian permission and supervision to use the Services.
                                Parents/guardians must read and agree to these Legal Terms for minors to use the Services.
                            </p>
                        </div>
                        <ol className="numbered">
                            <li><span className='font-weight-bold'>Our Services</span></li>
                            <div>
                                <p>The information provided through the Services is not meant to be distributed or used in jurisdictions where it would contravene local laws or regulations, or where it
                                    would necessitate our registration. Individuals accessing the Services from other locations do so voluntarily and must ensure compliance with local laws, where applicable.</p>
                                <p className='mt-2'>The Services are designed to comply with industry-specific regulations such as the Health Insurance Portability and Accountability Act (HIPAA) and the
                                    Uganda Data Privacy and Protection Act of 2019. However, you may not use the Services in a manner that violates the Gramm-Leach-Bliley Act (GLBA) or any other applicable
                                    regulations that prohibit the collection or processing of personal data in a way that infringes on the right to privacy of an individual.</p>
                            </div>
                            <li><span className='font-weight-bold'>Intellectual Property Rights</span></li>
                            <div>
                                <ul className='numbered-indent'>
                                    <li><span className='font-weight-bold'>Our intellectual property</span></li>
                                    <div>
                                        <p>
                                            We are the sole owners or licence holders of all intellectual property rights associated with our Services. This includes ownership or licensing of elements such
                                            as source code, databases, functionality, software, website designs, audio, video, text, photographs, graphics (referred to collectively as "Content"), as well as
                                            trademarks, service marks, and logos (referred to as "Marks") that are used within the Services.
                                        </p>
                                        <p className='mt-2'>
                                            Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in
                                            Uganda and around the world.
                                        </p>
                                        <p className='mt-2'>
                                            The Content and Marks available within the Services are provided "AS IS" for your personal, non-commercial use or internal business purposes only.
                                        </p>
                                    </div>
                                    <li><span className='font-weight-bold'>Your use of our Services</span></li>
                                    <div>
                                        Subject to your adherence to these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we provide you with a non-exclusive, non-transferable, and revocable licence to:
                                        <ul className='bulleted'>
                                            <li>access the Services; and</li>
                                            <li>to download or print a copy of any portion of the Content for which you have legitimate access</li>
                                        </ul>
                                        exclusively for your personal, non-commercial use, or for internal business purposes.
                                        <p className='mt-2'>
                                            Unless explicitly permitted in this section or elsewhere within our Legal Terms, you are prohibited from copying, reproducing, aggregating, republishing, uploading,
                                            posting, publicly displaying, encoding, translating, transmitting, distributing, selling, licensing, or otherwise utilising any part of the Services, Content, or
                                            Marks for any commercial purpose without our prior written consent.
                                        </p>
                                        <p className='mt-2'>
                                            If you intend to use the Services, Content, or Marks in ways not specified in this section or elsewhere in our Legal Terms, please submit your request to: info@coopconnect.coop.
                                            Should we grant you permission to post, reproduce, or publicly display any part of our Services or Content, you must acknowledge us as the owners or licensors of the Services,
                                            Content, or Marks and ensure that any copyright or proprietary notice is included and visible when posting, reproducing, or displaying our Content.
                                        </p>
                                        <p className='mt-2'>
                                            We retain all rights that are not explicitly granted to you concerning the Services, Content, and Marks.
                                        </p>
                                        <p className='mt-2'>
                                            Any infringement of these Intellectual Property Rights will be considered a significant violation of our Legal Terms, leading to the immediate termination of your right to use our Services.
                                        </p>
                                    </div>
                                    <li><span className='font-weight-bold'>Your submissions and contributions</span></li>
                                    <div>
                                        <p>
                                            Please carefully review both this section and the 'PROHIBITED ACTIVITIES' section before using our Services. This will help you understand (a) the rights you grant us and (b) your responsibilities when posting or uploading any content through the Services.
                                        </p>
                                        <ul className='bulleted'>
                                            <li>
                                                <span className='font-weight-bold'>Submissions: </span>
                                                By submitting any question, comment, suggestion, idea, feedback, or other information about the Services directly to us ('Submissions'), you agree to transfer all intellectual property rights associated with such Submission to us. You acknowledge that we will become the owner of this Submission, and we have the right to utilise and distribute it without restrictions for any lawful purpose, whether for commercial purposes or otherwise, without any obligation to acknowledge or provide compensation to you.</li>
                                            <li>
                                                <span className='font-weight-bold'>Contributions: </span>
                                                The Services may provide opportunities for you to engage in chats, contribute to blogs, participate in message boards, online forums, and other interactive functions where you can generate, submit, post, display, transmit, publish, distribute, or share content and materials with us or through the Services. This content and material, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other materials, are referred to as 'Contributions.' Please note that any Submission made publicly will also be considered a Contribution.
                                                <div className='mt-2' />You should be aware that Contributions may be visible to other users of the Services.
                                            </li>
                                            <li>
                                                <span className='font-weight-bold'>When you post Contributions, you grant us a licence (including use of your name, trademarks, and logos): </span>
                                                By posting any Contributions, you grant us an extensive, perpetual, irrevocable, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and licence to use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (either in whole or in part), and exploit your Contributions (including, but not limited to, your image, name, and voice) for any purpose, whether commercial, advertising, or otherwise, to prepare derivative works from your Contributions or incorporate them into other works, and to sublicense the licences granted in this section. Our use and distribution of your Contributions may occur in various media formats and through different media channels.
                                                <div className='mt-2'>This licence encompasses our use of your name, company name, and franchise name (if applicable), as well as any trademarks, service marks, trade names, logos, personal images, and commercial images you furnish.</div>
                                            </li>
                                            <li>
                                                <span className='font-weight-bold'>You are responsible for what you post or upload: </span>
                                                When you send us Submissions and/or post Contributions via any part of the Services or make Contributions available through the Services by connecting your account within the Services to any of your social networking profiles, you:
                                                <ul className='bulleted'>
                                                    <li>
                                                        Confirm that you have read and agreed to our 'PROHIBITED ACTIVITIES' You also undertake not to post, send, publish, upload, or transmit any Submission through the Services, nor post any Contribution that is unlawful, harassing, hostile, detrimental, defamatory, explicit, abusive, prejudiced, menacing to any person or group, sexually suggestive, untrue, incorrect, deceptive, or misleading.
                                                    </li>
                                                    <li>waive any and all moral rights related to such Submission and/or Contribution to the extent allowed by applicable law.</li>
                                                    <li>warrant that any such Submissions and/or Contributions are original to you or that you possess the required rights and licences to submit them, and that you have the full authority to grant us the aforementioned rights concerning your Submissions and/or Contributions.</li>
                                                    <li>warrant and represent that your Submissions and/or Contributions do not contain confidential information.</li>
                                                </ul>
                                                You bear full responsibility for your Submissions and/or Contributions, and you explicitly consent to compensate us for any losses we may incur due to your violation of: (a) This section, (b) Any third party's intellectual property rights, or (c) Applicable law.
                                            </li>
                                            <li>
                                                <span className='font-weight-bold'>We may remove or edit your Content: </span>
                                                While we are not obligated to actively monitor Contributions, we retain the right to remove or edit any Contributions without prior notice if, in our reasonable judgement, such Contributions are deemed harmful or in violation of these Legal Terms. Should we remove or edit any of your Contributions, we may take further action, including suspending or disabling your account and reporting the matter to the relevant authorities.
                                            </li>
                                        </ul>
                                    </div>
                                    <li><span className='font-weight-bold'>Copyright infringement</span></li>
                                    <div>
                                        We respect the intellectual property rights of others. If you believe that any material accessible on or through the Services violates your copyright ownership or control, please promptly refer to the 'COPYRIGHT INFRINGEMENTS' section below.
                                    </div>
                                </ul>
                            </div>
                            <li><span className='font-weight-bold'>User Representations</span></li>
                            <div>
                                By using the Services, you affirm and warrant that all registration information you submit is true, accurate, current, and complete; you will keep this information accurate and update it promptly as needed. You acknowledge that you have the legal capacity to comply with these Legal Terms, are not under the age of 13, and are not a minor in your jurisdiction. If you are a minor, you have obtained parental permission to use the Services. You agree not to access the Services using automated or non-human means, such as bots or scripts. Furthermore, you pledge not to use the Services for any illegal or unauthorised purposes, and you commit to ensuring that your use of the Services complies with all applicable laws and regulations. If you provide information that is untrue, inaccurate, not current, or incomplete, we reserve the right to suspend or terminate your account and deny you access to the Services, either temporarily or permanently.
                            </div>
                            <li><span className='font-weight-bold'>User Registration</span></li>
                            <div>
                                If registration is necessary for using the Services, you commit to maintaining the confidentiality of your password and assume responsibility for all activities conducted with your account and password. We retain the right to revoke, recover, or modify any username you choose if we conclude, at our sole discretion, that the chosen username is inappropriate, offensive, or objectionable in any way.
                            </div>
                            <li><span className='font-weight-bold'>Prohibited Activities</span></li>
                            <div>
                                You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or approved by us.
                                As a user of the Services, you agree not to:
                                <ul className='bulleted'>
                                    <li>Systematically extract data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without our written permission.</li>
                                    <li>Engage in deceptive practices, fraud, or misleading actions, especially in attempting to obtain sensitive account information like user passwords.</li>
                                    <li>Circumvent, disable, or otherwise disrupt security-related features of the Services, including those that prevent or limit the use or copying of Content or enforce usage restrictions on the Services and/or its contained Content.</li>
                                    <li>Demean, tarnish, or otherwise harm our reputation or that of the Services, as determined by us.</li>
                                    <li>Use any information acquired from the Services to harass, abuse, or harm others.</li>
                                    <li>Misuse our support services or provide false reports of abuse or misconduct.</li>
                                    <li>Utilise the Services in violation of applicable laws or regulations.</li>
                                    <li>Participate in unauthorised framing or linking to the Services.</li>
                                    <li>Upload or transmit viruses, Trojan horses, or other materials, including excessive use of capital letters and spamming, that disrupt the uninterrupted use and enjoyment of the Services by others or modify, impair, disrupt, alter, or interfere with the functioning, features, functions, operation, or maintenance of the Services.</li>
                                    <li>Employ any automated system, such as scripts for sending comments or messages, or use data mining, robots, or similar tools for data collection and extraction.</li>
                                    <li>Remove copyright or other proprietary rights notices from Content.</li>
                                    <li>Try to impersonate another user, individual, or use someone else's username.</li>
                                    <li>Upload or transmit materials that function as passive or active information collection or transmission mechanisms, including, but not limited to, clear graphics interchange formats ('gifs'), 1×1 pixels, web bugs, cookies, or similar devices (sometimes referred to as 'spyware,' 'passive collection mechanisms,' or 'pcms').</li>
                                    <li>Disrupt, interfere with, or excessively burden the Services or the connected networks or services.</li>
                                    <li>Harass, annoy, intimidate, or threaten our employees or agents engaged in providing any part of the Services to you.</li>
                                    <li>Attempt to bypass any protective measures of the Services designed to prevent or restrict access.</li>
                                    <li>Copy or adapt the software used in the Services, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                                    <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software components of the Services.</li>
                                    <li>Use, launch, develop, or distribute any automated system, including but not limited to spiders, robots, cheat utilities, scrapers, or offline readers, for accessing the Services, or use or launch any unauthorised script or other software.</li>
                                    <li>Utilise a buying agent or purchasing agent to conduct purchases through the Services.</li>
                                    <li>Engage in any unauthorised use of the Services, including collecting usernames and/or email addresses of users through electronic or other means for sending unsolicited emails or creating user accounts through automated or false means.</li>
                                    <li>Utilise the Services in any attempt to compete with us or for any revenue-generating activities or commercial ventures.</li>
                                    <li>Sell or transfer your profile.</li>
                                    <li>Use the Services for advertising or offering to sell goods and services.</li>
                                </ul>
                            </div>
                            <li><span className='font-weight-bold'>User Generated Contributions</span></li>
                            <div>
                                The Services may encourage your participation in various features, such as chat, blogs, message boards, online forums, and other functionalities, allowing you to generate, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials on the Services or to us. This content may encompass text, writings, video, audio, photographs, graphics, comments, suggestions, personal information, or other materials (collectively referred to as 'Contributions'). Please be aware that Contributions may be visible to other Services users and accessible through third-party websites. Consequently, any Contributions you send should not be considered confidential or proprietary. When you create or make Contributions available, you affirm and guarantee that:
                                <ul className='bulleted'>
                                    <li>Your Contributions will not infringe upon the proprietary rights, including but not limited to copyright, patent, trademark, trade secret, or moral rights of any third party, whether through creation, distribution, transmission, public display, performance, or the accessing, downloading, or copying of such Contributions.</li>
                                    <li>You either own the rights to your Contributions or possess the necessary licences, rights, consents, releases, and permissions to utilise them. This includes authorising us, the Services, and other users to employ your Contributions as envisaged by the Services and these Legal Terms.</li>
                                    <li>You have acquired written consent, releases, and permissions from each identifiable individual depicted in your Contributions, allowing for the use of their name or likeness as necessary for the inclusion and utilisation of your Contributions in accordance with the Services and these Legal Terms.</li>
                                    <li>Your Contributions are truthful, accurate, and devoid of any misleading information.</li>
                                    <li>Your Contributions are not spam, unsolicited or unauthorised advertising, promotional materials, pyramid schemes, chain letters, mass mailings, or any other form of solicitation.</li>
                                    <li>Your Contributions do not contain content that is obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable, as determined by us.</li>
                                    <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse any individuals.</li>
                                    <li>Your Contributions do not harass or threaten any person in a legal sense and do not promote violence against any specific individual or group.</li>
                                    <li>Your Contributions are in compliance with all applicable laws, regulations, and rules.</li>
                                    <li>Your Contributions do not encroach the privacy or publicity rights of any third party.</li>
                                    <li>Your Contributions do not violate any laws regarding child pornography or any regulations intended to protect the health or well-being of minors.</li>
                                    <li>Your Contributions do not include offensive comments related to race, national origin, gender, sexual orientation, or physical disabilities.</li>
                                    <li>Your Contributions do not otherwise violate, or link to material that violates, any provision outlined in these Legal Terms or any applicable law or regulation.</li>
                                </ul>
                                Engaging in any use of the Services that contravenes the above conditions constitutes a breach of these Legal Terms, and as a consequence, your rights to use the Services may be terminated or suspended, among other potential actions.
                            </div>
                            <li><span className='font-weight-bold'>Contribution Licence</span></li>
                            <div>
                                <p>
                                    By posting your Contributions on any part of the Services or making Contributions accessible to the Services by linking your account from the Services to any of your social networking accounts, you automatically grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and licence to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, retain, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (either wholly or partially), and disseminate these Contributions, encompassing your image and voice, for any purpose, whether it be commercial, advertising, or otherwise. This licence applies to all current and future forms, media, or technologies, and it includes the use of your name, company name, and franchise name, as applicable, along with any trademarks, service marks, trade names, logos, and both personal and commercial images you furnish. You waive all moral rights in your Contributions, and you confirm that moral rights have not been asserted in your Contributions.
                                </p>
                                <p className='mt-2'>
                                    We do not claim ownership of your Contributions, and you retain full ownership of all your Contributions, along with any intellectual property or other proprietary rights associated with them. We are not responsible for any statements or representations within your Contributions, and you are solely responsible for your Contributions to the Services. You agree not to hold us liable or take legal action against us regarding your Contributions.
                                </p>
                                <p className='mt-2'>
                                    We reserve the right, in our sole discretion, to: (1) edit, redact, or modify any Contributions; (2) re-categorize Contributions for more appropriate placement on the Services; and (3) pre-screen or remove Contributions at any time and for any reason, without prior notice. We are under no obligation to monitor your Contributions.
                                </p>
                            </div>
                            <li><span className='font-weight-bold'>Guidelines for Reviews</span></li>
                            <div>
                                <p>
                                    When using our Services to post reviews, please adhere to the following criteria: (1) Ensure you have firsthand experience with the subject of your review; (2) Avoid including offensive language, profanity, racism, hate speech, or any form of abuse in your reviews; (3) Refrain from making discriminatory references based on factors such as religion, race, gender, national origin, age, marital status, sexual orientation, or disability; (4) Do not mention or allude to any illegal activities in your reviews; (5) If posting negative reviews, do not have affiliations with competitors; (6) Avoid drawing legal conclusions about conduct in your reviews; (7) Provide accurate and truthful information, avoiding false or misleading statements; and (8) Do not organise campaigns encouraging others to post reviews, whether they are positive or negative.
                                </p>
                                <p className='mt-2'>
                                    We retain the discretion to accept, reject, or remove reviews at our sole discretion. We are not obligated to screen or delete reviews, even if they are considered objectionable or inaccurate by any party. It's important to note that reviews are not endorsed by us and may not necessarily reflect our opinions or those of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses that may result from any review. By posting a review, you grant us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and licence to reproduce, modify, translate, transmit by any means, display, perform, or distribute any content related to the review.
                                </p>
                            </div>
                            <li><span className='font-weight-bold'>Social Media</span></li>
                            <div>
                                <p>
                                    As part of the Services' functionality, you can link your account with third-party service providers' online accounts (referred to as "Third-Party Accounts") by either: (1) providing your login information for these Third-Party Accounts through the Services or (2) allowing us to access your Third-Party Account, as permitted under the relevant terms and conditions governing your use of each Third-Party Account. You confirm and guarantee that you have the right to disclose your login information for Third-Party Accounts to us and grant us access to your Third-Party Account without violating any terms and conditions governing your use of these Third-Party Accounts. This action does not obligate us to pay any fees or subject us to any usage limitations imposed by the third-party service provider of the Third-Party Account.
                                </p>
                                <p className='mt-2'>
                                    By granting us access to your Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) any content that you have provided and stored in your Third-Party Account (referred to as "Social Network Content") so that it is accessible on and through the Services via your account, including items such as friend lists, and (2) we may submit to and receive additional information from your Third-Party Account, as notified when you link your account with the Third-Party Account. Depending on the Third-Party Accounts you select and the privacy settings you configure for these Third-Party Accounts, personally identifiable information that you post on your Third-Party Accounts may be accessible on and through your Services account.
                                </p>
                                <p className='mt-2'>
                                    Please note that if a Third-Party Account or its associated service becomes unavailable or if our access to the Third-Party Account is terminated by the third-party service provider, then Social Network Content may no longer be accessible on and through the Services. You will have the option to disconnect your Services account from your Third-Party Accounts at any time.
                                </p>
                                <p className='mt-2'>
                                    IT IS IMPORTANT TO NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS CONNECTED TO YOUR THIRD-PARTY ACCOUNTS IS SOLELY GOVERNED BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We do not make any effort to review Social Network Content for any purpose, including but not limited to accuracy, legality, or non-infringement, and we are not liable for any Social Network Content.
                                </p>
                                <p className='mt-2'>
                                    You acknowledge and consent to us accessing your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet solely for the purpose of identifying and informing you about those contacts who have also registered to use the Services. You can deactivate the connection between the Services and your Third-Party Account by reaching out to us using the contact information provided below or through your account settings (if applicable). We will attempt to delete any information stored on our servers that was obtained through such as a Third-Party Account, with the exception of the username and profile picture associated with your account.
                                </p>
                            </div>
                            <li className='font-weight-bold'> Services Management</li>
                            <div>
                                We retain the right, but not the obligation, to: (1) continuously monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against any individual who, according to our sole discretion, violates the law or these Legal Terms, including reporting such users to law enforcement authorities; (3) at our sole discretion and without any limitations, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any part thereof; (4) at our sole discretion and without any limitations, notice, or liability, remove from the Services or disable all files and content that are excessively large or place an undue burden on our systems; and (5) otherwise  manage the Services effectively, protect our rights and property, and ensure their proper functioning, without the need for prior notice or assuming liability.
                            </div>
                            <li className='font-weight-bold'>Privacy Policy</li>
                            <div>
                                We prioritise data privacy and security. Please review our Privacy Policy at https://coopconnect.coop/privacy-policy. By utilising the Services, you agree to adhere to our Privacy Policy, which is integrated into these Legal Terms. It's important to note that the Services are hosted in Uganda and Uganda. If you access the Services from a region outside Uganda and Uganda with laws or requirements related to personal data collection, use, or disclosure that differ from those in Uganda and Uganda, then by continuing to use the Services, you are authorising the transfer of your data to Uganda and Uganda, and you expressly consent to having your data transferred to and processed in Uganda and Uganda. Additionally, we do not knowingly accept, request, or solicit information from children, nor do we intentionally market to children. Therefore, in compliance with the U.S. Children’s Online Privacy Protection Act, if we become aware that an individual under the age of 13 has provided personal information to us without the necessary and verifiable parental consent, we will promptly remove that information from the Services.
                            </div>
                            <li className='font-weight-bold'>Copyright Infringements</li>
                            <div>
                                We value the intellectual property rights of others. If you believe that any material accessible through the Services violates your copyright, please promptly inform us using the contact information provided below (referred to as a 'Notification'). A copy of your Notification will also be forwarded to the individual responsible for posting or storing the content mentioned in the Notification. It's important to note that under applicable law, you may be held responsible for damages if you make significant misrepresentations in a Notification. Therefore, if you are uncertain whether material on or linked to by the Services infringes your copyright, we recommend seeking legal counsel before proceeding with a Notification.
                            </div>
                            <li className='font-weight-bold'>Term and Termination</li>
                            <div>
                                <p>
                                    These Legal Terms will remain in full force and effect for the duration of your use of the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                                </p>
                                <p className='mt-2'>
                                    If we terminate or suspend your account for any reason, you are prohibited from subsequently registering or creating a new account under your name, a fictitious identity, or someone else's identity, even if you are representing a third party. In addition to the termination or suspension of your account, we retain the right to pursue appropriate legal action, which may include, but is not limited to, seeking civil, criminal, and injunctive remedies.
                                </p>
                            </div>
                            <li className='font-weight-bold'>Modifications and Interruptions</li>
                            <div>
                                <p>
                                    We retain the right to change, modify, or remove the contents of the Services at our sole discretion, and we may do so at any time or for any reason without prior notice. Please be aware that we are under no obligation to update any information on our Services. We shall not be held liable to you or any third party for any modifications, changes in pricing, suspensions, or discontinuance of the Services.
                                </p>
                                <p className='mt-2'>
                                    It's important to understand that we cannot guarantee the uninterrupted availability of the Services. There may be instances where we encounter hardware, software, or other issues, or need to perform maintenance related to the Services, which could lead to interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time and for any reason, without prior notice to you.
                                </p>
                                <p className='mt-2'>
                                    By using the Services, you acknowledge and agree that we bear no liability for any losses, damages, or inconveniences resulting from your inability to access or use the Services during any periods of downtime or discontinuance. Please be aware that these Legal Terms do not impose an obligation on us to maintain and support the Services or provide any corrections, updates, or releases related to the Services.
                                </p>
                            </div>
                            <li className='font-weight-bold'>Governing Law</li>
                            <div>
                                These Legal Terms shall be governed by and interpreted in accordance with the laws of Uganda. The Uhuru Institute for Social Development and you hereby irrevocably consent to the exclusive jurisdiction of the courts of Uganda to resolve any dispute that may arise in connection with these Legal Terms.
                            </div>
                            <li className='font-weight-bold'>Dispute Resolution</li>
                            <div>
                                <ul className='numbered-indent'>
                                    <li><span className='font-weight-bold'>Informal Negotiations</span></li>
                                    <div>
                                        To expedite the resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each referred to as a 'Dispute' and collectively as 'Disputes') brought by either you or us (individually referred to as a 'Party' and collectively as the 'Parties'), the Parties agree to first attempt to negotiate any Dispute (except for those Disputes expressly provided below) informally for a minimum of thirty (30) days before commencing arbitration. These informal negotiations commence upon written notice from one Party to the other Party.
                                    </div>
                                    <li><span className='font-weight-bold'>Binding Arbitration</span></li>
                                    <div>
                                        Any dispute arising from or related to these Legal Terms, including questions regarding their existence, validity, or termination, shall be submitted for resolution to the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) in accordance with the Rules of this ICAC, which, as a result of being referred to, is considered a part of this clause. The arbitration panel shall be composed of three (3) arbitrators, and the arbitration proceedings shall be held in Kampala, Uganda, with English as the language of communication. These Legal Terms shall be governed by the substantive law of Uganda.
                                    </div>
                                    <li><span className='font-weight-bold'>Restrictions</span></li>
                                    <div>
                                        The Parties mutually agree that any arbitration will exclusively address the Dispute involving each Party individually. To the maximum extent permitted by law: (a) there shall be no consolidation of the arbitration with any other proceedings; (b) arbitration of any Dispute shall not proceed on a class-action basis or employ class action procedures; and (c) there shall be no initiation of a Dispute in a supposed representative capacity on behalf of the general public or any other individuals.
                                    </div>
                                    <li><span className='font-weight-bold'>Exceptions to Informal Negotiations and Arbitration</span></li>
                                    <div>
                                        The Parties acknowledge that the following Disputes are exempt from the aforementioned provisions regarding informal negotiations and mandatory arbitration: (a) Disputes aimed at enforcing, protecting, or determining the validity of any intellectual property rights held by a Party; (b) Disputes arising from allegations of theft, piracy, invasion of privacy, or unauthorised use; and (c) claims for injunctive relief. In the event that this provision is deemed illegal or unenforceable, neither Party will opt for arbitration concerning any Dispute falling within the section of this provision that is found to be illegal or unenforceable. Such Disputes shall be adjudicated by a court with appropriate jurisdiction among those designated for jurisdiction earlier, and the Parties agree to submit to the personal jurisdiction of that court.
                                    </div>
                                </ul>
                            </div>
                            <li className='font-weight-bold'>Corrections</li>
                            <div>
                                Content on the Services may, on occasion, contain typographical errors, inaccuracies, or omissions, including descriptions, pricing, and availability. We retain the right to rectify such errors, inaccuracies, or omissions and to modify or update the information provided on the Services at our discretion and without prior notice.
                            </div>
                            <li className='font-weight-bold'>DISCLAIMER</li>
                            <div>
                                <p>THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. YOUR UTILISATION OF THE SERVICES CARRIES INHERENT RISKS, AND YOU SHOULD PROCEED WITH CAUTION. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
                                <p className='mt-2'>WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES. WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR DUE TO ANY LOSS OR DAMAGE OF ANY NATURE SUSTAINED THROUGH THE UTILISATION OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE PROVIDED VIA THE SERVICES.</p>
                                <p className='mt-2'>WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION ADVERTISED IN ANY BANNER OR OTHER FORM OF ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
                            </div>
                            <li className='font-weight-bold'>LIMITATIONS OF LIABILITY</li>
                            <div>
                                AT NO TIME SHALL WE, OR OUR DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            </div>
                            <li className='font-weight-bold'>Indemnification</li>
                            <div>
                                You are obligated to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, brought by any third party due to or arising from: (1) Your Contributions; (2) Your utilisation of the Services; (3) A breach of these Legal Terms; (4) Any violation of your representations and warranties as stated in these Legal Terms; (5) Your infringement upon the rights of a third party, including but not limited to intellectual property rights; or (6) Any overt harmful actions directed at any other user of the Services with whom you have connected via the Services. Nevertheless, we retain the right, at your expense, to assume exclusive control over the defence of any matter for which you are required to indemnify us. You must also cooperate, at your expense, with our defence of such claims. We will make reasonable efforts to inform you of any such claim, action, or proceeding subject to this indemnification when we become aware of it.
                            </div>
                            <li className='font-weight-bold'>User Data</li>
                            <div>
                                We will retain specific data that you send to the Services for the purpose of managing service performance and data connected to your use of the Services. While we conduct regular routine data backups, you bear sole responsibility for all data you transmit or that pertains to any activity you engage in while using the Services. You acknowledge and agree that we will not be held liable for any loss or corruption of such data, and you hereby waive any claims against us stemming from such data loss or corruption.
                            </div>
                            <li className='font-weight-bold'>Electronic Communications, Transactions, and Signatures</li>
                            <div>
                                <p>Your interactions with the Services, such as visiting the website, sending us emails, and filling out online forms, constitute electronic communications. By using the Services, you give your consent to receive electronic communications from us. You also acknowledge that all agreements, notices, disclosures, and other communications we send to you electronically, either via email or on the Services, fulfil any legal obligations that such communication must be in writing.</p>
                                <p className='mt-2'>YOU EXPRESSLY CONSENT TO THE UTILISATION OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER DOCUMENTS, AS WELL AS ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS THAT ARE INITIATED OR COMPLETED THROUGH US OR VIA THE SERVICES. By agreeing to this, you waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction that necessitate an original signature or the delivery or preservation of non-electronic records. This also applies to any requirements regarding payments or the issuance of credits through means other than electronic methods.</p>
                            </div>
                            <li className='font-weight-bold'>Uganda Users & Residents</li>
                            <div>
                                If you have a complaint that remains unresolved with us, you have the option to contact the National Information Technology Authority Uganda (NITA-U), the regulatory body responsible for data protection and privacy matters in Uganda under the Personal Data Protection Act (PDPA). You can contact NITA-U in writing at:
                                <p className='mt-2'><span className='font-weight-bold'>Address</span></p>
                                <p>National Information Technology Authority Uganda (NITA-U)</p>
                                <p>Palm Courts, Plot 7A,</p>
                                <p>Rotary Avenue (Former Lugogo Bypass)</p>
                                <p>P.O. Box 33151, Kampala, Uganda</p>
                                <p className='mt-2'><span className='font-weight-bold'>Telephone</span>: +256 417 801 038</p>
                                <p className='mb-2'><span className='font-weight-bold'>Website</span>: <a href='https://www.nita.go.ug/'>https://www.nita.go.ug</a></p>
                                for more information and assistance regarding privacy-related concerns.
                            </div>
                            <li className='font-weight-bold'>Miscellaneous</li>
                            <div>
                                The Parties mutually agree that any arbitration will exclusively address the Dispute involving each Party individually. To the maximum extent permitted by law: (a) there shall be no consolidation of the arbitration with any other proceedings; (b) arbitration of any Dispute shall not proceed on a class-action basis or employ class action procedures; and (c) there shall be no initiation of a Dispute in a supposed representative capacity on behalf of the general public or any other individuals.
                            </div>
                            <li className='font-weight-bold'>Contact Us</li>
                            <div>
                                <p>
                                    To facilitate the resolution of any grievances related to the Services or to obtain additional details regarding the utilisation of the Services, we cordially invite you to get in touch with us through the following means:
                                </p>
                                <p className='mt-2'><span className='font-weight-bold'>Mailing Address</span>:</p>
                                <p>The Uhuru Institute for Social Development</p>
                                <p>Plot 1, Katalima Crescent</p>
                                <p>Kampala, Central</p>
                                <p>Uganda</p>
                                <p className='mt-2'><span className='font-weight-bold'>Phone</span>:+256784100014</p>
                                <p><span className='font-weight-bold'>Email</span>: info@coopconnect.coop</p>
                            </div>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    </>
)

export default TermsOfUse
