import React from "react"
import { useInfiniteQuery } from "react-query"
import { Link } from 'react-router-dom'
import { useUserInfo } from "../async-manager/queries"
import Image from "../layouts/Image"
import Loading from "../layouts/Loading"
import ProgressDots from "../layouts/ProgressDots"
import Name from "../layouts/Name"
import { getUsersMayKnow } from "../utils/api"
import { loadState } from "../utils/storage"
import Suggestions from "./Suggestions"

const LeftSideBar = () => {
    const id = loadState('id')
    const image = loadState('image')
    const name = loadState('name')

    const {
        data: user_info, isLoading: loadingUserInfo,
        /*error, refetch*/
    } = useUserInfo({ id })

    const {
        data: mayKnowData, isLoading: loadingMayKnow,
        /*refetch, isFetching, error,
        fetchNextPage, hasNextPage,
        isFetchingNextPage */
    } = useInfiniteQuery({
        queryKey: ['users-may-know'],
        queryFn: () => getUsersMayKnow({ page: 1 }),
        getNextPageParam: (lastPage, page) =>
            lastPage?.metadata?.page < lastPage?.metadata?.pages
                ? lastPage?.metadata?.page + 1
                : undefined
        ,
        keepPreviousData: true,
        retry: 0
    })

    const mayKnow = mayKnowData?.pages[0]?.data

    const { friends, requests } = user_info || {}
    return (
        <div className="d-none d-lg-block col-lg-3 col-md-4 pd-left-none no-pd">
            <div className="main-left-sidebar no-margin">
                <div className="user-data full-width">
                    <div className="user-profile">
                        <div className="username-dt">
                            <div className="usr-pic">
                                <Image image={image} />
                            </div>
                        </div>
                        <div className="user-specs">
                            <h3><Name name={name} author_id={id} /></h3>
                            <span>@username</span>
                        </div>
                    </div>
                    <div className="friends row w-100 d-flex justify-content-center">
                        {loadingUserInfo && <ProgressDots />}
                        {user_info && (<>
                            <div className="px-3 pb-3 pt-1">
                                <h4 className="py-2">Friends</h4>
                                <span>{friends}</span>
                            </div>
                            <div className="px-3 pb-3 pt-1">
                                <h4 className="py-2">Requests</h4>
                                <span>{requests}</span>
                            </div></>
                        )}
                    </div>
                </div>

                <div className="suggestions full-width">
                    <div className="sd-title">
                        <h3>You May Know</h3>
                        <i className="la la-ellipsis-v"></i>
                    </div>
                    <div className="suggestions-list">
                        {loadingMayKnow && <Loading />}
                        {mayKnow && mayKnow.slice(0, 4).map(user => (
                            <Suggestions
                                user={user}
                                status={'may-know'}
                                key={user.id}
                                id={user.id} />
                        ))}
                        <div className="text-center py-3">
                            <Link className="view-more" to={`/profile/${id}/friends/may-know`} title="">View More</Link>
                        </div>
                    </div>
                </div>

                {/* <div className="suggestions full-width">
                    <div className="sd-title">
                        <h3>Friends</h3>
                        <i className="la la-ellipsis-v"></i>
                    </div>
                    <div className="suggestions-list">
                        {friends && friends.slice(0, 4).map(id => (
                            <Suggestions friends={true} key={id} id={id} />
                        ))}
                        <div className="text-center py-3">
                            <Link className="view-more" to={`/profile/${id}/friends`} title="">View More</Link>
                        </div>
                    </div>
                </div> */}

                <div className="tags-sec full-width">
                    <ul>
                        <li><Link to="#" title="">Help Center</Link></li>
                        <li><Link to="#" title="">About</Link></li>
                        <li><Link to="#" title="">Privacy Policy</Link></li>
                        <li><Link to="#" title="">Community Guidelines</Link></li>
                        <li><Link to="#" title="">Cookies Policy</Link></li>
                        <li><Link to="#" title="">Career</Link></li>
                        <li><Link to="#" title="">Language</Link></li>
                        <li><Link to="#" title="">Copyright Policy</Link></li>
                    </ul>
                    <div className="cp-sec">
                        <img src="images/logo2.png" alt="" />
                        <p><img src="images/cp.png" alt="" />Copyright 2019</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftSideBar
